import { boolean, nativeEnum, object, string, z } from 'zod';

import { UUID_MATCHER } from '../common/matchers';
import { INVALID_UUID } from '../common/messages';

export type FeatureFlagsForCurrentAdmin = {
  ENABLE_READ_ONLY_MODE: boolean;
  ENABLE_TEAMS: boolean;
  ENABLE_TEST_ENVIRONMENT_WARNING: boolean;
  ENABLE_COMMON_REPO_API: boolean;
  ENABLE_DOC_BETA_MODE: boolean;
  ENABLE_AUTO_REFRESH_ON_UPDATE: boolean;
};

export type FeatureFlag =
  | 'ALLOW_API_OWNER_APP_CHANGE'
  | 'ENABLE_SCOPE_OVERVIEW'
  | 'ENABLE_CLIENT_IMPORT'
  | 'ENABLE_READ_ONLY_MODE'
  | 'ENABLE_TEAMS'
  | 'ENABLE_TEST_ENVIRONMENT_WARNING'
  | 'ENABLE_COMMON_REPO_API'
  | 'ENABLE_DOC_BETA_MODE'
  | 'ENABLE_AUTO_REFRESH_ON_UPDATE'
  | 'ENABLE_CUSTOM_PAGES_FOR_TEAMS'
  | 'ENABLE_API_REVIEW_PAGE'
  | 'ENABLE_EVENT_PAGE';

export type FeatureFlagType = 'organization' | 'team' | 'email' | 'everyone';

export enum FeatureFlags {
  ALLOW_API_OWNER_APP_CHANGE = 'ALLOW_API_OWNER_APP_CHANGE',
  ENABLE_SCOPE_OVERVIEW = 'ENABLE_SCOPE_OVERVIEW',
  ENABLE_CLIENT_IMPORT = 'ENABLE_CLIENT_IMPORT',
  ENABLE_READ_ONLY_MODE = 'ENABLE_READ_ONLY_MODE',
  ENABLE_TEAMS = 'ENABLE_TEAMS',
  ENABLE_DOC_BETA_MODE = 'ENABLE_DOC_BETA_MODE',
  ENABLE_TEST_ENVIRONMENT_WARNING = 'ENABLE_TEST_ENVIRONMENT_WARNING',
  ENABLE_COMMON_REPO_API = 'ENABLE_COMMON_REPO_API',
  ENABLE_AUTO_REFRESH_ON_UPDATE = 'ENABLE_AUTO_REFRESH_ON_UPDATE',
  ENABLE_CUSTOM_PAGES_FOR_TEAMS = 'ENABLE_CUSTOM_PAGES_FOR_TEAMS',
  ENABLE_API_REVIEW_PAGE = 'ENABLE_API_REVIEW_PAGE',
  ENABLE_EVENT_PAGE = 'ENABLE_EVENT_PAGE',
}

export interface FeatureFlagDto {
  id: string;
  key: FeatureFlags;
  value: boolean;
  type: FeatureFlagType;
  typeId: string | null;
  name?: string;
}

export const featureFlagInputSchema = object({
  id: string().regex(UUID_MATCHER, INVALID_UUID).optional(),
  key: nativeEnum(FeatureFlags),
  value: boolean(),
  type: z.enum(['organization', 'team', 'email', 'everyone']),
  typeId: string().nullable(),
});

export type FeatureFlagInputDto = z.infer<typeof featureFlagInputSchema>;

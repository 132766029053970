import { Anchor, Button, H1 } from '@dnb/eufemia';
import type { FeatureFlagDto } from '@portals/shared/admin/FeatureFlagDto';
import { useAsync } from '@portals/shared-frontend/hooks';
import type { JSX } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import useSWR from 'swr';

import { deleteFeatureFlag } from '@/api/feature-flags';
import BackButton from '@/components/BackButton';
import DataTable, { type Column } from '@/components/DataTable';
import LoadingModal from '@/components/LoadingModal';
import LoadingPage from '@/components/LoadingPage';

const COLUMNS: Column<FeatureFlagRow>[] = [
  {
    header: 'Key',
    attribute: 'key',
    render: ({ id, key }) => (
      <Anchor element={Link} to={`/feature-flags/${id}`}>
        {key}
      </Anchor>
    ),
  },
  { header: 'Type', attribute: 'type' },
  { header: 'Name', attribute: 'name' },
  { header: 'Enabled', attribute: 'value' },
];

interface FeatureFlagRow {
  id: string;
  key: string;
  name?: string;
  type: string;
  value: string;
}

export default function FeatureFlagList(): JSX.Element {
  const navigate = useNavigate();
  const {
    data: flags,
    isValidating: flagsLoading,
    mutate: mutateFlags,
  } = useSWR<FeatureFlagDto[]>('/feature-flags');

  const rows =
    flags?.map<FeatureFlagRow>((flag) => ({
      ...flag,
      value: flag.value ? 'Yes' : 'No',
    })) ?? [];

  const onDelete = useAsync(
    async ({ id }: FeatureFlagRow) => {
      await deleteFeatureFlag(id);
      mutateFlags((flags) => flags?.filter((flag) => flag.id !== id), false);
    },
    [mutateFlags],
  );

  if (flagsLoading) {
    return <LoadingPage />;
  }

  return (
    <>
      {onDelete.waiting && <LoadingModal />}

      <BackButton to="/">Home</BackButton>

      <H1 top="large">Feature Flags</H1>
      <DataTable
        barContent={
          <Button
            element={Link}
            icon="add"
            icon_position="left"
            to="/feature-flags/new"
            variant="secondary"
          >
            Add flag
          </Button>
        }
        columns={COLUMNS}
        data={rows}
        defaultSortKey="key"
        filterBy={['key', 'name']}
        onDelete={onDelete.execute}
        onEdit={({ id }) => navigate(id)}
      />
    </>
  );
}

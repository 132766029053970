import { Anchor } from '@dnb/eufemia';
import type {
  ReviewStatusType,
  ScopeRequestWithApiAndOwnerDto,
} from '@portals/shared/admin/ScopeRequestDto';
import { groupBy } from '@portals/shared-frontend/utils';
import { type JSX, useMemo } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import DataTable, { type Column } from '@/components/DataTable';
import LoadingPage from '@/components/LoadingPage';
import { humanDate } from '@/utils';

interface ScopeRequestRow {
  id: string;
  requestType?: 'CREATE' | 'DELETE' | 'UPDATE';
  scopeName: string;
  apiName: string;
  apiSlug: string;
  owner: string | null;
  createdAt: string;
  reviewStatus?: ReviewStatusType;
}

const COLUMNS: Column<ScopeRequestRow>[] = [
  { header: 'Type', attribute: 'requestType' },
  {
    header: 'Scope',
    attribute: 'scopeName',
    render: ({ scopeName, id }) => (
      <Anchor element={Link} to={`/scopes/${id}/edit`}>
        {scopeName}
      </Anchor>
    ),
  },
  { header: 'API', attribute: 'apiName' },
  { header: 'API Slug', attribute: 'apiSlug' },
  { header: 'Owner', attribute: 'owner' },
  {
    header: 'Created',
    attribute: 'createdAt',
    render: ({ createdAt }) => humanDate(createdAt),
  },
];

const toScopeRequestRow = (scopeRequests: ScopeRequestWithApiAndOwnerDto[]) =>
  scopeRequests.map<ScopeRequestRow>((row) => ({
    ...row,
    owner: row.teamName || row.userEmail,
    createdAt: row.createdAt,
  }));

export interface ScopeRequestsTableProps {
  scopeRequests: ScopeRequestWithApiAndOwnerDto[];
  loading?: boolean;
}

export default function ScopeRequestsTable({
  scopeRequests,
  loading = false,
}: ScopeRequestsTableProps): JSX.Element {
  const navigate = useNavigate();

  const rows: ScopeRequestRow[] = useMemo(() => {
    if (!scopeRequests || scopeRequests.length === 0) {
      return [];
    }
    const rowsByStatus = groupBy(scopeRequests, 'reviewStatus');
    if (!rowsByStatus['PENDING']) {
      return [];
    }
    return toScopeRequestRow(rowsByStatus['PENDING']);
  }, [scopeRequests]);

  if (loading) {
    return <LoadingPage />;
  }
  return (
    <DataTable
      barContent={<span />} // Just a work around to keep search bar to the right side
      columns={COLUMNS}
      data={rows}
      defaultSortKey="createdAt"
      filterBy={[
        'scopeName',
        'apiName',
        'apiSlug',
        'owner',
        'requestType',
        'createdAt',
      ]}
      onShow={({ id }) => navigate(`/scopes/${id}/edit`)}
      reverseSort
    />
  );
}

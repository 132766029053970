import {
  Autocomplete,
  Button,
  Dd,
  Dl,
  Dt,
  FormRow,
  FormSet,
  HelpButton,
  P,
  Section,
  Span,
} from '@dnb/eufemia';
import { edit as EditIcon } from '@dnb/eufemia/icons';
import type { ApiWithOwnerDto } from '@portals/shared/admin/ApiDto';
import type { ScopeAuditLogDto } from '@portals/shared/admin/ScopeAuditLogDto';
import type { ScopeFromAllSourcesDto } from '@portals/shared/admin/ScopeDto';
import {
  type ScopeRequestCreationAndApprovalInputDto,
  scopeRequestCreationAndApprovalInputSchema,
} from '@portals/shared/admin/ScopeRequestDto';
import { useAsync, useEufemiaForm } from '@portals/shared-frontend/hooks';
import Prism from 'prismjs';
import { type JSX, useCallback, useMemo, useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';

import { createScope } from '@/api/scopes';
import LoadingPage from '@/components/LoadingPage';

export interface ScopeDetailViewProps {
  scope: ScopeFromAllSourcesDto;
  apis: ApiWithOwnerDto[];
}

export default function ScopeDetailView({
  scope,
  apis,
}: ScopeDetailViewProps): JSX.Element {
  const { controller, handleSubmit } = useEufemiaForm(
    scopeRequestCreationAndApprovalInputSchema,
    {
      name: scope.scopeName,
      description:
        scope.ciamScope?.description ||
        scope.ciamTestScope?.description ||
        scope.portalScope?.description,
    },
  );
  const [isEditing, setIsEditing] = useState(!scope.portalScope?.apiId);
  const navigate = useNavigate();
  const showScopeAuditLog = useCallback(
    (scopeAuditLog: ScopeAuditLogDto) => {
      navigate(`/scopes/history/${scopeAuditLog.id}`);
    },
    [navigate],
  );

  const onCreate = useAsync(
    async ({
      apiId,
      name,
      description,
    }: ScopeRequestCreationAndApprovalInputDto) => {
      const log = await createScope({ apiId, name, description });
      showScopeAuditLog(log);
    },
    [showScopeAuditLog],
  );

  const api = useMemo(
    () => apis.find((api) => api.id === scope.portalScope?.apiId),
    [apis, scope.portalScope?.apiId],
  );

  if (onCreate.waiting) {
    return <LoadingPage />;
  }

  return (
    <>
      <Section spacing="medium" style_type="white">
        <Dl>
          <P bottom="small">Scope</P>
          <Dd>{scope.scopeName}</Dd>
        </Dl>
      </Section>
      <Section spacing="medium">
        {isEditing ? (
          <FormSet
            on_submit={handleSubmit((data) => onCreate.execute(data))}
            vertical
          >
            <FormRow top="medium">
              <P bottom="medium">Select api</P>
              <Autocomplete
                data={apis?.map((api) => ({
                  selected_key: api.id,
                  content: (
                    <Dl>
                      <Dt>{api.name}</Dt>
                      <P size="small">{api.id}</P>
                    </Dl>
                  ),
                }))}
                on_change={({ data }) =>
                  controller.setValue('apiId', data.selected_key)
                }
                size="large"
                stretch
                suffix={
                  <HelpButton size="small">
                    Select api to create / update scope
                  </HelpButton>
                }
                value={scope?.portalScope?.apiId}
              />
            </FormRow>
            <FormRow top="medium">
              <Button right="small" type="submit">
                Save
              </Button>
            </FormRow>
          </FormSet>
        ) : (
          <>
            <Dl>
              <Dt>
                <Span bottom="small" right="small">
                  Api
                </Span>
                <Button
                  icon={EditIcon}
                  on_click={() => {
                    setIsEditing(true);
                  }}
                  right="x-small"
                  size="medium"
                  title="Edit"
                />
              </Dt>
              <Dd>{api?.name}</Dd>
            </Dl>
            <Button
              element={RouterLink}
              icon="chevron-right"
              target="_blank"
              to={`/apis/${scope?.portalScope?.apiId}`}
              variant="secondary"
            >
              Show api
            </Button>
          </>
        )}
      </Section>
      <Section spacing="medium" style_type="white">
        <P bottom="medium">Ciam Scope</P>
        {scope.ciamScope && (
          <pre className="dnb-pre prism-code language-json">
            <div
              dangerouslySetInnerHTML={{
                __html: Prism.highlight(
                  JSON.stringify(scope.ciamScope, null, 2),
                  Prism.languages['txt'],
                  'txt',
                ),
              }}
            />
          </pre>
        )}
      </Section>
      <Section spacing="medium">
        <P bottom="medium">Ciam Test Scope</P>
        {scope.ciamTestScope && (
          <pre className="dnb-pre prism-code language-json">
            <div
              dangerouslySetInnerHTML={{
                __html: Prism.highlight(
                  JSON.stringify(scope.ciamTestScope, null, 2),
                  Prism.languages['txt'],
                  'txt',
                ),
              }}
            />
          </pre>
        )}
      </Section>
      <Section spacing="medium" style_type="white">
        <P bottom="medium">Portal Scope</P>
        {scope.portalScope && (
          <pre className="dnb-pre prism-code language-json">
            <div
              dangerouslySetInnerHTML={{
                __html: Prism.highlight(
                  JSON.stringify(scope.portalScope, null, 2),
                  Prism.languages['txt'],
                  'txt',
                ),
              }}
            />
          </pre>
        )}
      </Section>
    </>
  );
}

import {
  Button,
  Dd,
  Dl,
  Dt,
  FormStatus,
  H1,
  H2,
  HelpButton,
  P,
  Section,
  Space,
} from '@dnb/eufemia';
import type { ScopeAuditLogDto } from '@portals/shared/admin/ScopeAuditLogDto';
import { ScopeRequestType } from '@portals/shared/admin/ScopeRequestDto';
import classNames from 'classnames';
import React, { type JSX } from 'react';
import { Link as RouterLink, useParams } from 'react-router-dom';
import useSWR from 'swr';

import BackButton from '@/components/BackButton';
import Container from '@/components/Container';
import LoadingPage from '@/components/LoadingPage';

import ScopeAuditLogsTable from './components/ScopeAuditLogsTable';
import { EventTypeDetails, reviewChecklistTitleMap } from './constant';

import style from './ScopesShow.module.css';

export default function ScopeAuditLogShow(): JSX.Element {
  const { id: requestId } = useParams();
  const { data: scopeAuditLog, isValidating: scopeAuditLogLoading } =
    useSWR<ScopeAuditLogDto>(`/scope-audit-logs/${requestId}`);

  if (scopeAuditLogLoading || !scopeAuditLog) {
    return <LoadingPage />;
  }

  const eventTypeStatusClass =
    EventTypeDetails.find(
      (eventType) => scopeAuditLog?.event === eventType?.event,
    )?.statusClass || '';

  return (
    <>
      <BackButton to="/scopes/history">Home</BackButton>
      <Container centered size="small">
        <P
          className={classNames(style['Status'], style[eventTypeStatusClass])}
          top="x-large"
        >
          {scopeAuditLog.event.replace('_', ' ').toLocaleLowerCase()}
        </P>
        <H1>Scope Audit Log</H1>

        <Section spacing="x-large" top="x-large">
          <H2>API</H2>
          <Dl>
            <Dt>Name</Dt>
            <Dd>{scopeAuditLog.metadata.api?.name}</Dd>
            <Dt>Slug</Dt>
            <Dd>{scopeAuditLog.metadata.api?.slug}</Dd>
          </Dl>
          <Button
            element={RouterLink}
            icon="chevron-right"
            to={`/apis/${scopeAuditLog.apiId}`}
            top="large"
            variant="secondary"
          >
            Show API
          </Button>
        </Section>

        <Section spacing="x-large" style_type="white">
          <H2>App</H2>
          <Dl>
            <Dt>Name</Dt>
            <Dd>{scopeAuditLog.metadata.app?.name}</Dd>
            <Dt>Owner</Dt>
            <Dd>
              {scopeAuditLog.metadata.app?.teamId
                ? 'Team Id: ' + scopeAuditLog.metadata.app?.teamId
                : 'User Id: ' + scopeAuditLog.metadata.app?.userId}
            </Dd>
          </Dl>
          <Button
            element={RouterLink}
            icon="chevron-right"
            to={
              scopeAuditLog.metadata.app?.teamId
                ? `/teams/${scopeAuditLog.metadata.app?.teamId}`
                : `/users/${scopeAuditLog.metadata.app?.userId}`
            }
            top="large"
            variant="secondary"
          >
            Show owner
          </Button>
        </Section>

        <Section spacing="x-large">
          <H2>Scope</H2>
          <Dl top="large">
            {scopeAuditLog.metadata.scopeRequest?.requestType ==
            ScopeRequestType.CREATE ? (
              <>
                <Dt>Name</Dt>
                <Dd>{scopeAuditLog.metadata.scopeRequest?.scopeName}</Dd>
                <Dt>Description</Dt>
                <Dd>{scopeAuditLog.metadata.scopeRequest?.scopeDescription}</Dd>
              </>
            ) : (
              <>
                <Dt>Name</Dt>
                <Dd>{scopeAuditLog.metadata.scope?.name}</Dd>
                <Dt>Description</Dt>
                <Dd>{scopeAuditLog.metadata.scope?.description}</Dd>
              </>
            )}
          </Dl>
        </Section>
      </Container>
      <Section spacing="x-large" style_type="white">
        <H2>Request history</H2>
        <ScopeAuditLogsTable
          columns={['event', 'apiName', 'appName', 'createdAt']}
          scopeAuditLogs={scopeAuditLog?.relatedScopeAuditLog || []}
        />
      </Section>

      <Container centered size="small">
        <Section spacing="x-large">
          <H2>Decision</H2>
          <Dl top="large">
            <Dt>Reviewer</Dt>
            <Dd>{scopeAuditLog.metadata.scopeRequest?.reviewedBy}</Dd>

            <Dt>Review Checklist</Dt>

            {scopeAuditLog.metadata.scopeRequest?.reviewChecklist &&
              Object.keys(
                scopeAuditLog.metadata.scopeRequest?.reviewChecklist,
              ).map((key) => (
                <React.Fragment key={key}>
                  <Dt>{reviewChecklistTitleMap[key]}</Dt>
                  <Dd>
                    {scopeAuditLog.metadata.scopeRequest?.reviewChecklist?.[key]
                      ? 'YES'
                      : 'NO'}
                  </Dd>
                </React.Fragment>
              ))}

            <Dt>
              Event
              <HelpButton left="small" title="Event">
                <Dl top="large">
                  {EventTypeDetails.map((eventType) => (
                    <React.Fragment key={eventType.event}>
                      <Dt
                        className={classNames(
                          style['Status'],
                          style[eventType.statusClass],
                        )}
                      >
                        {eventType.event}
                      </Dt>
                      <Dd>{eventType.desciption}</Dd>
                    </React.Fragment>
                  ))}
                </Dl>
              </HelpButton>
            </Dt>
            <Dd>{scopeAuditLog.event}</Dd>
            <Dt>Decision Status</Dt>
            <Dd>{scopeAuditLog.metadata.scopeRequest?.reviewStatus}</Dd>

            <Dt>Decision Comment</Dt>

            <Dd>
              {scopeAuditLog.metadata.scopeRequest?.reviewComment ??
                'No comment added by reviewer'}
            </Dd>
            <Button
              disabled={!scopeAuditLog.metadata.scopeRequest?.reviewedBy}
              element={RouterLink}
              icon="chevron-right"
              to={
                scopeAuditLog.metadata.scopeRequest?.reviewedBy
                  ? `/users/${scopeAuditLog.metadata.scopeRequest?.reviewedBy}`
                  : '#'
              }
              top="large"
              variant="secondary"
            >
              Show Reviewer
            </Button>
            {!scopeAuditLog.metadata.scopeRequest?.reviewedBy && (
              <Space top="small">
                <FormStatus
                  state="warn"
                  text="No reviewer information available."
                />
              </Space>
            )}
          </Dl>
        </Section>
      </Container>
    </>
  );
}

import {
  Button,
  Checkbox,
  Dd,
  Dl,
  Dt,
  FormRow,
  FormSet,
  FormStatus,
  H1,
  H2,
  P,
  Section,
  Textarea,
} from '@dnb/eufemia';
import {
  type AppApiEntitlementRequestDetailsResponseDto,
  ReviewStatus,
} from '@portals/shared/admin/AppApiEntitlementDto';
import type { OrganizationDto } from '@portals/shared/admin/OrganizationDto';
import { Decision } from '@portals/shared/portal/AppApiEntitlementDto';
import { useAsync } from '@portals/shared-frontend/hooks';
import { useEufemiaForm } from '@portals/shared-frontend/hooks/useEufemiaForm';
import { hasOwnProperty } from '@portals/shared-frontend/utils';
import { ApiError } from '@portals/shared-frontend/utils/ApiError';
import classNames from 'classnames';
import { type JSX, useCallback, useEffect, useMemo, useState } from 'react';
import { Link as RouterLink, useParams } from 'react-router-dom';
import useSWR from 'swr';
import { z } from 'zod';

import { handleEntitlementRequest } from '@/api/entitlements';
import BackButton from '@/components/BackButton';
import Container from '@/components/Container';
import LoadingModal from '@/components/LoadingModal';
import LoadingPage from '@/components/LoadingPage';
import { TeamSection } from '@/components/TeamSection';
import { UserSection } from '@/components/UserSection';
import { humanDatetime } from '@/utils';

import style from './EntitlementsRequestShow.module.css';

const EntitlementRequestForm = z.object({
  comment: z.string().nullable(),
  checklist: z.object({
    receivedFirstLevelApprovalFromApiProvider: z.boolean(),
  }),
});

export default function EntitlementsRequestShow(): JSX.Element {
  const { entitlementRequestId } = useParams();
  const [error, setError] = useState('');

  const {
    data: request,
    isValidating: loadingRequest,
    mutate,
  } = useSWR<AppApiEntitlementRequestDetailsResponseDto>(
    `/entitlement-requests/${entitlementRequestId}`,
  );

  const organizationId =
    request?.team?.organizationId ?? request?.user?.organizationId;

  const { data: organization, isValidating: loadingOrganization } =
    useSWR<OrganizationDto>(
      organizationId ? `/organizations/${organizationId}` : null,
    );

  const {
    controller: { values, setValue },
    register,
    handleSubmit,
    submitting,
  } = useEufemiaForm(EntitlementRequestForm, {
    comment: request?.reviewComment,
    checklist: {
      receivedFirstLevelApprovalFromApiProvider: false,
    },
  });

  const handleError = useCallback((error: unknown) => {
    if (ApiError.isApiError(error)) {
      setError(error.message);
    } else if (
      hasOwnProperty(error, 'message') &&
      typeof error.message === 'string'
    ) {
      setError(error.message);
    } else {
      setError('Something went wrong');
    }
  }, []);

  // Set initial valuues
  useEffect(() => {
    if (request) {
      setValue('comment', request.reviewComment);
      if (request.reviewChecklist) {
        setValue('checklist', request.reviewChecklist);
      }
    }
  }, [request, setValue]);

  const editableForm = useMemo(() => {
    return (
      request?.reviewStatus &&
      [ReviewStatus.PENDING, ReviewStatus.LEVEL1_APPROVED].includes(
        request?.reviewStatus,
      )
    );
  }, [request?.reviewStatus]);

  const onSubmit = handleSubmit(
    useCallback(
      async ({ checklist, comment }) => {
        setError('');
        try {
          if (entitlementRequestId && request) {
            const response = await handleEntitlementRequest(
              entitlementRequestId,
              {
                apiId: request.api.id,
                appId: request.app.id,
                checklist: JSON.stringify(checklist),
                decision: Decision.APPROVED,
                liveMode: true,
                reviewComment: comment ?? null,
                scopeIds: request.scopes.map((scope) => scope.id),
              },
            );
            mutate({ ...response });
          }
        } catch (error) {
          handleError(error);
        }
      },
      [entitlementRequestId, handleError, mutate, request],
    ),
  );

  const onDeny = useAsync(async () => {
    try {
      setError('');
      if (entitlementRequestId && request) {
        const response = await handleEntitlementRequest(entitlementRequestId, {
          apiId: request.api.id,
          appId: request.app.id,
          checklist: JSON.stringify(values.checklist),
          decision: Decision.DENIED,
          liveMode: true,
          reviewComment: values.comment ?? 'No comment',
          scopeIds: request.scopes.map((scope) => scope.id),
        });
        mutate({ ...response });
      }
    } catch (error) {
      handleError(error);
    }
  }, [
    entitlementRequestId,
    handleError,
    mutate,
    request,
    values.checklist,
    values.comment,
  ]);

  const isLoading = loadingRequest || loadingOrganization;

  if (isLoading) {
    return <LoadingPage />;
  }

  return (
    <>
      {(onDeny.waiting || submitting) && <LoadingModal />}
      <BackButton to="/">Home</BackButton>
      <Container centered size="small">
        <P
          className={classNames(
            style['Status'],
            style[`Status--${request?.reviewStatus.toLocaleLowerCase()}`],
          )}
          top="x-large"
        >
          {request?.reviewStatus.toLocaleLowerCase()}
        </P>

        <H1>Review request</H1>

        <Section spacing="x-large" top="x-large">
          <H2>App</H2>
          <Dl top="large">
            <Dt>Name</Dt>
            <Dd>{request?.app.name}</Dd>
            <Dt>Description</Dt>
            <Dd>{request?.app.description}</Dd>
          </Dl>
          <Button
            element={RouterLink}
            icon="chevron-right"
            to={`/apps/${request?.app.id}`}
            top="large"
            variant="secondary"
          >
            Show app
          </Button>
        </Section>

        <Section spacing="x-large" style_type="white">
          <H2>Owner</H2>
          {request?.team && (
            <TeamSection
              name={request.team.name}
              organizationName={organization?.name ?? 'N/A'}
              teamId={request.team.id}
            />
          )}
          {request?.user && (
            <UserSection
              email={request.user.email}
              firstName={request.user.firstName}
              lastName={request.user.lastName}
              organizationName={organization?.name ?? 'N/A'}
              userId={request.user.id}
            />
          )}
        </Section>

        <Section spacing="x-large">
          <H2>Decision</H2>
          <Dl>
            <Dt>API</Dt>
            <Dd>{request?.api.name}</Dd>
            <Dt>Use case</Dt>
            <pre>{request?.useCase}</pre>

            <Dt>Terms of use accepted at</Dt>
            <Dd>{humanDatetime(request?.termsOfUseAcceptedAt ?? null)}</Dd>

            <Dt>Scopes requested</Dt>
            {request?.scopes && request.scopes.length > 0
              ? request.scopes.map((scope) => (
                  <div className={style['Scopes']} key={scope.name}>
                    <P>{scope.name}</P>
                    <P className={style['ScopeDescription']}>
                      {scope.description}
                    </P>
                  </div>
                ))
              : 'No scope requested'}
          </Dl>
          <Dl>
            <Dt>External ticket id</Dt>
            <Dd>{request?.externalTicketId}</Dd>
            <Dt>External comment</Dt>
            <Dd>{request?.externalTicketComment}</Dd>
          </Dl>
          <FormSet on_submit={onSubmit} top="medium" vertical>
            <FormRow>
              <Checkbox
                disabled={!editableForm}
                label="Received first-level decision from API provider"
                {...register.checkbox(
                  'checklist.receivedFirstLevelApprovalFromApiProvider',
                )}
              />
            </FormRow>

            <FormRow top="medium">
              <Textarea
                label="Comment"
                rows={5}
                stretch
                {...register.textarea('comment')}
                disabled={!editableForm}
              />
            </FormRow>

            {editableForm && (
              <FormRow top="x-large" vertical={false}>
                <Button
                  disabled={
                    !values.checklist?.receivedFirstLevelApprovalFromApiProvider
                  }
                  right="small"
                  type="submit"
                >
                  Approve
                </Button>
                <Button
                  disabled={
                    !values.checklist?.receivedFirstLevelApprovalFromApiProvider
                  }
                  onClick={onDeny.execute}
                  variant="secondary"
                >
                  Deny
                </Button>
              </FormRow>
            )}
            {error && (
              <FormRow top="small">
                <FormStatus text={error} />
              </FormRow>
            )}
          </FormSet>
        </Section>
      </Container>
    </>
  );
}

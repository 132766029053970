import { Button, FormRow, FormSet, FormStatus, Input } from '@dnb/eufemia';
import {
  add_medium as AddMediumIcon,
  close_medium as CloseMediumIcon,
  email_medium as EmailMediumIcon,
} from '@dnb/eufemia/icons';
import { useEufemiaForm, useFieldArray } from '@portals/shared-frontend/hooks';
import { noneEmptyValues } from '@portals/shared-frontend/utils';
import { type JSX, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useSWRConfig } from 'swr';
import { z } from 'zod';

import { addMembersToTeam } from '@/api/teams';
import Container from '@/components/Container';
import LoadingModal from '@/components/LoadingModal';
import ModalPage from '@/components/ModalPage';

import style from './AddTeamMember.module.css';

type AddTeamMemberProp = {
  handleClose?: (value: boolean) => void;
};

const emailsFormSchema = z.object({
  memberEmails: z
    .array(z.string().email().or(z.literal('')))
    .min(1)
    .refine(
      (values) => values.some(noneEmptyValues),
      'At least one email is required',
    ),
});

export default function AddTeamMember({
  handleClose,
}: AddTeamMemberProp): JSX.Element {
  const { mutate } = useSWRConfig();
  const { teamId } = useParams();
  const navigate = useNavigate();
  const {
    controller,
    controller: { formError, getError },
    handleSubmit,
    register,
    submitting,
  } = useEufemiaForm(emailsFormSchema, {
    memberEmails: [''],
  });

  const { push, items, remove } = useFieldArray(controller, 'memberEmails');

  const onSubmit = handleSubmit(
    useCallback(
      async ({ memberEmails }) => {
        if (teamId) {
          await addMembersToTeam(
            teamId,
            memberEmails.map((email) => email.toLowerCase()),
          );
          if (handleClose) {
            await mutate(`/teams/${teamId}`);
            handleClose(false);
          }
        }
      },

      [teamId, handleClose, mutate],
    ),
  );

  return (
    <ModalPage
      actualModal
      onClose={() => {
        handleClose ? handleClose(false) : navigate(`/teams/${teamId}`);
      }}
      title="Add team members"
    >
      {submitting && <LoadingModal />}
      <Container size="small">
        <FormSet on_submit={onSubmit} vertical>
          <FormStatus
            bottom="medium"
            text={getError('memberEmails')}
            title="Emails"
          />
          <FormRow label="Email addresses">
            {items.map((_, i) => (
              <Input
                autoFocus={i === 0}
                bottom="medium"
                icon={EmailMediumIcon}
                icon_position="left"
                icon_size="medium"
                input_class={style['AddTeamMemberInput']}
                key={i}
                placeholder="Email"
                size="large"
                stretch
                suffix={
                  items.length > 1 && (
                    <Button
                      icon={CloseMediumIcon}
                      on_click={() => remove(i)}
                      size="medium"
                      variant="secondary"
                    />
                  )
                }
                {...register.input(`memberEmails.${i}`)}
              />
            ))}
          </FormRow>

          <FormRow>
            <Button
              icon={AddMediumIcon}
              icon_position="left"
              on_click={() => push('')}
              size="small"
              variant="tertiary"
            >
              Add more members
            </Button>
          </FormRow>
          <FormRow top="medium" vertical={false}>
            <Button size="large" status={!!formError} type="submit">
              Add members
            </Button>
          </FormRow>
          {formError && (
            <FormRow top="medium">
              <FormStatus text={formError} />
            </FormRow>
          )}
        </FormSet>
      </Container>
    </ModalPage>
  );
}

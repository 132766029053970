import { Anchor, Button, H1 } from '@dnb/eufemia';
import type { TagDto } from '@portals/shared/admin/TagDto';
import { useAsync } from '@portals/shared-frontend/hooks';
import { type JSX, useMemo } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import useSWR from 'swr';

import { deleteTag } from '@/api/tags';
import BackButton from '@/components/BackButton';
import DataTable, { type Column } from '@/components/DataTable';
import LoadingModal from '@/components/LoadingModal';
import LoadingPage from '@/components/LoadingPage';
import { humanDate } from '@/utils';

interface TagRow {
  id: string;
  title: string;
  slug: string;
  createdAt: Date;
  updatedAt: Date;
}

const COLUMNS: Column<TagRow>[] = [
  {
    header: 'Name',
    attribute: 'title',
    render: ({ title, id }) => (
      <Anchor element={Link} to={`/tags/${id}`}>
        {title}
      </Anchor>
    ),
  },
  { header: 'Slug', attribute: 'slug' },
  {
    header: 'Created at',
    attribute: 'createdAt',
    render: ({ createdAt }) => humanDate(createdAt),
  },
  {
    header: 'Updated at',
    attribute: 'updatedAt',
    render: ({ updatedAt }) => humanDate(updatedAt),
  },
];

export default function TagList(): JSX.Element {
  const navigate = useNavigate();
  const {
    data: tags,
    isValidating: tagsLoading,
    mutate: mutateTags,
  } = useSWR<TagDto[]>('/tags');

  const isLoading = tagsLoading;

  const rows = useMemo(() => {
    if (!tags) {
      return [];
    }

    return tags.map<TagRow>(({ id, title, slug, createdAt, updatedAt }) => ({
      id,
      title,
      slug,
      createdAt: new Date(createdAt),
      updatedAt: new Date(updatedAt),
    }));
  }, [tags]);

  const onDelete = useAsync(
    async ({ id }: TagRow) => {
      await deleteTag(id);
      mutateTags((tags) => tags?.filter((tag) => tag.id !== id), false);
    },
    [mutateTags],
  );

  if (isLoading) {
    return <LoadingPage />;
  }

  return (
    <>
      {onDelete.waiting && <LoadingModal />}

      <BackButton to="/">Home</BackButton>

      <H1 top="large">Tags</H1>
      <DataTable
        barContent={
          <Button
            element={Link}
            icon="add"
            icon_position="left"
            to="/tags/new"
            variant="secondary"
          >
            Add tag
          </Button>
        }
        columns={COLUMNS}
        data={rows}
        defaultSortKey="title"
        filterBy={['title', 'slug']}
        onDelete={onDelete.execute}
        onEdit={({ id }) => navigate(id)}
      />
    </>
  );
}

import { Anchor, Button, H1 } from '@dnb/eufemia';
import type { OrganizationDto } from '@portals/shared/admin/OrganizationDto';
import { type JSX, useMemo } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import useSWR from 'swr';

import BackButton from '@/components/BackButton';
import DataTable, { Column } from '@/components/DataTable';
import LoadingPage from '@/components/LoadingPage';
import { humanDate } from '@/utils';

interface OrganizationRow {
  id: string;
  name: string;
  members: number;
  domain: string;
  createdAt: Date;
}

const COLUMNS: Column<OrganizationRow>[] = [
  {
    header: 'Name',
    attribute: 'name',
    render: ({ name, id }) => (
      <Anchor element={Link} to={`/organizations/${id}`}>
        {name}
      </Anchor>
    ),
  },
  { header: 'Members', attribute: 'members' },
  { header: 'Domain', attribute: 'domain' },
  {
    header: 'Created at',
    attribute: 'createdAt',
    render: ({ createdAt }) => humanDate(createdAt),
  },
];

export default function OrganizationList(): JSX.Element {
  const navigate = useNavigate();
  const { data: organizations, isValidating: organizationsLoading } =
    useSWR<OrganizationDto[]>('/organizations');

  const isLoading = organizationsLoading;

  const rows = useMemo(() => {
    if (!organizations) {
      return [];
    }

    return organizations.map<OrganizationRow>(
      ({ id, name, domain, createdAt, userCount }) => ({
        id,
        name,
        domain,
        members: userCount,
        createdAt: new Date(createdAt),
      }),
    );
  }, [organizations]);

  if (isLoading) {
    return <LoadingPage />;
  }

  return (
    <>
      <BackButton to="/">Home</BackButton>

      <H1 top="large">Organizations</H1>
      <DataTable
        barContent={
          <Button
            element={Link}
            icon="add"
            icon_position="left"
            to="/organizations/new"
            variant="secondary"
          >
            Add organization
          </Button>
        }
        columns={COLUMNS}
        data={rows}
        defaultSortKey="name"
        filterBy={['name', 'domain']}
        onEdit={({ id }) => navigate(`${id}/edit`)}
        onShow={({ id }) => navigate(id)}
      />
    </>
  );
}

/* eslint-disable simple-import-sort/imports */
import Prism from 'prismjs';
import 'prismjs/components/prism-bash';
import 'prismjs/components/prism-yaml';
import 'prismjs/components/prism-json';
import 'prismjs/components/prism-java';
import 'prismjs/components/prism-markdown';
import 'prismjs/components/prism-python';
import { ComponentPropsWithoutRef } from 'react';

import MermaidDiagram from '../MermaidDiagram';
import CopyCode from './CopyButton';

import { CodeWrapper } from './markdown.styles';
import { Code } from '@dnb/eufemia';

const codeBlockTitleRegex = /title=(?<quote>["'])(?<title>.*?)\1/;

function parseCodeBlockTitle(metastring?: string): string {
  return metastring?.match(codeBlockTitleRegex)?.groups!['title'] ?? '';
}

interface Props extends ComponentPropsWithoutRef<'code'> {
  node?: {
    data?: {
      meta?: string;
    };
  };
  inline: boolean;
}

export default function CodeSection({
  children,
  className,
  node,
  inline,
}: Props) {
  if (inline) {
    return <Code>{children}</Code>;
  }
  if (className === 'language-mermaid') {
    return <MermaidDiagram content={children} />;
  }
  const codeTitle = node?.data?.meta && parseCodeBlockTitle(node?.data.meta);

  const lang = className?.replace('language-', '') ?? 'txt';
  let prismCode;
  try {
    prismCode = Prism.highlight(
      children?.toString() || '',
      Prism.languages[lang]!,
      lang,
    );
  } catch {
    prismCode = Prism.highlight(
      children?.toString() || '',
      Prism.languages['txt']!,
      'txt',
    );
  }

  return (
    <CodeWrapper>
      {codeTitle && <div className="title">{codeTitle}</div>}
      <pre
        className={`dnb-pre prism-code language-${lang}${
          codeTitle ? ' has-title' : ''
        }`}
      >
        <div dangerouslySetInnerHTML={{ __html: prismCode }} />
      </pre>
      <CopyCode code={children?.toString() || ''} />
    </CodeWrapper>
  );
}

import {
  Button,
  Dd,
  Dialog,
  Dl,
  Dt,
  FormRow,
  FormSet,
  FormStatus,
  H1,
  H2,
  Input,
  Section,
} from '@dnb/eufemia';
import type { TeamDetailsDto } from '@portals/shared/admin/TeamDto';
import {
  createTeamScopePrefix,
  type TeamScopePrefixDto,
} from '@portals/shared/admin/TeamScopePrefix';
import { useAsync, useEufemiaForm } from '@portals/shared-frontend/hooks';
import { hasOwnProperty } from '@portals/shared-frontend/utils';
import { type JSX, useCallback, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import useSWR from 'swr';

import { deleteTeamScopePrefix, saveTeamScopePrefix } from '@/api/teams';
import BackButton from '@/components/BackButton';
import Container from '@/components/Container';
import DataTable, { type Column } from '@/components/DataTable';
import LoadingPage from '@/components/LoadingPage';
import { humanDatetime } from '@/utils';

import MembersSection from './TeamMembersSection';
import TeamPagesSection from './TeamPagesSection';

interface AppRow {
  id: string;
  name: string;
  description: string | null;
}

const APP_COLUMNS: Column<AppRow>[] = [
  { header: 'Name', attribute: 'name' },
  { header: 'Description', attribute: 'description' },
];
const SCOPE_PREFIX_COLUMNS: Column<TeamScopePrefixDto>[] = [
  { header: 'Scope Prefix', attribute: 'scopePrefix' },
];

export default function TeamShow(): JSX.Element {
  const navigate = useNavigate();
  const { teamId } = useParams();

  const {
    data: team,
    isValidating: isLoading,
    mutate,
  } = useSWR<TeamDetailsDto>(`/teams/${teamId}`);
  const [errorMessage, setErrorMessage] = useState('');

  const appRows = useMemo(
    () =>
      team?.apps.map<AppRow>(({ id, name, description }) => ({
        id,
        name,
        description,
      })) ?? [],
    [team],
  );

  const scopePrefixRows = useMemo(() => team?.scopePrefixes ?? [], [team]);

  const { controller, register, handleSubmit, submitting } = useEufemiaForm(
    createTeamScopePrefix,
    {},
  );

  const onSubmit = handleSubmit(
    useCallback(
      async (data) => {
        setErrorMessage('');
        if (teamId) {
          try {
            await saveTeamScopePrefix(teamId, data.scopePrefix);
            mutate();
          } catch (error: unknown) {
            if (
              hasOwnProperty(error, 'message') &&
              typeof error?.message == 'string'
            ) {
              setErrorMessage(error?.message);
            }
          }
        }
      },
      [mutate, teamId],
    ),
  );

  const onDelete = useAsync(
    async ({ scopePrefix }: TeamScopePrefixDto) => {
      if (teamId) {
        await deleteTeamScopePrefix(teamId, scopePrefix);
        mutate();
      }
    },
    [mutate, teamId],
  );

  if (isLoading || !team) {
    return <LoadingPage />;
  }

  return (
    <>
      <BackButton to="/teams">Teams</BackButton>

      <Container centered size="small">
        <H1 bottom="x-large">{team.name}</H1>
        <Section spacing="x-large">
          <H2>Team Info</H2>
          <Dl top="large">
            <Dt>Name</Dt>
            <Dd>{team.name}</Dd>
            {team.description && (
              <>
                <Dt>Description</Dt>
                <Dd>{team.description}</Dd>
              </>
            )}
            <Dt>Created</Dt>
            <Dd>{humanDatetime(team.createdAt)}</Dd>
          </Dl>
        </Section>
      </Container>
      <Section spacing="x-large" style_type="divider">
        <H2 bottom="large" size="x-large" top={false}>
          Apps
        </H2>
        <DataTable
          columns={APP_COLUMNS}
          data={appRows}
          defaultSortKey="name"
          filterBy={['name', 'description']}
          onShow={({ id }) => navigate(`/apps/${id}`)}
        />
      </Section>

      <Section spacing="x-large" style_type="divider">
        <MembersSection team={team} />
      </Section>

      <Section spacing="x-large" style_type="divider">
        <H2 bottom="large" size="x-large" top={false}>
          Scope prefixes
        </H2>

        <DataTable
          barContent={
            <Dialog
              onClose={() => controller.setValue('scopePrefix', null)}
              title="Add scope prefix"
              trigger={(props) => (
                <Button
                  {...props}
                  icon="add"
                  icon_position="left"
                  variant="secondary"
                >
                  Add scope prefix
                </Button>
              )}
            >
              <FormSet on_submit={onSubmit} vertical>
                <Section spacing="x-large" style_type="white">
                  <FormRow>
                    <Input
                      label="Scope Prefix"
                      size={40}
                      {...register.input('scopePrefix')}
                    />
                  </FormRow>
                </Section>
                <FormStatus text={errorMessage} top="medium" />
                <Button
                  disabled={submitting}
                  icon="save"
                  icon_position="left"
                  type="submit"
                >
                  Save
                </Button>
              </FormSet>
            </Dialog>
          }
          columns={SCOPE_PREFIX_COLUMNS}
          data={scopePrefixRows}
          defaultSortKey="scopePrefix"
          filterBy={['scopePrefix']}
          onDelete={onDelete.execute}
        />
      </Section>
      <Section spacing="x-large" style_type="divider">
        <H2 bottom="large" size="x-large" top={false}>
          Team pages
        </H2>
        <TeamPagesSection path={`/content/tpp/${teamId}`} />
      </Section>
    </>
  );
}

/* eslint-disable max-lines */
import {
  Autocomplete,
  Button,
  DatePicker,
  Dd,
  Dl,
  Dt,
  FormRow,
  FormSet,
  H1,
  H2,
  H3,
  InputMasked,
  Link,
  Section,
  Space,
  Tag,
  ToggleButton,
} from '@dnb/eufemia';
import { trash_medium as TrashIcon } from '@dnb/eufemia/icons';
import type { ApiDto } from '@portals/shared/admin/ApiDto';
import type { ApiIncidentDto } from '@portals/shared/admin/ApiIncidentDto';
import {
  useAsync,
  useEufemiaForm,
  useFieldArray,
} from '@portals/shared-frontend/hooks';
import { keyByAndMap } from '@portals/shared-frontend/utils';
import { type JSX, useCallback, useEffect } from 'react';
import { Link as RouterLink, useNavigate, useParams } from 'react-router-dom';
import useSWR from 'swr';

import { saveIncident } from '@/api/incidents';
import BackButton from '@/components/BackButton';
import Container from '@/components/Container';
import DeleteDialog from '@/components/DeleteDialog';
import LoadingModal from '@/components/LoadingModal';
import LoadingPage from '@/components/LoadingPage';
import MarkdownEditor from '@/components/MarkdownEditor';
import MarkdownHelperTable from '@/pages/content/components/MarkdownHelperTable';
import { request } from '@/request';

import { dtoToForm, formToDto } from './mappers';
import {
  DEFAULT_INCIDENT,
  DEFAULT_INCIDENT_UPDATE,
  incidentInputSchema,
} from './schemas';

const TIME_MASK = [/\d/, /\d/, ':', /\d/, /\d/];

export default function IncidentForm(): JSX.Element {
  const { incidentId } = useParams();
  const navigate = useNavigate();

  const { data: apis, isValidating: apisLoading } = useSWR<ApiDto[]>('/api');
  const { data: incident, isValidating: incidentLoading } =
    useSWR<ApiIncidentDto>(incidentId ? `/api-incidents/${incidentId}` : null);

  const {
    controller,
    controller: { setValues, getValue },
    register,
    handleSubmit,
    submitting,
  } = useEufemiaForm(incidentInputSchema, DEFAULT_INCIDENT);

  const updatesController = useFieldArray(controller, 'updates');
  const apisController = useFieldArray(controller, 'apiIds');

  const apiNamesById = keyByAndMap(apis ?? [], 'id', 'name');

  const isIncident = getValue('type') === 'incident';
  const title = incident ? 'Edit incident' : 'Add incident';

  const onSubmit = handleSubmit(
    useCallback(
      async (values) => {
        await saveIncident(formToDto(values));
        navigate('..');
      },
      [navigate],
    ),
  );
  const deleteIncident = useAsync(async () => {
    await request(`/api-incidents/${incidentId}`, 'delete');
    navigate('..', { replace: true });
  }, [incidentId, navigate]);

  useEffect(() => {
    if (incident) setValues(dtoToForm(incident));
  }, [incident, setValues]);

  if (apisLoading || incidentLoading) return <LoadingPage />;

  return (
    <>
      <BackButton to="/api-incidents">Status</BackButton>
      <Container centered size="large">
        {submitting && <LoadingModal />}

        <H1 bottom={false} top="large">
          {title}
        </H1>
        <FormSet label_direction="vertical" on_submit={onSubmit}>
          <Section spacing="x-large" style_type="white">
            {incidentId ? (
              apisController.items.length > 0 && (
                <Dl top="large">
                  <Dt>API</Dt>
                  <Dd>
                    <Tag text={apiNamesById[apisController.items[0].value]} />
                  </Dd>
                </Dl>
              )
            ) : (
              <FormRow direction="vertical" top="medium">
                <Autocomplete
                  {...register.autocomplete('apiIds')}
                  data={apis?.map((api) => ({
                    content: (
                      <H3>
                        {api.name}{' '}
                        <Link
                          element={RouterLink}
                          target="_blank"
                          to={`/apis/${api.id}`}
                        />
                      </H3>
                    ),
                    selected_key: api.id,
                  }))}
                  label="APIs"
                  on_change={({ data }) =>
                    apisController.push(data.selected_key)
                  }
                  prevent_selection
                />
                {apisController.items.length > 0 && (
                  <Tag.Group label="Selected apis" top="x-small">
                    {apisController.items.map(
                      ({ value, index, remove }) =>
                        apiNamesById[value] && (
                          <Tag key={index} onDelete={remove}>
                            {apiNamesById[value]}
                          </Tag>
                        ),
                    )}
                  </Tag.Group>
                )}
              </FormRow>
            )}
            <FormRow top="medium">
              <ToggleButton.Group
                label="Type"
                variant="radio"
                {...register.toggleButtonGroup('type')}
              >
                <ToggleButton text="Incident" value="incident" />
                <ToggleButton text="Maintenance" value="maintenance" />
              </ToggleButton.Group>
            </FormRow>
            <FormRow bottom="medium" top="medium">
              <ToggleButton.Group
                label="Severity"
                variant="radio"
                {...register.toggleButtonGroup('severity')}
              >
                <ToggleButton text="Instabilities" value="1" />
                <ToggleButton text="Reduced" value="2" />
              </ToggleButton.Group>
            </FormRow>
            <MarkdownEditor
              onChange={(_value) => controller.setValue('description', _value)}
              value={controller.values.description}
            />
            <MarkdownHelperTable />
          </Section>
          <Section spacing="x-large">
            <H2>Timestamps</H2>
            <FormRow
              label={isIncident ? 'Reported at' : 'Scheduled start'}
              top="medium"
            >
              <DatePicker
                label={isIncident ? 'Reported at date' : 'Scheduled start date'}
                label_direction="horizontal"
                label_sr_only
                right="x-small"
                show_input
                {...register.datePicker('reportedAtDate')}
              />
              <InputMasked
                label={isIncident ? 'Reported at time' : 'Scheduled start time'}
                label_direction="horizontal"
                label_sr_only
                mask={TIME_MASK}
                placeholder="HH:mm"
                placeholder_char="-"
                show_mask
                size={6}
                {...register.input('reportedAtTime')}
              />
            </FormRow>
            <FormRow
              label={isIncident ? 'Resolved at' : 'Scheduled end'}
              top="medium"
            >
              <DatePicker
                label={isIncident ? 'Resolved at date' : 'Scheduled end date'}
                label_direction="horizontal"
                label_sr_only
                right="x-small"
                show_input
                {...register.datePicker('resolvedAtDate')}
              />
              <InputMasked
                label={isIncident ? 'Resolved at time' : 'Scheduled end time'}
                label_direction="horizontal"
                label_sr_only
                mask={TIME_MASK}
                placeholder="HH:mm"
                placeholder_char="-"
                show_mask
                size={6}
                {...register.input('resolvedAtTime')}
              />
            </FormRow>
          </Section>
          {incidentId && (
            <Section spacing="x-large" style_type="white">
              <H2>Updates</H2>
              {updatesController.items.map(({ index, remove }) => (
                <Space bottom="x-large" key={index} top="medium">
                  <MarkdownEditor
                    onChange={(_value) => {
                      controller.setValue(
                        `updates.${index}.description`,
                        _value,
                      );
                    }}
                    value={controller.values.updates?.at(index)?.description}
                  />

                  <FormRow label="Reported at" top="medium">
                    <DatePicker
                      label="Reported at date"
                      label_direction="horizontal"
                      label_sr_only
                      right="x-small"
                      show_input
                      {...register.datePicker(
                        `updates.${index}.reportedAtDate`,
                      )}
                    />
                    <InputMasked
                      label="Reported at time"
                      label_direction="horizontal"
                      label_sr_only
                      mask={TIME_MASK}
                      placeholder="HH:mm"
                      placeholder_char="-"
                      show_mask
                      size={6}
                      {...register.input(`updates.${index}.reportedAtTime`)}
                    />
                  </FormRow>
                  <FormRow top="medium">
                    <Button
                      icon={TrashIcon}
                      icon_position="left"
                      on_click={remove}
                      variant="signal"
                    >
                      Remove update
                    </Button>
                  </FormRow>
                </Space>
              ))}
              <FormRow top="medium">
                <Button
                  icon="add"
                  icon_position="left"
                  on_click={() =>
                    updatesController.push(DEFAULT_INCIDENT_UPDATE)
                  }
                  variant="secondary"
                >
                  Add update
                </Button>
              </FormRow>
            </Section>
          )}
          <Section spacing="medium" style_type="divider">
            <Button
              disabled={submitting}
              icon="save"
              icon_position="left"
              right="small"
              text="Save"
              type="submit"
            />
            <DeleteDialog
              loading={deleteIncident.waiting}
              onDelete={deleteIncident.execute}
              text="This will permanently delete this incident and all its updates."
              title="Are you sure you want to delete this incident?"
            />
          </Section>
        </FormSet>
      </Container>
    </>
  );
}

/* eslint-disable max-lines */
import {
  Accordion,
  Anchor,
  Autocomplete,
  Button,
  Checkbox,
  Code,
  Dd,
  Dialog,
  Div,
  Dl,
  Dropdown,
  Dt,
  FormLabel,
  FormRow,
  FormSet,
  FormStatus,
  H1,
  H2,
  H3,
  HelpButton,
  Input,
  P,
  Radio,
  Section,
  Space,
  Tag,
  Textarea,
  ToggleButton,
} from '@dnb/eufemia';
import {
  edit as EditIcon,
  trash_medium as TrashIcon,
} from '@dnb/eufemia/icons';
import {
  AccessTypes,
  type ApiDto,
  type ApiInputDto,
  ApiType,
  type ApiWithOwnerDto,
  SeverityClassification,
} from '@portals/shared/admin/ApiDto';
import type { AppDto } from '@portals/shared/admin/AppDto';
import type { OrganizationDto } from '@portals/shared/admin/OrganizationDto';
import type { TagDto } from '@portals/shared/admin/TagDto';
import type { TeamDto } from '@portals/shared/admin/TeamDto';
import {
  useAsync,
  useEufemiaForm,
  useFieldArray,
} from '@portals/shared-frontend/hooks';
import { keyByAndMap } from '@portals/shared-frontend/utils';
import type { DeepPartial } from '@portals/shared-frontend/utils/types';
import { type JSX, useCallback, useEffect, useMemo } from 'react';
import { Link as RouterLink, useNavigate, useParams } from 'react-router-dom';
import useSWR from 'swr';

import { deleteApi, upsertApi } from '@/api/apis';
import BackButton from '@/components/BackButton';
import Container from '@/components/Container';
import DeleteDialog from '@/components/DeleteDialog';
import LoadingModal from '@/components/LoadingModal';
import LoadingPage from '@/components/LoadingPage';
import useFeatureFlags from '@/hooks/useFeatureFlags';

import {
  type ApiEnvironmentFormInput,
  apiForm,
  type ApiFormInput,
  type ApiFormOutput,
} from './schema';

import style from './ApiForm.module.css';

const DEFAULT_API: DeepPartial<ApiFormInput> = {
  availableInLiveMode: false,
  attachable: false,
  stage: 'unpublished',
  organizations: [],
  teams: [],
  enableStatus: false,
  isPublic: false,
  classification: 'domain',
  tags: [],
  environments: [
    {
      name: 'Production',
      slug: 'prod',
      isDefault: true,
      isInternal: false,
    },
    {
      name: 'UAT',
      slug: 'uat',
      isDefault: false,
      isInternal: true,
    },
    {
      name: 'SIT',
      slug: 'sit',
      isDefault: false,
      isInternal: true,
    },
  ],
  ordering: '999',
  deprecated: false,
  accessTypes: [],
  enableFaq: false,
  type: ApiType.REST,
};

const DEFAULT_ENVIRONMENT: Partial<ApiEnvironmentFormInput> = {
  name: '',
  slug: '',
  isInternal: true,
  isDefault: false,
};

const CLASSIFICATION_OPTIONS = {
  system: 'System',
  domain: 'Domain',
  experience: 'Experience',
  public: 'Public',
  partner: 'Partner',
  technical: 'Technical',
};

const SEVERITY_CLASSIFICATION_OPTIONS = {
  [SeverityClassification.OPEN]: 'Open',
  [SeverityClassification.INTERNAL]: 'Internal',
  [SeverityClassification.CONFIDENTIAL]: 'Confidential',
  [SeverityClassification.STRICTLY_CONFIDENTIAL]: 'Strictly Confidential',
};

const STAGE_OPTIONS = {
  launched: 'Launched',
  upcoming: 'Upcoming',
  lab: 'Lab',
  deprecated: 'Deprecated',
  unpublished: 'Unpublished',
  review: 'In review',
};

const APPROVAL_STAGES = {
  test: 'test',
  live: 'live',
  public: 'public',
};

function formToDto(data: ApiFormOutput): ApiInputDto {
  return {
    ...data,
    description: data.description || null,
    productPageUrl: data.productPageUrl || null,
    liveModeWebhookSecret: data.liveModeWebhookSecret || null,
    testModeWebhookSecret: data.testModeWebhookSecret || null,
    liveModeWebhookUrl: data.liveModeWebhookUrl || null,
    testModeWebhookUrl: data.testModeWebhookUrl || null,
    termsOfUse: data.termsOfUse || null,
    ordering: Number.parseInt(data.ordering),
    accessTypes: data.accessTypes || null,
  };
}

function dtoToForm(data: ApiDto): DeepPartial<ApiFormInput> {
  return {
    ...data,
    description: data.description || '',
    productPageUrl: data.productPageUrl || '',
    liveModeWebhookSecret: data.liveModeWebhookSecret || '',
    testModeWebhookSecret: data.testModeWebhookSecret || '',
    liveModeWebhookUrl: data.liveModeWebhookUrl || '',
    testModeWebhookUrl: data.testModeWebhookUrl || '',
    termsOfUse: data.termsOfUse || '',
    ordering: data.ordering.toString(),
    appId: data.appId || '',
    accessTypes: data.accessTypes || [],
    piiExposed: data.piiExposed || false,
    gdprCompliant: data.gdprCompliant || false,
  };
}

export default function ApiForm(): JSX.Element {
  const { apiId } = useParams();
  const navigate = useNavigate();
  const { featureFlags } = useFeatureFlags();

  // Form state
  const {
    controller,
    controller: { setValues, getError },
    register,
    handleSubmit,
    submitting,
  } = useEufemiaForm(apiForm, DEFAULT_API);

  const environmentsController = useFieldArray(controller, 'environments');
  const teamsController = useFieldArray(controller, 'teams');
  const organizationsController = useFieldArray(controller, 'organizations');

  // Fetch data
  const { data: tags, isValidating: tagsLoading } = useSWR<TagDto[]>('/tags');
  const { data: apps, isValidating: appsLoading } = useSWR<AppDto[]>('/apps');
  const { data: teams, isValidating: teamsLoading } =
    useSWR<TeamDto[]>('/teams');
  const { data: organizations, isValidating: organizationsLoading } =
    useSWR<OrganizationDto[]>('/organizations');
  const { data: api, isValidating: apiLoading } = useSWR<ApiWithOwnerDto>(
    apiId ? `/api/${apiId}` : null,
  );

  // Data
  const title = api ? 'Edit API' : 'Add API';

  const isLoading =
    tagsLoading ||
    teamsLoading ||
    organizationsLoading ||
    apiLoading ||
    appsLoading;

  const teamNamesById = useMemo(
    () => keyByAndMap(teams ?? [], 'id', 'name'),
    [teams],
  );

  const organizationsNamesById = useMemo(
    () => keyByAndMap(organizations ?? [], 'id', 'name'),
    [organizations],
  );

  // Set initial values
  useEffect(() => {
    if (api) {
      setValues(dtoToForm(api));
    }
  }, [api, setValues]);

  useEffect(() => {
    const app = apps?.find((app) => app.id === api?.appId);
    controller.setValue('appId', app?.id);
  }, [api?.appId, apps]);

  const app = useMemo(
    () => apps?.find((app) => app.id === controller.values.appId),
    [apps, controller.values.appId],
  );

  // Handle submit
  const onSubmit = handleSubmit(
    useCallback(
      async (data) => {
        await upsertApi(formToDto(data));
        navigate('..');
      },
      [navigate],
    ),
  );

  const onDelete = useAsync(async () => {
    if (apiId) {
      await deleteApi(apiId);
      navigate('/apis');
    }
  }, [apiId, navigate]);

  if (isLoading) {
    return <LoadingPage />;
  }

  const assignNewApp = (appId: string) => {
    const app = apps?.find((app) => app.id === appId);
    controller.setValue('appId', app?.id);
  };

  return (
    <>
      <BackButton to="/apis">APIs</BackButton>

      <Container centered size="small">
        {submitting && <LoadingModal />}

        <H1 top="large">{title}</H1>
        <P>
          An API is created to handle how your API documentation will be
          available for users. It will store some basic information, handle
          access management for the API and you will be able to upload different
          versions based on different environments.
        </P>
        <FormSet label_direction="vertical" on_submit={onSubmit}>
          <Section spacing="x-large" style_type="white">
            <FormRow>
              <Radio.Group
                id="api-type"
                label="Type"
                {...register.radioGroup('type')}
              >
                {Object.values(ApiType).map((apiType) => (
                  <Radio key={apiType} label={apiType} value={apiType} />
                ))}
              </Radio.Group>
            </FormRow>
            <FormRow top="medium">
              <Input
                label="Name"
                size={40}
                suffix={
                  <HelpButton size="small">
                    This is the name of the API.
                  </HelpButton>
                }
                {...register.input('name')}
              />
            </FormRow>
            <FormRow top="medium">
              <Input
                label="Slug"
                size={40}
                suffix={
                  <HelpButton size="small">
                    The slug is a human readable ID. This should be unique for
                    the API and in the following format: <Code>api-name</Code>.
                    For example, for the Payment Initiation Service API the slug
                    is <Code>psd2-payments</Code>.
                  </HelpButton>
                }
                {...register.input('slug')}
              />
            </FormRow>
            <FormRow top="medium">
              <Textarea
                cols={40}
                label="Description"
                rows={10}
                suffix={
                  <HelpButton size="small">
                    This is a short description of the API which will be visible
                    to the users in the API Explorer page.
                  </HelpButton>
                }
                {...register.textarea('description')}
              />
            </FormRow>
            <FormRow top="medium">
              <Textarea
                cols={40}
                label="Terms of use"
                rows={10}
                suffix={
                  <HelpButton size="small">
                    This is the terms a user must agree to and abide by in order
                    to use this API. Leave empty if the API has no such terms.
                  </HelpButton>
                }
                {...register.textarea('termsOfUse')}
              />
            </FormRow>
            <FormRow top="medium">
              <Dropdown
                data={CLASSIFICATION_OPTIONS}
                label="Classification"
                suffix={
                  <HelpButton size="small">
                    Classify the API according to the Integration Blueprint.
                    Only public APIs will be exposed publicly.
                  </HelpButton>
                }
                {...register.dropdown('classification')}
              />
            </FormRow>
            <FormRow top="medium">
              <Dropdown
                data={STAGE_OPTIONS}
                label="Stage (deprecated)"
                {...register.dropdown('stage')}
              />
            </FormRow>
            <FormRow top="medium">
              <Checkbox
                label="Deprecated"
                {...register.checkbox('deprecated')}
              />
            </FormRow>
            <FormRow top="medium">
              <Checkbox
                label="Enable status reporting"
                suffix={
                  <HelpButton size="small">
                    Select if the API is displayed in the Status page in the
                    Developer Portal. This also enables users to subscribe to
                    status updates for the API.
                  </HelpButton>
                }
                {...register.checkbox('enableStatus')}
              />
            </FormRow>
            <FormRow top="medium">
              <Checkbox
                label="Enable FAQ"
                suffix={
                  <HelpButton size="small">
                    Select if the API should display FAQ section in the
                    documentation pages. Make sure that there is an FAQ section
                    for the API.
                  </HelpButton>
                }
                {...register.checkbox('enableFaq')}
              />
            </FormRow>
          </Section>

          <Section spacing="x-large">
            <H2>Visiblity</H2>
            <FormRow top="medium">
              <Checkbox
                label="Should be publicly available"
                suffix={
                  <HelpButton size="small">
                    Select if the API should be public and available for
                    everyone.
                  </HelpButton>
                }
                {...register.checkbox('isPublic')}
              />
            </FormRow>
            <FormStatus
              state="warn"
              stretch
              text="Leaving both of these fields empty will result in no one having access to the API. Selecting an organization will grant ALL users of the organization access to the API, regardless of team memberships."
              top="medium"
            />
            <FormRow direction="vertical" top="medium">
              <Autocomplete
                data={organizationsNamesById}
                label="Organizations"
                on_change={({ data }) =>
                  organizationsController.push(data.selected_key)
                }
                prevent_selection
                suffix={
                  <HelpButton size="small">
                    Selecting an organization will grant all users in the
                    organization access to the API.
                  </HelpButton>
                }
              />
              {organizationsController.items.length > 0 && (
                <Tag.Group label="Selected teams" top="x-small">
                  {organizationsController.items.map(
                    ({ value, index, remove }) =>
                      organizationsNamesById[value] && (
                        <Tag key={index} onDelete={remove}>
                          {organizationsNamesById[value]}
                        </Tag>
                      ),
                  )}
                </Tag.Group>
              )}
            </FormRow>
            <FormRow direction="vertical" top="medium">
              <Autocomplete
                data={teamNamesById}
                label="Teams"
                on_change={({ data }) =>
                  teamsController.push(data.selected_key)
                }
                prevent_selection
                suffix={
                  <HelpButton size="small">
                    Selecting a team will grant all members of the team access
                    to the API.
                  </HelpButton>
                }
              />
              {teamsController.items.length > 0 && (
                <Tag.Group label="Selected teams" top="x-small">
                  {teamsController.items.map(
                    ({ value, index, remove }) =>
                      teamNamesById[value] && (
                        <Tag key={index} onDelete={remove}>
                          {teamNamesById[value]}
                        </Tag>
                      ),
                  )}
                </Tag.Group>
              )}
            </FormRow>
          </Section>

          <Section spacing="x-large" style_type="white">
            <H2>Tags</H2>
            <FormRow top="medium">
              <ToggleButton.Group
                variant="checkbox"
                {...register.toggleButtonGroup('tags', { multiselect: true })}
              >
                {tags?.map((tag) => (
                  <ToggleButton
                    key={tag.id}
                    text={tag.title}
                    value={tag.title}
                  />
                ))}
              </ToggleButton.Group>
            </FormRow>
          </Section>

          <Section spacing="x-large">
            <H2>Environments</H2>
            <FormStatus
              id="environments-status"
              text={getError('environments')}
              title="Environments"
              top="medium"
            />
            {environmentsController.items.map(({ index, remove }) => (
              <Space bottom="x-large" key={index} top="medium">
                <FormRow>
                  <Input
                    label="Name"
                    size={40}
                    suffix={
                      <HelpButton size="small">
                        The name of the environment. This is what users of the
                        Developer Portal will see.
                      </HelpButton>
                    }
                    {...register.input(`environments.${index}.name`)}
                  />
                </FormRow>
                <FormRow top="medium">
                  <Input
                    label="Slug"
                    size={40}
                    suffix={
                      <HelpButton size="small">
                        This is the slug which is used to identify the
                        environment. It should be unique within the API.
                      </HelpButton>
                    }
                    {...register.input(`environments.${index}.slug`)}
                  />
                </FormRow>
                <FormRow top="medium">
                  <Checkbox
                    label="Is default environment"
                    suffix={
                      <HelpButton size="small">
                        Select if this environment should be the default
                        environment when browsing documentation. You can only
                        have one default environment.
                      </HelpButton>
                    }
                    {...register.checkbox(`environments.${index}.isDefault`)}
                  />
                </FormRow>
                <FormRow top="medium">
                  <Checkbox
                    label="Is internal DNB environment"
                    suffix={
                      <HelpButton size="small">
                        Select if this environment is internal. Internal means
                        available for DNB users only.
                      </HelpButton>
                    }
                    {...register.checkbox(`environments.${index}.isInternal`)}
                  />
                </FormRow>
                <FormRow top="medium">
                  <Button
                    icon={TrashIcon}
                    icon_position="left"
                    on_click={remove}
                    variant="signal"
                  >
                    Remove environment
                  </Button>
                </FormRow>
              </Space>
            ))}
            <FormRow top="medium">
              <Button
                icon="add"
                icon_position="left"
                on_click={() =>
                  environmentsController.push(DEFAULT_ENVIRONMENT)
                }
                variant="secondary"
              >
                Add environment
              </Button>
            </FormRow>
          </Section>

          <Section spacing="x-large" style_type="white">
            <H2>Access management</H2>
            <FormRow top="small">
              <H3>Access types</H3>
              <HelpButton left="x-small" size="small" title="Access types">
                <P>
                  For now we provide two different ways to give API Consumers
                  access to your API.
                </P>
                <H3 top="medium">OAuth (system-to-system)</H3>
                <Div>
                  OAuth refers to the authorization protocol OAuth 2.0. The
                  protocol enables third-party applications to access user data
                  without requiring the user to share their login credentials.
                  Instead, the user grants the third-party application
                  permission to access their data on their behalf. For now we
                  only support system-to-system (S2S) authentication that is
                  based on client authentication using{' '}
                  <code className="dnb-code">private_key_jwt</code> method as
                  defined in{' '}
                  <Anchor href="https://openid.net/specs/openid-connect-core-1_0.html#ClientAuthentication">
                    OpenID Connect
                  </Anchor>
                  .
                </Div>
                <H3 top="medium">API keys</H3>
                <Div>
                  API keys, on the other hand, are used for authentication and
                  identification purposes. They are essentially unique
                  identifiers that are assigned to users or applications to
                  enable access to an API (Application Programming Interface).
                  API keys are often used to control access to APIs by limiting
                  the number of requests an application can make or to restrict
                  access to certain endpoints.
                </Div>
                <H3 top="medium">Consequences of changing access type</H3>
                <Div>
                  Changing the access type of an existing APIs is NOT something
                  you should do because it can lead to API consumers loosing
                  access. For example, changing access type of the API from
                  OAuth to API keys will result in consumer apps using OAuth
                  access no longer being able to attach to the API. Consumers
                  can&apos;t attach APIs with different access type to the same
                  app. For this they need to make different apps.
                </Div>
              </HelpButton>
            </FormRow>
            <FormRow direction="vertical" top="small">
              <ToggleButton.Group
                {...register.toggleButtonGroup('accessTypes', {
                  multiselect: true,
                })}
                variant="checkbox"
              >
                <ToggleButton text="API keys" value={AccessTypes.API_KEYS} />
                <ToggleButton
                  text="OAuth (S2S)"
                  value={AccessTypes.O_AUTH_SYSTEM_TO_SYSTEM}
                />
              </ToggleButton.Group>
            </FormRow>
            <FormRow top="medium">
              <Checkbox
                label="Available in live mode"
                suffix={
                  <HelpButton size="small">
                    Select whether the API is available in live mode.
                  </HelpButton>
                }
                {...register.checkbox('availableInLiveMode')}
              />
            </FormRow>
            <FormRow top="medium">
              <Checkbox
                label="Allow attaching to app"
                suffix={
                  <HelpButton size="small">
                    Should able to attach this API to an app they have created.
                    This allows the users to request access to this API.
                  </HelpButton>
                }
                {...register.checkbox('attachable')}
              />
            </FormRow>
            <Accordion
              expanded={false}
              remember_state
              title="Advanced (webhooks)"
              top="medium"
            >
              <Accordion.Content>
                <FormRow top="small">
                  <H3>Webhooks</H3>
                  <HelpButton
                    left="x-small"
                    size="small"
                    title="Webhook URL and secret"
                  >
                    <H3>Webhook URL</H3>
                    <P bottom="small">
                      The URL of a webhook that should be called when an API
                      access event occurs in the development and production
                      environment.
                    </P>
                    <H3>Webhook secret</H3>
                    <P>
                      The secret is used to sign payloads when calling the API
                      access webhook. We have one for each environment i.e. the
                      development and production environment.
                    </P>
                  </HelpButton>
                </FormRow>
                <FormRow top="medium">
                  <Input
                    label="Test mode webhook URL"
                    size={40}
                    {...register.input('testModeWebhookUrl')}
                  />
                </FormRow>
                <FormRow top="medium">
                  <Input
                    label="Test mode webhook secret"
                    size={40}
                    {...register.input('testModeWebhookSecret')}
                  />
                </FormRow>
                <FormRow top="medium">
                  <Input
                    label="Live mode webhook URL"
                    size={40}
                    {...register.input('liveModeWebhookUrl')}
                  />
                </FormRow>
                <FormRow top="medium">
                  <Input
                    label="Live mode webhook secret"
                    size={40}
                    {...register.input('liveModeWebhookSecret')}
                  />
                </FormRow>
              </Accordion.Content>
            </Accordion>
          </Section>

          <Section spacing="x-large">
            <H2>Advanced options</H2>
            <FormRow top="medium">
              <Input
                label="Product page URL"
                size={40}
                suffix={
                  <HelpButton size="small">
                    This is the relative URL to the product page for the given
                    API. Not all APIs have their own product page.
                  </HelpButton>
                }
                {...register.input('productPageUrl')}
              />
            </FormRow>
            <FormRow top="medium">
              <Input
                label="Ordering (deprecated)"
                size={40}
                {...register.input('ordering')}
              />
            </FormRow>
          </Section>
          <Section spacing="x-large" style_type="white">
            <H2>Approval process</H2>
            <FormRow top="medium">
              <Dropdown
                data={APPROVAL_STAGES}
                label="Approval stage"
                suffix={
                  <HelpButton size="small">
                    <P>
                      <b>test</b>: Client creation requires approval for both
                      test and live environments.
                    </P>
                    <P>
                      <b>live</b>: Client creation requires approval for live
                      environment only. Access to test environment is auto
                      approved.
                    </P>
                    <P>
                      <b>public</b>: Client creation is auto approved in both
                      environments.
                    </P>
                  </HelpButton>
                }
                {...register.dropdown('approvalStage')}
              />
            </FormRow>
          </Section>
          <Section spacing="x-large">
            <H2>Service Now</H2>
            <FormRow top="medium">
              <Checkbox
                label="Enable Service Now request forms"
                {...register.checkbox('enableServiceNowRequest')}
                suffix={
                  <HelpButton size="small">
                    Check this to enable the Service Now request flow in the
                    portal. It is important that the API also has a Service Now
                    Identifier
                  </HelpButton>
                }
              />
            </FormRow>
            <FormRow top="medium">
              <Input
                label="ServiceNow Identifier (BA/BSN)"
                size={40}
                {...register.input('serviceNowIdentifier')}
                suffix={
                  <HelpButton
                    size="small"
                    title="ServiceNow Identifier (BA/BSN)"
                  >
                    The Business application id (BAXXXXX) or Service offering id
                    (BSNXXXXXXX) to identify the API in ServiceNow.
                  </HelpButton>
                }
              />
            </FormRow>
            <FormRow top="medium">
              <FormLabel for_id="severity-classification">
                Severity classification
                <HelpButton left="x-small" size="small">
                  How is the severity of the data exposed by this API
                  classified.
                </HelpButton>
              </FormLabel>
            </FormRow>
            <FormRow>
              <Radio.Group
                id="severity-classification"
                layout_direction="column"
                {...register.radioGroup('severityClassification')}
              >
                {Object.entries(SEVERITY_CLASSIFICATION_OPTIONS).map(
                  ([value, label]) => (
                    <Radio key={value} label={label} value={value} />
                  ),
                )}
              </Radio.Group>
            </FormRow>

            <FormRow top="medium">
              <Checkbox
                label="Exposing personal identifiable information"
                {...register.checkbox('piiExposed')}
                suffix={
                  <HelpButton size="small">
                    This should be checked of the API exposed personal
                    identifiable information.
                  </HelpButton>
                }
              />
            </FormRow>
            <FormRow top="medium">
              <Checkbox
                label="GDPR Compliant"
                {...register.checkbox('gdprCompliant')}
                suffix={
                  <HelpButton size="small">
                    This should be checked of the API required to follow the
                    GDPR regulations.
                  </HelpButton>
                }
              />
            </FormRow>
          </Section>

          <Section spacing="x-large" style_type="white">
            <H2>Scopes</H2>
            {api?.scopes?.length === 0 ? (
              <P top="small">No scopes defined for API</P>
            ) : (
              api?.scopes
                ?.filter(
                  (scope) =>
                    !controller.values.scopesToDelete?.includes(scope.id),
                )
                ?.map((scope) => (
                  <Space
                    className={style['ScopeList']}
                    key={scope.id}
                    top="medium"
                  >
                    <Dl key={scope.id}>
                      <Dt>{scope?.name}</Dt>
                      <Dd>{scope?.description}</Dd>
                    </Dl>
                    <Div className={style['ScopeList-action']}>
                      <Dialog
                        confirmText="Delete"
                        confirmType="warning"
                        declineText="Cancel"
                        description="Scope will be deleted as soon as you save the form. This action cannot be undone."
                        icon={TrashIcon}
                        onConfirm={({ close }) => {
                          close();
                          controller.setValue('scopesToDelete', [
                            ...(controller.values.scopesToDelete || []),
                            scope.id,
                          ]);
                        }}
                        title="Are you sure you want to delete this scope?"
                        triggerAttributes={{
                          text: 'Delete scope',
                          icon: TrashIcon,
                          variant: 'signal',
                        }}
                        variant="confirmation"
                      />
                    </Div>
                  </Space>
                ))
            )}
          </Section>
          <Section spacing="x-large">
            <H2>
              App{' '}
              {app && featureFlags.ALLOW_API_OWNER_APP_CHANGE && (
                <Button
                  icon={EditIcon}
                  on_click={() => {
                    controller.setValue('appId', null);
                  }}
                  right="x-small"
                  size="medium"
                  title="Edit"
                />
              )}
            </H2>
            {app ? (
              <>
                <Dl>
                  <Dt>Name</Dt>
                  <Dd>{app.name} </Dd>
                  <Dt>Owner</Dt>
                  <Dd>
                    {app.owner.type == 'team'
                      ? 'Team Id: ' + app?.owner.id
                      : 'User Id: ' + app?.owner.id}
                  </Dd>
                </Dl>
                <Button
                  element={RouterLink}
                  icon="chevron-right"
                  target="_blank"
                  to={
                    app.owner.type == 'team'
                      ? `/teams/${app.owner.id}`
                      : `/users/${app.owner.id}`
                  }
                  top="large"
                  variant="secondary"
                >
                  Show owner
                </Button>
              </>
            ) : (
              featureFlags.ALLOW_API_OWNER_APP_CHANGE && (
                <Autocomplete
                  data={apps?.map((app) => ({
                    selected_key: app.id,
                    content: (
                      <Dl>
                        <Dt>{app.name}</Dt>
                        <P size="small">{app.id}</P>
                      </Dl>
                    ),
                  }))}
                  label="Assign new app"
                  on_change={({ data }) => assignNewApp(data.selected_key)}
                  required
                  suffix={
                    <HelpButton size="small">
                      The api has no owner app. Select available apps to assign
                      owner.
                    </HelpButton>
                  }
                />
              )
            )}
          </Section>

          <Section spacing="large" style_type="divider">
            <Button
              disabled={submitting}
              icon="save"
              icon_position="left"
              right="small"
              type="submit"
            >
              Save
            </Button>
            <DeleteDialog
              loading={onDelete.waiting}
              onDelete={onDelete.execute}
              text="This will permanently delete the API and cannot be undone."
              title="Are you sure you want to delete this API?"
            />
          </Section>
        </FormSet>
      </Container>
    </>
  );
}

import { Autocomplete } from '@dnb/eufemia';
import { isObject } from '@portals/shared-frontend/utils';
import type { ComponentPropsWithoutRef, JSX, ReactNode } from 'react';
import { useMemo } from 'react';

/**
 *
 * Drop in replacement for Eufemia's Autocomplete component
 * that handles a `data` object properly
 *
 * TODO: Remove when fixed in Eufemia
 */

export default function FixedAutocomplete({
  data,
  value,
  on_change,
  ...props
}: ComponentPropsWithoutRef<typeof Autocomplete>): JSX.Element {
  const fixedData = useMemo(() => {
    if (!isObject(data)) {
      return data;
    }

    return Object.entries(data).map(([key, value]) => ({
      selected_key: key,
      selected_value: value as ReactNode,
      content: value as ReactNode,
    }));
  }, [data]);

  const indices = useMemo(() => {
    const map = new Map<unknown, number>();

    if (isObject(data)) {
      const entries = Object.entries(data);

      for (const [i, [key]] of entries.entries()) {
        map.set(key, i);
      }
    }

    return map;
  }, [data]);

  const fixedOnChange = useMemo(() => {
    if (!isObject(data)) {
      return on_change;
    }

    return ({ value, ...event }: Record<string, unknown>) =>
      on_change?.({
        value: typeof value === 'number' ? Object.keys(data)[value] : value,
        ...event,
      });
  }, [data, on_change]);

  const fixedValue = useMemo(() => {
    if (!isObject(data) || value == null) {
      return value;
    }

    return indices.get(value);
  }, [data, indices, value]);

  return (
    <Autocomplete
      data={fixedData}
      on_change={fixedOnChange}
      value={fixedValue}
      {...props}
    />
  );
}

import { Button, H1, Link } from '@dnb/eufemia';
import type { TemplateDto } from '@portals/shared/admin/TemplateDto';
import type { JSX } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import useSWR from 'swr';

import BackButton from '@/components/BackButton';
import DataTable, { Column } from '@/components/DataTable';
import { humanDatetime } from '@/utils';

const COLUMNS: Column<TemplateRow>[] = [
  {
    header: 'Name',
    attribute: 'name',
    render: ({ name, id }) => (
      <Link element={RouterLink} to={`/templates/${id}`}>
        {name}
      </Link>
    ),
  },
  {
    header: 'Created',
    attribute: 'createdAt',
    render: ({ createdAt }) => humanDatetime(createdAt),
  },
  {
    header: 'updated',
    attribute: 'updatedAt',
    render: ({ updatedAt }) => humanDatetime(updatedAt),
  },
];

interface TemplateRow {
  id: string;
  name: string;
  createdAt: string;
  updatedAt: string;
}

export default function TemplateList(): JSX.Element {
  const navigate = useNavigate();

  const { data: templates, isValidating: loadingTemplates } =
    useSWR<TemplateDto[]>('/templates');

  const rows =
    templates?.map<TemplateRow>((template) => ({
      ...template,
    })) ?? [];

  return (
    <>
      <BackButton to="/">Home</BackButton>
      <H1 bottom="large">Templates</H1>

      <DataTable
        barContent={
          <Button
            element={RouterLink}
            icon="add"
            icon_position="left"
            to="/templates/new"
            variant="secondary"
          >
            Add template
          </Button>
        }
        columns={COLUMNS}
        data={rows}
        defaultSortKey="name"
        filterBy={['name']}
        onShow={({ id }) => navigate(`/templates/${id}`)}
        skeleton={loadingTemplates}
      />
    </>
  );
}

import { H1, H2 } from '@dnb/eufemia';
import type { ApiWithOwnerDto } from '@portals/shared/admin/ApiDto';
import type { SubscriberWithSubscriptionsDto } from '@portals/shared/admin/SubscriptionDto';
import type { JSX } from 'react';
import { useParams } from 'react-router-dom';
import useSWR from 'swr';

import BackButton from '@/components/BackButton';
import DataTable from '@/components/DataTable';
import LoadingPage from '@/components/LoadingPage';
import { Download } from '@/pages/subscribers/DownloadEmails';
import { SUBSCRIBER_COLUMNS } from '@/pages/subscribers/SubscriberList';

export default function ShowSubscriberList(): JSX.Element {
  const { apiId, subscriptionType } = useParams();

  const { data: subscribers, isValidating: subscribersLoading } = useSWR<
    SubscriberWithSubscriptionsDto[]
  >(`/subscriptions/${subscriptionType}/${apiId}`);

  const { data: api, isValidating: apiLoading } = useSWR<ApiWithOwnerDto>(
    apiId ? `/api/${apiId}` : null,
  );

  const isLoading = subscribersLoading || apiLoading;

  const simplifiedSubscribers = subscribers
    ? subscribers.map(({ email }) => ({ email }))
    : [];

  const capitalizedSubscriptionType = subscriptionType
    ? subscriptionType.charAt(0).toUpperCase() + subscriptionType.slice(1)
    : '';

  const apiNameWithHyphens = api?.name.replaceAll(/\s/g, '');

  if (isLoading) {
    return <LoadingPage />;
  }

  return (
    <div>
      <BackButton to="/subscribers">Subscribers</BackButton>
      <H1 top="large">{capitalizedSubscriptionType} subscribers</H1>
      <H2 bottom="medium">{api?.name}</H2>
      <DataTable
        barContent={
          <Download
            data={simplifiedSubscribers}
            title={`${capitalizedSubscriptionType}-${apiNameWithHyphens}-`}
          />
        }
        columns={SUBSCRIBER_COLUMNS}
        data={subscribers || []}
        defaultSortKey="email"
        filterBy={['email']}
      />
    </div>
  );
}

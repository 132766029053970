/* eslint-disable max-lines */
/* eslint-disable react/prop-types */
// TODO revisit this prop inspection as it should not be disabled

import {
  Accordion,
  Anchor,
  Blockquote,
  Button,
  Flex,
  H1,
  H2,
  H3,
  H4,
  H5,
  H6,
  Hr,
  Icon,
  Li,
  Link,
  Ol,
  P,
  Space,
  Table,
  Tabs,
  Ul,
} from '@dnb/eufemia';
import type { ButtonVariant } from '@dnb/eufemia/components/button';
import type { Sizes } from '@dnb/eufemia/components/flex/Item';
import {
  ErrorIcon,
  InfoIcon,
  MarketingIcon,
  WarnIcon,
} from '@dnb/eufemia/components/FormStatus';
import { lightbulb_medium as LightBulb } from '@dnb/eufemia/icons';
import { hasOwnProperty } from '@portals/shared-frontend/utils';
import type { ContainerDirective } from 'mdast-util-directive';
import React, { type JSX } from 'react';
import ReactMarkdown from 'react-markdown';
import directive from 'remark-directive';
import remarkGemoji from 'remark-gemoji';
import remarkGfm from 'remark-gfm';
import slugify from 'slugify';
import type { Parent as DirectiveLabel } from 'ts-mdast';
import { visit } from 'unist-util-visit';

import CodeSection from './CodeSection';
import rehypeInlineCodeProperty from './rehypeInlineCodeProperty';

import './index.styles.css';

import { AccordionSection, Alert, Card } from './markdown.styles';

interface MarkdownProps {
  children: string;
}

type DirectiveContent = ContainerDirective['children'];

function parseDirective(directive: ContainerDirective): {
  directiveLabel?: DirectiveLabel;
  contentNodes: DirectiveContent;
} {
  const hasDirectiveLabel = Boolean(
    directive.children?.[0]?.data &&
      hasOwnProperty(directive.children?.[0]?.data, 'directiveLabel') &&
      directive.children?.[0]?.data?.directiveLabel === true,
  );

  if (hasDirectiveLabel) {
    const [directiveLabel, ...contentNodes] = directive.children;
    return { directiveLabel: directiveLabel as DirectiveLabel, contentNodes };
  }
  return { directiveLabel: undefined, contentNodes: directive.children };
}

function getTitle(directiveLabel: DirectiveLabel): string | undefined {
  const isTextOnlyTitle =
    directiveLabel?.children?.length === 1 &&
    directiveLabel?.children?.[0]?.type === 'text';

  return isTextOnlyTitle
    ? // @ts-expect-error: todo type
      (directiveLabel?.children?.[0].value as string)
    : undefined;
}

function reactMarkdownRemarkDirective() {
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  return (tree: any): void => {
    visit(
      tree,
      ['textDirective', 'leafDirective', 'containerDirective'],
      // eslint-disable-next-line  @typescript-eslint/no-explicit-any
      (node: any) => {
        if (node.type === 'containerDirective') {
          const directive = node as ContainerDirective;
          const { directiveLabel, contentNodes } = parseDirective(directive);
          const textOnlyTitle =
            directive.attributes && directive.attributes['title'] !== undefined
              ? directive.attributes['title']
              : directiveLabel
                ? getTitle(directiveLabel)
                : undefined;

          directive.data = {
            hName: node.name,
            hProperties: {
              ...(textOnlyTitle && { title: textOnlyTitle }),
              ...node.attributes,
              type: directive.name,
              identifier: 'footnoteDefinition',
            },
            ...node.data,
          };
          directive.children = contentNodes;

          if (directiveLabel && !textOnlyTitle) {
            const complexTitleNode = {
              type: 'mdxAdmonitionTitle',
              data: {
                type: 'mdxAdmonitionTitle',
                hProperties: {},
              },
              children: directiveLabel.children,
            };

            // @ts-expect-error: invented node type
            directive.children.unshift(complexTitleNode);
          }

          return directive;
        }

        node.data = {
          hName: node.name,
          hProperties: node.attributes,
          ...node.data,
        };

        return node;
      },
    );
  };
}

export default function Markdown({ children }: MarkdownProps): JSX.Element {
  return (
    <ReactMarkdown
      components={{
        hr: () => <Hr />,
        flex: (props) => (
          <Flex.Container>
            {React.Children.map(props.children, (child, i) => {
              if (!child) return null;
              const title = child.props.title;
              const data = child.props.children;
              const gridSize = ((props.grid && 12 / props.grid) ||
                12 / React.Children.count(props.children)) as Sizes;

              return (
                <Flex.Item
                  key={`flex-item-${i}`}
                  size={{
                    medium: gridSize,
                    small: 12,
                  }}
                  title={title}
                >
                  <Card type={child.props.card ?? null}>
                    {(Boolean(child.props.title && child.props.url) && (
                      <H4 bottom="x-small">
                        <Anchor
                          href={child.props.url}
                          icon="arrow_right"
                          iconPosition="right"
                        >
                          {child.props.title}
                        </Anchor>
                      </H4>
                    )) ||
                      (child.props.title && <H4>{child.props.title}</H4>)}
                    {data}
                  </Card>
                </Flex.Item>
              );
            })}
          </Flex.Container>
        ),
        button: (props) => (
          // @ts-expect-error button props and Eufemia Button props are a bit different
          <Button
            {...props}
            // @ts-expect-error url is used in our Guide, so keeping it for backwards compatibility.
            {...(Object.hasOwn(props, 'url') && { href: props['url'] })}
            // type is used in our Guide as Eufemia button's variant, so keeping it for backwards compatibility.
            {...(props.type && { variant: props.type as ButtonVariant })}
          >
            {props.children}
          </Button>
        ),
        br: () => <br />,
        info: (props) => (
          <Alert className="info">
            <Icon icon={LightBulb} />
            <div className="text">
              {props.title && (
                <P medium size="small">
                  {props.title}
                </P>
              )}
              {props.children}
            </div>
          </Alert>
        ),
        note: (props) => (
          <Alert className="note">
            <MarketingIcon />
            <div className="text">
              {props.title && (
                <P medium size="small">
                  {props.title}
                </P>
              )}
              {props.children}
            </div>
          </Alert>
        ),
        tip: (props) => (
          <Alert className="tip">
            <InfoIcon />
            <div className="text">
              {props.title && (
                <P medium size="small">
                  {props.title}
                </P>
              )}
              {props.children}
            </div>
          </Alert>
        ),
        warning: (props) => (
          <Alert className="warning">
            <WarnIcon />
            <div className="text">
              {props.title && (
                <P medium size="small">
                  {props.title}
                </P>
              )}
              {props.children}
            </div>
          </Alert>
        ),
        danger: (props) => (
          <Alert className="danger">
            <ErrorIcon />
            <div className="text">
              {props.title && (
                <P medium size="small">
                  {props.title}
                </P>
              )}
              {props.children}
            </div>
          </Alert>
        ),
        h1: (props) => (
          <H1
            bottom="medium"
            id={
              'heading/' +
              slugify(props?.children?.toString().toLowerCase() ?? '')
            }
          >
            {props.children}
          </H1>
        ),
        h2: (props) => (
          <H2
            bottom="small"
            id={
              'heading/' +
              slugify(props?.children?.toString().toLowerCase() ?? '')
            }
            top="medium"
          >
            {props.children}
          </H2>
        ),
        h3: (props) => (
          <H3
            bottom="small"
            id={
              'heading/' +
              slugify(props?.children?.toString().toLowerCase() ?? '')
            }
            top="medium"
          >
            {props.children}
          </H3>
        ),
        h4: (props) => (
          <H4
            bottom="small"
            id={
              'heading/' +
              slugify(props?.children?.toString().toLowerCase() ?? '')
            }
            top="medium"
          >
            {props.children}
          </H4>
        ),
        h5: (props) => (
          <H5
            bottom="medium"
            id={
              'heading/' +
              slugify(props?.children?.toString().toLowerCase() ?? '')
            }
          >
            {props.children}
          </H5>
        ),
        h6: (props) => (
          <H6
            bottom="medium"
            id={
              'heading/' +
              slugify(props?.children?.toString().toLowerCase() ?? '')
            }
          >
            {props.children}
          </H6>
        ),
        ul: (props) => (
          <Ul bottom="medium" top="medium">
            {props.children}
          </Ul>
        ),
        ol: (props) => (
          <Ol bottom="medium" top="medium">
            {props.children}
          </Ol>
        ),
        li: (props) => (
          <Li bottom="medium" top="medium">
            {props.children}
          </Li>
        ),
        accordion: (props) => {
          return (
            <AccordionSection className={props.contrast ? 'contrast' : ''}>
              <Accordion bottom="medium" expanded={props.expanded} top="medium">
                <Accordion.Header>{props.title}</Accordion.Header>
                <Accordion.Content>{props.children}</Accordion.Content>
              </Accordion>
            </AccordionSection>
          );
        },
        space: (props) => <Space top={props.size ?? 'small'} />,
        tabs: (props) => {
          const tabsData = React.Children.map(props.children, (child, i) => {
            if (!child) return null;
            return {
              key: i.toString(), // Must be string, otherwise content is not rendered.
              title: child.props.title,
              content: child.props.children,
            };
          });
          return <Tabs content_spacing="x-small" data={tabsData} prerender />;
        },
        blockquote: (props) => (
          <Blockquote bottom="medium" top="medium">
            {props.children}
          </Blockquote>
        ),
        table: (props) => (
          // @ts-expect-error border prop is defined as boolean in Eufemia, but as number in html
          <Table bottom="medium" top="medium" {...props}>
            {props.children}
          </Table>
        ),
        a: (props) => <Link {...props}>{props.children}</Link>,
        // @ts-expect-error there is one additional property coming from rehypeInlineCodeProperty
        code: (props) => <CodeSection {...props} />,
        p: (props) => (
          <P bottom="small" {...props}>
            {props.children}
          </P>
        ),
      }}
      rehypePlugins={[rehypeInlineCodeProperty]}
      remarkPlugins={[
        directive,
        remarkGfm,
        reactMarkdownRemarkDirective,
        remarkGemoji,
      ]}
    >
      {children}
    </ReactMarkdown>
  );
}

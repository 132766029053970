/* eslint-disable max-lines */
import { Button, Dialog, Div, Drawer, Hr, Link, P, Tag } from '@dnb/eufemia';
import {
  filter as FilterIcon,
  graph_increase as GraphIncreaseIcon,
} from '@dnb/eufemia/icons';
import type { ApiWithOwnerDto } from '@portals/shared/admin/ApiDto';
import type { TeamDto } from '@portals/shared/admin/TeamDto';
import { keyBy } from '@portals/shared-frontend/utils';
import { type JSX, useCallback, useMemo, useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import useSWR from 'swr';

import DataTable, { Column } from '@/components/DataTable';
import LoadingPage from '@/components/LoadingPage';
import { humanDate } from '@/utils';

import ApiFilters, { type Filters, type Key } from './ApiFilters';
import type { ApiRow, ApiStatisticsDetailLevel, StageCount } from './schema';

const COLUMNS: Column<ApiRow>[] = [
  {
    header: 'Name',
    attribute: 'name',
    render: ({ name, id }) => (
      <Link element={RouterLink} to={`${id}`}>
        {name}
      </Link>
    ),
  },
  { header: 'Type', attribute: 'type' },
  { header: 'Slug', attribute: 'slug' },
  { header: 'Stage', attribute: 'stage' },
  { header: 'Classification', attribute: 'classification' },
  { header: 'Public', attribute: 'isPublic' },
  { header: 'Exposure', attribute: 'exposure' },
  {
    header: 'Created',
    attribute: 'createdAt',
    render: ({ createdAt }) => humanDate(createdAt),
  },
  {
    header: 'Tags',
    attribute: 'tags',
    render: (row) => (
      <Tag.Group label="API tags">
        {row.tags.map((tag) => (
          <Tag key={tag}>{tag}</Tag>
        ))}
      </Tag.Group>
    ),
  },
  { header: 'Owner', attribute: 'owner' },
];

const boolToText = (value: boolean) => (value ? 'yes' : 'no');

type ApiListProps = {
  apis: ApiWithOwnerDto[];
  showFilters: boolean;
};

export default function ApiListTable({
  apis,

  showFilters,
}: ApiListProps): JSX.Element {
  const navigate = useNavigate();
  const [filter, setFilter] = useState<Filters>({
    attachableLiveMode: 'all',
    attachableTestMode: 'all',
    publicApi: 'all',
    servicenowIntegration: 'all',
    stage: [],
    classification: [],
    accessType: [],
  });

  const { data: teams, isValidating: teamsLoading } =
    useSWR<TeamDto[]>('/teams');
  const { data: organizations, isValidating: organizationsLoading } =
    useSWR<TeamDto[]>('/organizations');

  const isLoading = teamsLoading || organizationsLoading;

  const [openApiCountStatsDialog, setOpenApiCountStatsDialog] = useState(false);

  const apisCountStats = useMemo(() => {
    if (!apis) {
      return null;
    }

    const today = apis.filter((api) => {
      const today = humanDate(new Date());
      const apiCreated = humanDate(new Date(api.createdAt));
      return today === apiCreated;
    }).length;

    const thisMonth = apis.filter((api) => {
      const currentMonth = humanDate(new Date()).slice(3, 10);
      const apiCreatedMonth = humanDate(new Date(api.createdAt)).slice(3, 10);
      return currentMonth === apiCreatedMonth;
    }).length;

    const dnbOrganization = organizations?.find((org) => org.name === 'DNB');
    const stageCount = apis.reduce<StageCount>(
      (result, api) => {
        switch (api.stage) {
          case 'launched': {
            result.launched++;
            return result;
          }
          case 'upcoming': {
            result.upcoming++;
            return result;
          }
          case 'lab': {
            result.lab++;
            return result;
          }
          case 'deprecated': {
            result.deprecated++;
            return result;
          }
          case 'unpublished': {
            result.unpublished++;
            return result;
          }
          case 'review': {
            result.review++;
            return result;
          }
        }
      },
      {
        launched: 0,
        upcoming: 0,
        lab: 0,
        deprecated: 0,
        unpublished: 0,
        review: 0,
      },
    );

    const details = apis.reduce<ApiStatisticsDetailLevel>(
      (result, api) => {
        if (api.isPublic) {
          result['public'] += 1;
        }

        if (
          api.organizations.length === 1 &&
          api.organizations[0] === dnbOrganization?.id
        ) {
          result.internal++;
        }

        if (api.classification === 'partner') {
          result.partner++;
        }

        if (
          api.teams.length === 0 &&
          api.organizations.length === 0 &&
          !api.isPublic
        ) {
          result.unexposed++;
        }
        return result;
      },
      {
        public: 0,
        internal: 0,
        partner: 0,
        unexposed: 0,
      },
    );

    return {
      today,
      thisMonth,
      total: apis.length,
      details,
      stages: stageCount,
    };
  }, [apis, organizations]);

  const filteredApis = useMemo(() => {
    if (!apis) {
      return [];
    }
    return apis
      .filter(({ isPublic }) =>
        filter.publicApi === 'all'
          ? true
          : boolToText(isPublic) === filter.publicApi,
      )
      .filter(({ availableInLiveMode }) =>
        filter.attachableLiveMode === 'all'
          ? true
          : boolToText(availableInLiveMode) === filter.attachableLiveMode,
      )
      .filter(({ attachable }) =>
        filter.attachableTestMode === 'all'
          ? true
          : boolToText(attachable) === filter.attachableTestMode,
      )
      .filter(({ stage }) =>
        filter.stage.length === 0 ? true : filter.stage.includes(stage),
      )
      .filter(({ classification }) =>
        filter.classification.length === 0
          ? true
          : filter.classification.includes(classification),
      )
      .filter(({ accessTypes }) =>
        filter.accessType.length === 0
          ? true
          : accessTypes &&
            accessTypes.some((item) => filter.accessType.includes(item)),
      )
      .filter(({ enableServiceNowRequest }) =>
        filter.servicenowIntegration === 'all'
          ? true
          : filter.servicenowIntegration == 'yes'
            ? enableServiceNowRequest
            : !enableServiceNowRequest,
      );
  }, [apis, filter]);

  const rows = useMemo(() => {
    if (!teams || !organizations || !apis) {
      return [];
    }

    const teamById = keyBy(teams, 'id');
    const organizationsById = keyBy(organizations, 'id');

    return filteredApis.map<ApiRow>(
      ({
        id,
        name,
        slug,
        stage,
        classification,
        isPublic,
        tags,
        teams,
        organizations,
        createdAt,
        owner,
        type,
      }) => ({
        id,
        name,
        slug,
        stage,
        exposure: organizations
          .map((id) => organizationsById[id].name)
          .concat(teams.map((id) => teamById[id].name))
          .join(', '),
        createdAt: new Date(createdAt),
        classification,
        isPublic: isPublic ? 'Yes' : 'No',
        tags,
        owner: owner ?? 'N/A',
        type: type,
      }),
    );
  }, [apis, filteredApis, organizations, teams]);

  const onFilterChange = useCallback(
    (newValue: string | string[], key: Key) => {
      setFilter((oldFilter) => ({
        ...oldFilter,
        [`${key}`]: newValue,
      }));
    },
    [],
  );

  if (isLoading) {
    return <LoadingPage />;
  }

  return (
    <DataTable
      barContent={
        showFilters ? (
          <div>
            <Button
              element={RouterLink}
              icon="add"
              icon_position="left"
              right="small"
              to="/apis/new"
              variant="secondary"
            >
              Add API
            </Button>

            <Dialog
              onClose={() => setOpenApiCountStatsDialog(false)}
              openState={openApiCountStatsDialog}
              title="API statistics"
              trigger={() => (
                <Button
                  icon={GraphIncreaseIcon}
                  icon_position="left"
                  on_click={() => setOpenApiCountStatsDialog(true)}
                  variant="secondary"
                >
                  API statistics
                </Button>
              )}
            >
              {apisCountStats ? (
                <>
                  <P top="large">
                    Total APIs: <b>{apisCountStats.total}</b>
                  </P>
                  <P>
                    New APIs this month: <b>{apisCountStats.thisMonth}</b>
                  </P>
                  <P>
                    New APIs today: <b>{apisCountStats.today}</b>
                  </P>
                  <Hr bottom="small" top="small" />
                  <P>
                    Public APIs: <b>{apisCountStats.details.public}</b>
                  </P>
                  <P>
                    Internal APIs: <b>{apisCountStats.details.internal}</b>
                  </P>
                  <P>
                    Partner APIs: <b>{apisCountStats.details.partner}</b>
                  </P>
                  <P>
                    Unexposed APIs: <b>{apisCountStats.details.unexposed}</b>
                  </P>
                  <Hr bottom="small" top="small" />
                  <P>
                    Launched APIs: <b>{apisCountStats.stages.launched}</b>
                  </P>
                  <P>
                    Upcoming APIs: <b>{apisCountStats.stages.upcoming}</b>
                  </P>
                  <P>
                    Lab APIs: <b>{apisCountStats.stages.lab}</b>
                  </P>
                  <P>
                    API Contracts: <b>{apisCountStats.stages.unpublished}</b>
                  </P>
                  <P>
                    Deprecated APIs: <b>{apisCountStats.stages.deprecated}</b>
                  </P>
                </>
              ) : (
                <P top="large">No stats provided</P>
              )}
            </Dialog>
            <Drawer
              left="small"
              title="Filters"
              triggerAttributes={{
                text: 'Filters',
                variant: 'secondary',
                icon: FilterIcon,
              }}
            >
              <ApiFilters filter={filter} onFilterChange={onFilterChange} />
            </Drawer>
          </div>
        ) : (
          <Div />
        )
      }
      columns={COLUMNS}
      data={rows}
      defaultSortKey="name"
      filterBy={['name', 'slug', 'tags', 'owner']}
      onEdit={({ id }) => navigate(id)}
    />
  );
}

import {
  Button,
  Checkbox,
  Dd,
  Dl,
  Dt,
  FormRow,
  FormSet,
  FormStatus,
  H2,
  P,
  Section,
  Textarea,
} from '@dnb/eufemia';
import {
  type ScopeRequestInputDto,
  ScopeRequestType,
  type ScopeRequestWithApiAndOwnerDto,
} from '@portals/shared/admin/ScopeRequestDto';
import { useEufemiaForm } from '@portals/shared-frontend/hooks';
import React, { type JSX, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { z } from 'zod';

const ScopeCreationForm = z.object({
  comment: z.string().nullish(),
  checklist: z.object({
    prefixIsMatching: z.boolean(),
    notAssociatedWithOtherApis: z.boolean(),
  }),
});

const ScopeDeletionForm = z.object({
  comment: z.string().nullish(),
  checklist: z.object({
    apiVersionDeprecated: z.boolean(),
    ciamApproval: z.boolean(),
  }),
});

interface DecisionFormProps {
  scopeRequest: ScopeRequestWithApiAndOwnerDto;
  onApprove: (data: ScopeRequestInputDto) => void;
  onDeny: (data: ScopeRequestInputDto) => void;
  reviewChecklistTitleMap: Record<string, string>;
}

const scopeRequestCreationDefaults = {
  checklist: {
    prefixIsMatching: false,
    notAssociatedWithOtherApis: false,
  },
  comment: null,
};
const scopeRequestDeletionDefaults = {
  checklist: {
    apiVersionDeprecated: false,
    ciamApproval: false,
  },
  comment: null,
};
export default function DecisionForm({
  scopeRequest,
  onApprove,
  onDeny,
  reviewChecklistTitleMap,
}: DecisionFormProps): JSX.Element {
  const {
    controller: { getValue, values, formError, setValue },
    register,
    handleSubmit,
  } = useEufemiaForm(
    scopeRequest.requestType == ScopeRequestType.CREATE
      ? ScopeCreationForm
      : ScopeDeletionForm,
    scopeRequest.requestType == ScopeRequestType.CREATE
      ? scopeRequestCreationDefaults
      : scopeRequestDeletionDefaults,
  );

  useEffect(() => {
    if (scopeRequest.requestType == ScopeRequestType.CREATE) {
      setValue(
        'checklist.notAssociatedWithOtherApis',
        !scopeRequest.isInCiam?.test || !scopeRequest.isInCiam?.prod,
      );
    }
    const hasMatchingPrefix = scopeRequest.scopePrefixes.some((prefix) =>
      scopeRequest.scopeName.startsWith(prefix),
    );
    if (hasMatchingPrefix) {
      setValue('checklist.prefixIsMatching', true);
    }
  }, [
    scopeRequest.isInCiam?.prod,
    scopeRequest.isInCiam?.test,
    scopeRequest.requestType,
    scopeRequest.scopeName,
    scopeRequest.scopePrefixes,
    setValue,
  ]);

  const isApproveButtonDisabled =
    scopeRequest.requestType == ScopeRequestType.CREATE
      ? !(
          getValue('checklist.notAssociatedWithOtherApis') &&
          getValue('checklist.prefixIsMatching')
        )
      : !(
          getValue('checklist.apiVersionDeprecated') &&
          getValue('checklist.ciamApproval')
        );

  const skipScopeCreationInCiamProd =
    scopeRequest.requestType == ScopeRequestType.CREATE &&
    scopeRequest.isInCiam?.prod;

  const skipScopeCreationInCiamTest =
    scopeRequest.requestType == ScopeRequestType.CREATE &&
    scopeRequest.isInCiam?.test;

  const getApprovalText = () => {
    if (skipScopeCreationInCiamTest && skipScopeCreationInCiamProd) {
      return (
        <>
          This scope already exists in <b>CIAM (UAT and Production)</b>.
          Approving will register the scope in the Developer Portal and update
          the metadata in CIAM.
        </>
      );
    } else if (skipScopeCreationInCiamTest) {
      return (
        <>
          This scope already exists in <b>CIAM UAT</b>. Approving will register
          the scope in <b>CIAM Production</b> and in the Developer Portal, as
          well as updating metadata in CIAM UAT.
        </>
      );
    } else if (skipScopeCreationInCiamProd) {
      return (
        <>
          Clicking Approve will update the scope metadata in{' '}
          <b>CIAM Production</b>, and register the scope in <b>CIAM UAT</b> and
          in the Developer Portal.
        </>
      );
    }
    return null;
  };

  return (
    <>
      {scopeRequest?.reviewStatus === 'PENDING' && (
        <Section spacing="x-large">
          <H2>Decision</H2>
          <FormSet
            on_submit={handleSubmit((data) =>
              onApprove({
                skipScopeCreationInCiamProd,
                skipScopeCreationInCiamTest,
                comment: data.comment,
                checklist: JSON.stringify(data.checklist),
              }),
            )}
            vertical
          >
            <FormRow bottom="large" top="large">
              {scopeRequest.requestType == ScopeRequestType.CREATE ? (
                <>
                  <Checkbox
                    label={reviewChecklistTitleMap['prefixIsMatching']}
                    {...register.checkbox('checklist.prefixIsMatching')}
                  />
                  <Checkbox
                    label={
                      reviewChecklistTitleMap['notAssociatedWithOtherApis']
                    }
                    top="medium"
                    {...register.checkbox(
                      'checklist.notAssociatedWithOtherApis',
                    )}
                  />
                  {scopeRequest.existingScope && (
                    <FormStatus state="warn" top="medium">
                      <P>
                        The scope
                        <span className="dnb-typo-bold">
                          {' '}
                          {scopeRequest.existingScope?.name}{' '}
                        </span>
                        is already associated with
                        {scopeRequest.existingScope?.apiId == scopeRequest.apiId
                          ? ' this api.'
                          : ' another api.'}{' '}
                        Hence, the scope creation request cannot be approved.
                      </P>

                      <Button
                        element={RouterLink}
                        icon="chevron-right"
                        to={`/apis/${scopeRequest.existingScope?.apiId}`}
                        top="small"
                        variant="secondary"
                      >
                        Show API
                      </Button>
                    </FormStatus>
                  )}
                </>
              ) : (
                <>
                  <Checkbox
                    label={reviewChecklistTitleMap['apiVersionDeprecated']}
                    {...register.checkbox('checklist.apiVersionDeprecated')}
                  />
                  <Checkbox
                    label={reviewChecklistTitleMap['ciamApproval']}
                    top="medium"
                    {...register.checkbox('checklist.ciamApproval')}
                  />
                </>
              )}
            </FormRow>
            <FormRow top="medium">
              <Textarea
                label="Comment"
                rows={4}
                stretch
                {...register.textarea('comment')}
              />
            </FormRow>

            {(skipScopeCreationInCiamTest || skipScopeCreationInCiamProd) && (
              <FormRow top="x-large">
                <FormStatus state="warning" stretch text={getApprovalText()} />
              </FormRow>
            )}
            <FormRow
              top={
                skipScopeCreationInCiamTest || skipScopeCreationInCiamProd
                  ? 'medium'
                  : 'x-large'
              }
              vertical={false}
            >
              <Button
                disabled={isApproveButtonDisabled}
                right="small"
                type="submit"
              >
                Approve
              </Button>
              <Button
                onClick={() =>
                  onDeny({
                    comment: values.comment,
                    checklist: JSON.stringify(values.checklist),
                  })
                }
                variant="secondary"
              >
                Deny
              </Button>
            </FormRow>
            {formError && (
              <FormRow>
                <FormStatus text={formError} />
              </FormRow>
            )}
          </FormSet>
        </Section>
      )}

      {(scopeRequest?.reviewStatus === 'DENIED' ||
        scopeRequest?.reviewStatus === 'APPROVED') && (
        <Section spacing="x-large" style_type="white">
          <H2>Decision</H2>
          <Dl top="large">
            <Dt>Reviewer</Dt>
            <Dd>{scopeRequest.reviewedBy}</Dd>
            {scopeRequest.reviewChecklist &&
              Object.keys(scopeRequest.reviewChecklist).map((key) => (
                <React.Fragment key={key}>
                  <Dt>{reviewChecklistTitleMap[key]}</Dt>
                  <Dd>{scopeRequest.reviewChecklist?.[key] ? 'YES' : 'NO'}</Dd>
                </React.Fragment>
              ))}

            <Dt>Final decision</Dt>
            <Dd>{scopeRequest.reviewStatus}</Dd>
            <Dt>Final decision comment</Dt>
            <Dd>{scopeRequest.reviewComment}</Dd>
          </Dl>
        </Section>
      )}
    </>
  );
}

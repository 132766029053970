import { H1, Tabs } from '@dnb/eufemia';
import type { ApiDto } from '@portals/shared/admin/ApiDto';
import type { SubscriberWithSubscriptionsDto } from '@portals/shared/admin/SubscriptionDto';
import { type JSX, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useSWR from 'swr';

import BackButton from '@/components/BackButton';
import DataTable, { type Column } from '@/components/DataTable';
import LoadingPage from '@/components/LoadingPage';
import { Download } from '@/pages/subscribers/DownloadEmails';
import ShowApiSubscriberEmails from '@/pages/subscribers/ShowApiSubscriberEmails';

interface ApiSubscriptionsRow {
  id: string;
  name: string;
  enableStatus: string;
  subscribers: number;
}

const API_COLUMNS: Column<ApiSubscriptionsRow>[] = [
  { header: 'API', attribute: 'name' },
  { header: 'Subscribers', attribute: 'subscribers' },
  { header: 'Status reporting enabled', attribute: 'enableStatus' },
];

export interface SubscriberRow {
  email: string;
}

export const SUBSCRIBER_COLUMNS: Column<SubscriberRow>[] = [
  { header: 'Email', attribute: 'email' },
];

export default function SubscriberList(): JSX.Element {
  const navigate = useNavigate();
  const [activeList] = useState<SubscriberRow[] | undefined>(undefined);

  const { data: apis, isValidating: apisLoading } = useSWR<ApiDto[]>('/api');
  const { data: subscribers, isValidating: subscriptionsLoading } =
    useSWR<SubscriberWithSubscriptionsDto[]>('/subscriptions');

  const isLoading = apisLoading || subscriptionsLoading;

  const { releaseSubscribers, statusSubscribers, postSubscribers } =
    useMemo(() => {
      const releaseSubscribers = new Map<
        string,
        SubscriberWithSubscriptionsDto[]
      >();
      const statusSubscribers = new Map<
        string,
        SubscriberWithSubscriptionsDto[]
      >();
      const postSubscribers: SubscriberWithSubscriptionsDto[] = [];

      if (subscribers) {
        for (const subscriber of subscribers) {
          for (const id of subscriber.releases) {
            if (releaseSubscribers.has(id)) {
              releaseSubscribers.get(id)?.push(subscriber);
            } else {
              releaseSubscribers.set(id, [subscriber]);
            }
          }

          for (const id of subscriber.status) {
            if (statusSubscribers.get(id)) {
              statusSubscribers.get(id)?.push(subscriber);
            } else {
              statusSubscribers.set(id, [subscriber]);
            }
          }

          if (subscriber.posts) {
            postSubscribers.push(subscriber);
          }
        }
      }

      return { releaseSubscribers, statusSubscribers, postSubscribers };
    }, [subscribers]);

  const simplifiedPostSubscribers = postSubscribers.map((subscriber) => ({
    email: subscriber.email,
  }));

  const releasesRows =
    apis?.map<ApiSubscriptionsRow>(({ id, name, enableStatus }) => ({
      id,
      name,
      enableStatus: enableStatus ? 'Yes' : 'No',
      subscribers: releaseSubscribers.get(id)?.length ?? 0,
    })) ?? [];

  const statusRows =
    apis?.map<ApiSubscriptionsRow>(({ id, name, enableStatus }) => ({
      id,
      name,
      enableStatus: enableStatus ? 'Yes' : 'No',
      subscribers: statusSubscribers.get(id)?.length ?? 0,
    })) ?? [];

  if (isLoading) {
    return <LoadingPage />;
  }

  return (
    <>
      <BackButton to="/">Home</BackButton>
      <H1 top="large">Subscribers</H1>
      <Tabs
        data={[
          {
            title: 'Status updates',
            key: 'status',
            content: (
              <DataTable
                columns={API_COLUMNS}
                data={statusRows}
                defaultSortKey="subscribers"
                filterBy={['name']}
                onShow={(row) => {
                  navigate(`status/${row.id}`);
                }}
                reverseSort
                stateKey="status"
              />
            ),
          },
          {
            title: 'Releases',
            key: 'releases',
            content: (
              <DataTable
                columns={API_COLUMNS}
                data={releasesRows}
                defaultSortKey="subscribers"
                filterBy={['name']}
                onShow={(row) => {
                  navigate(`release/${row.id}`);
                }}
                reverseSort
                stateKey="releases"
              />
            ),
          },
          {
            title: 'News',
            key: 'news',
            content: (
              <DataTable
                barContent={
                  <Download data={simplifiedPostSubscribers} title="News" />
                }
                columns={SUBSCRIBER_COLUMNS}
                data={postSubscribers}
                defaultSortKey="email"
                filterBy={['email']}
                stateKey="news"
              />
            ),
          },
        ]}
      />
      {activeList && <ShowApiSubscriberEmails />}
    </>
  );
}

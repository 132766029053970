import { boolean, object, string, z } from 'zod';

import type { ScopeAuditLogDto } from './ScopeAuditLogDto';
import type { ScopeDto } from './ScopeDto';
export const SCOPE_NAME_MATCHER = /^[\d_a-z-]+(?:\.[\d_a-z-]+)*:[\d_a-z-]+$/;
export const INVALID_SCOPE_NAME =
  'Must be a series of lowercased words (a-z) or numbers concatenated with hyphens, underscores or dots (-._) and should end with a single word after a colon (:)';

export type ReviewStatusType = 'APPROVED' | 'DENIED' | 'PENDING';
export enum ScopeRequestType {
  CREATE = 'CREATE',
  DELETE = 'DELETE',
  UPDATE = 'UPDATE',
}

export interface ScopeCreationRequestDto {
  id: string;
  completed: boolean;
  reviewChecklist: Record<string, unknown> | null;
  reviewComment: string | null;
  scopeDescription: string;
  scopeName: string;
  apiId: string;
  reviewStatus: ReviewStatusType;
  createdAt: string;
  reviewedAt: string | null;
  reviewedBy: string | null;
  updatedAt: string;
  requestType: 'CREATE' | 'UPDATE';
}

export interface ScopeDeletionRequestDto {
  id: string;
  completed: boolean;
  reviewChecklist: Record<string, unknown> | null;
  reviewComment: string | null;
  scopeId: string;
  reviewStatus: ReviewStatusType;
  createdAt: string;
  reviewedAt: string | null;
  reviewedBy: string | null;
  updatedAt: string;
  requestType: 'DELETE';
}

export type ScopeRequestDto = ScopeCreationRequestDto | ScopeDeletionRequestDto;
export interface ScopeCreationRequestWithApiAndOwnerDto
  extends Pick<
    ScopeCreationRequestDto,
    | 'id'
    | 'reviewStatus'
    | 'requestType'
    | 'createdAt'
    | 'updatedAt'
    | 'reviewChecklist'
    | 'reviewComment'
    | 'reviewedBy'
  > {
  apiId: string;
  scopeName: string;
  apiName: string;
  apiSlug: string;
  userId: string | null;
  teamId: string | null;
  teamName: string | null;
  userEmail: string | null;
  scopeDescription: string | null;
  orgranizationId: string | null;
  isInCiam?: ScopeInCiam;
  existingScope?: ScopeDto | null;
  relatedScopeAuditLog?: ScopeAuditLogDto[];
  scopePrefixes: string[];
}

export interface ScopeDeletionRequestWithApiAndOwnerDto
  extends Pick<
    ScopeDeletionRequestDto,
    | 'id'
    | 'scopeId'
    | 'reviewStatus'
    | 'requestType'
    | 'createdAt'
    | 'updatedAt'
    | 'reviewChecklist'
    | 'reviewComment'
    | 'reviewedBy'
  > {
  apiId: string;
  scopeName: string;
  apiName: string;
  apiSlug: string;
  userId: string | null;
  teamId: string | null;
  teamName: string | null;
  userEmail: string | null;
  scopeDescription: string | null;
  orgranizationId: string | null;
  isInCiam?: ScopeInCiam;
  existingScope?: ScopeDto | null;
  relatedScopeAuditLog?: ScopeAuditLogDto[];
  scopePrefixes: string[];
}

export type ScopeRequestWithApiAndOwnerDto =
  | ScopeCreationRequestWithApiAndOwnerDto
  | ScopeDeletionRequestWithApiAndOwnerDto;

export const scopeRequestInputSchema = object({
  comment: string().nullish(),
  checklist: string(),
  skipScopeCreationInCiamProd: boolean().optional(),
  skipScopeCreationInCiamTest: boolean().optional(),
});

export type ScopeRequestInputDto = z.infer<typeof scopeRequestInputSchema>;

export const scopeRequestCreationAndApprovalInputSchema = object({
  apiId: string().min(1),
  name: string().min(1),
  description: string().min(1),
});
export type ScopeRequestCreationAndApprovalInputDto = z.infer<
  typeof scopeRequestCreationAndApprovalInputSchema
>;

export type ScopeInCiam = {
  test: boolean;
  prod: boolean;
};

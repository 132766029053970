import type { FeatureFlagDto } from '@portals/shared/portal/FeatureFlagDto';
import useSWR from 'swr';

export const DEFAULT_FEATURE_FLAGS: FeatureFlagDto = {
  ALLOW_API_OWNER_APP_CHANGE: false,
  ENABLE_SCOPE_OVERVIEW: false,
  ENABLE_CLIENT_IMPORT: false,
  ENABLE_READ_ONLY_MODE: false,
  ENABLE_DOC_BETA_MODE: false,
  ENABLE_TEAMS: false,
  ENABLE_TEST_ENVIRONMENT_WARNING: false,
  ENABLE_COMMON_REPO_API: false,
  ENABLE_AUTO_REFRESH_ON_UPDATE: false,
  ENABLE_CUSTOM_PAGES_FOR_TEAMS: false,
  ENABLE_API_REVIEW_PAGE: false,
  ENABLE_EVENT_PAGE: false,
};

interface UseFeatureFlags {
  featureFlags: FeatureFlagDto;
  isLoading: boolean;
}

export default function useFeatureFlags(): UseFeatureFlags {
  const { data, isValidating } = useSWR<FeatureFlagDto>('/flags', {
    revalidateIfStale: false,
  });

  return {
    featureFlags: data ?? DEFAULT_FEATURE_FLAGS,
    isLoading: isValidating,
  };
}

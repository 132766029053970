import { v4 as uuid } from 'uuid';
import { array, boolean, literal, number, object, string, union, z } from 'zod';

import { URL_MATCHER, UUID_MATCHER } from '../common/matchers';
import { INVALID_URL, INVALID_UUID } from '../common/messages';
import {
  type ApiEnvironmentDto,
  apiEnvironmentInputSchema,
} from './ApiEnvironmentsDto';
import type { ScopeDto } from './ScopeDto';

export enum AccessTypes {
  API_KEYS = 'API_KEYS',
  O_AUTH_SYSTEM_TO_SYSTEM = 'O_AUTH_SYSTEM_TO_SYSTEM',
}

export enum SeverityClassification {
  OPEN = 'open',
  INTERNAL = 'internal',
  CONFIDENTIAL = 'confidential',
  STRICTLY_CONFIDENTIAL = 'strictly-confidential',
}

export enum ApiType {
  REST = 'REST',
  EVENT = 'EVENT',
}

export interface ApiDto {
  id: string;
  appId: string | null;
  ordering: number;
  description: string | null;
  availableInLiveMode: boolean;
  attachable: boolean;
  name: string;
  liveModeScope: string | null;
  testModeScope: string | null;
  icon: string;
  createdAt: string;
  updatedAt: string;
  stage:
    | 'launched'
    | 'upcoming'
    | 'lab'
    | 'deprecated'
    | 'unpublished'
    | 'review';
  approvalStage: 'test' | 'live' | 'public';
  organizations: string[];
  teams: string[];
  documentationAnchor: string | null;
  slug: string;
  productPageUrl: string | null;
  enableStatus: boolean;
  legacyName: string | null;
  isPublic: boolean;
  liveModeWebhookSecret: string | null;
  testModeWebhookSecret: string | null;
  liveModeWebhookUrl: string | null;
  testModeWebhookUrl: string | null;
  classification:
    | 'system'
    | 'domain'
    | 'experience'
    | 'public'
    | 'partner'
    | 'technical';
  tags: string[];
  termsOfUse: string | null;
  termsOfUseUpdatedAt: number | null;
  deprecated: boolean;
  accessTypes: Array<
    AccessTypes.API_KEYS | AccessTypes.O_AUTH_SYSTEM_TO_SYSTEM
  > | null;
  serviceNowIdentifier: string | null;
  severityClassification: SeverityClassification | null;
  piiExposed: boolean | null;
  gdprCompliant: boolean | null;
  enableServiceNowRequest: boolean;
  enableFaq: boolean;
  type: ApiType;
}

export interface ApiWithScopesDto extends ApiDto {
  scopes?: ScopeDto[];
}
export interface ApiWithOwnerDto extends ApiDto {
  owner: string | null;
  isOwner?: boolean;
  scopes?: ScopeDto[];
}

export interface ApiWithEnvironmentsDto extends ApiWithOwnerDto {
  environments: ApiEnvironmentDto[];
}

export const apiInputSchema = object({
  id: string().regex(UUID_MATCHER, INVALID_UUID).optional(),
  appId: string().regex(UUID_MATCHER, INVALID_UUID).nullish(),
  description: string().min(1).max(400).nullish(),
  icon: string().min(1).max(40),
  availableInLiveMode: boolean(),
  attachable: boolean(),
  name: string().min(1).max(120),
  slug: string().min(1).max(120),
  liveModeScope: string().nullish(),
  testModeScope: string().nullish(),
  productPageUrl: string().max(120).regex(URL_MATCHER, INVALID_URL).nullish(),
  stage: union([
    literal('launched'),
    literal('upcoming'),
    literal('lab'),
    literal('deprecated'),
    literal('unpublished'),
    literal('review'),
  ]),
  approvalStage: union([literal('test'), literal('live'), literal('public')]),
  organizations: string().min(1).array(),
  teams: string().min(1).array(),
  documentationAnchor: string().nullish(),
  enableStatus: boolean(),
  isPublic: boolean(),
  liveModeWebhookSecret: string().nullish().default(uuid),
  testModeWebhookSecret: string().nullish().default(uuid),
  liveModeWebhookUrl: string().nullish(),
  testModeWebhookUrl: string().nullish(),
  classification: union([
    literal('system'),
    literal('domain'),
    literal('experience'),
    literal('technical'),
    literal('public'),
    literal('partner'),
  ]),
  tags: string().min(1).array(),
  environments: array(apiEnvironmentInputSchema).min(1),
  ordering: number().int().optional().default(999),
  termsOfUse: string().min(1).nullish(),
  deprecated: boolean().default(false),
  scopesToDelete: string().array().optional(),
  accessTypes: string()
    .and(z.enum([AccessTypes.API_KEYS, AccessTypes.O_AUTH_SYSTEM_TO_SYSTEM]))
    .array()
    .nullable(),
  serviceNowIdentifier: string().nullable(),
  severityClassification: string()
    .and(
      z.enum([
        SeverityClassification.CONFIDENTIAL,
        SeverityClassification.INTERNAL,
        SeverityClassification.OPEN,
        SeverityClassification.STRICTLY_CONFIDENTIAL,
      ]),
    )
    .nullable(),
  piiExposed: boolean().nullable(),
  gdprCompliant: boolean().nullable(),
  enableServiceNowRequest: boolean().default(false),
  enableFaq: boolean().default(false),
  type: z.nativeEnum(ApiType).default(ApiType.REST),
});

export type ApiInputDto = z.input<typeof apiInputSchema>;

import { Button, Dialog, P } from '@dnb/eufemia';
import { trash_medium as TrashIcon } from '@dnb/eufemia/icons';
import type { ApiKeyDto } from '@portals/shared/admin/ApiKeyDto';
import { type JSX, useState } from 'react';

import Card from '@/components/Card';

import style from './index.module.css';

type ApiKey = { apiKeys: ApiKeyDto[]; onDelete: (id: string) => void };

export function ApiKeys({ apiKeys, onDelete }: ApiKey): JSX.Element {
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);

  return (
    <div>
      {apiKeys.map((apiKey) => (
        <Card
          className={style['ApiKeyCard']}
          direction="horizontal"
          key={apiKey.id}
        >
          <P>Key: {apiKey.key}</P>
          <Button
            icon={TrashIcon}
            on_click={() => setOpenConfirmationModal(true)}
            variant="signal"
          >
            Delete
          </Button>

          {openConfirmationModal && (
            <Dialog
              confirmText="Delete"
              confirmType="warning"
              declineText="Cancel"
              description="This API key will be deleted permanetly from this app."
              icon={TrashIcon}
              onClose={() => {
                setOpenConfirmationModal(false);
              }}
              onConfirm={() => {
                setOpenConfirmationModal(false);
                onDelete(apiKey.id);
              }}
              openState={openConfirmationModal}
              title="Delete API key"
              variant="confirmation"
            />
          )}
        </Card>
      ))}
    </div>
  );
}

import {
  Button,
  Div,
  H1,
  Modal,
  P,
  Section,
  Skeleton,
  Space,
} from '@dnb/eufemia';
import { close as CloseIcon } from '@dnb/eufemia/icons';
import type { JSX, PropsWithChildren, ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';

import Container from '../Container';

import style from './index.module.css';

type ModalPageProps = PropsWithChildren<{
  title?: ReactNode;
  subtitle?: ReactNode;
  description?: ReactNode;
  actualModal?: boolean;
  skeleton?: boolean;
  onClose?: () => void;
  hideCloseButton?: boolean;
  size?: 'small' | 'medium' | 'large';
}>;

function FullScreenPage({
  title,
  subtitle,
  description,
  skeleton,
  onClose,
  children,
  hideCloseButton = false,
  size = 'large',
}: ModalPageProps): JSX.Element {
  const navigate = useNavigate();

  const _onClose = () => {
    if (onClose) {
      return onClose();
    }
    navigate('..');
  };

  return (
    <Section style_type="white">
      <Div className={style['ModalPage']}>
        <Skeleton show={skeleton}>
          {(title || subtitle || !hideCloseButton) && (
            <Div className={style['ModalActionHeader']}>
              <Container centered size={size}>
                <Div className={style['ModalActionHeaderContain']}>
                  {(title || subtitle) && (
                    <header className={style['ModalActionHeaderText']}>
                      {(title || subtitle) && (
                        <>
                          <H1 bottom="small" size="x-large" top="0">
                            {title}
                          </H1>
                          <P bottom="small" top="0">
                            {subtitle}
                          </P>
                        </>
                      )}
                    </header>
                  )}
                  {!hideCloseButton && (
                    <Div className={style['ModalActionCloseBtn']}>
                      <Button
                        bottom="xx-large"
                        icon={CloseIcon}
                        icon_position="left"
                        onClick={_onClose}
                        size="large"
                        variant="tertiary"
                      >
                        Close
                      </Button>
                    </Div>
                  )}
                </Div>
              </Container>
            </Div>
          )}
          <Container centered size={size}>
            {description && (
              <header className={style['HeaderText']}>
                {description &&
                  (typeof description === 'string' ? (
                    <P className={style['Description']} top="large">
                      {description}
                    </P>
                  ) : (
                    <Space className={style['Description']} top="large">
                      {description}
                    </Space>
                  ))}
              </header>
            )}

            <Space top="large">{children}</Space>
          </Container>
        </Skeleton>
      </Div>
    </Section>
  );
}

export default function ModalPage({
  actualModal = false,
  ...props
}: ModalPageProps): JSX.Element {
  if (actualModal) {
    return (
      <Modal
        fullscreen
        hideCloseButton
        noAnimation
        openState
        preventClose
        trigger_attributes={{
          hidden: true,
        }}
      >
        <Div className={style['Wrapper']}>
          <FullScreenPage {...props} />
        </Div>
      </Modal>
    );
  }
  return <FullScreenPage {...props} />;
}

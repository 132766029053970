/* eslint-disable max-lines */
import {
  Anchor,
  Button,
  Dialog,
  Div,
  Flex,
  FormStatus,
  H1,
  Input,
  P,
  Space,
  Tabs,
} from '@dnb/eufemia';
import {
  bank as BankIcon,
  download as DownloadIcon,
  person_medium as PersonIcon,
  speedometer as SpeedOMeterIcon,
} from '@dnb/eufemia/icons';
import { Type } from '@portals/shared/admin/DashboardDto';
import type { UserWithLiveModeStatusDto } from '@portals/shared/admin/UserDto';
import { Dashboard } from '@portals/shared-frontend/components';
import { useAsync } from '@portals/shared-frontend/hooks';
import { isInternalUser } from '@portals/shared-frontend/utils';
import { ApiError } from '@portals/shared-frontend/utils/ApiError';
import { isBefore, subDays } from 'date-fns';
import { type JSX, useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useSWR from 'swr';

import { deleteUsers, notifyUsersAboutExpiration } from '@/api/user';
import BackButton from '@/components/BackButton';
import DataTable, { type Column } from '@/components/DataTable';
import LoadingModal from '@/components/LoadingModal';
import LoadingPage from '@/components/LoadingPage';
import type { Filters } from '@/pages/users/UserFilters';
import { request } from '@/request';
import { humanDatetime } from '@/utils';

import BarContent from './BarContent';
import type { Tab } from './constants';
import {
  DEFAULT_FILTERS,
  getDateXDaysBackInTime,
  tabs,
  THREE_YEARS,
  TWO_YEARS_11_MONTHS,
  type UserRow,
} from './constants';

const COLUMNS: Column<UserRow>[] = [
  {
    header: 'Name',
    attribute: 'name',
    render: ({ name, id }) => <Anchor href={`/users/${id}`}>{name}</Anchor>,
  },
  { header: 'Email', attribute: 'email' },
  {
    header: 'Last login',
    attribute: 'lastLoggedIn',
    render: ({ lastLoggedIn }) =>
      lastLoggedIn ? humanDatetime(lastLoggedIn) : '',
  },
  {
    header: 'Member since',
    attribute: 'createdAt',
    render: ({ createdAt }) => humanDatetime(createdAt),
  },
  {
    header: 'Has live access',
    attribute: 'hasLiveAccess',
    render: ({ hasLiveAccess }) => (hasLiveAccess ? 'Yes' : 'No'),
  },
];

export default function UserList(): JSX.Element {
  const [tab, setTab] = useState<Tab>('all');
  const [error, setError] = useState<string | null>(null);
  const [usersDownloaded, setUsersDownloaded] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [numberOfUsersToDelete, setNumberOfUsersToDelete] = useState(15);
  const [openSendNotificationDialog, setOpenSendNotificationDialog] =
    useState(false);
  const [filter, setFilter] = useState<Filters>(DEFAULT_FILTERS);
  const navigate = useNavigate();
  const getParams = useCallback(
    (filter: Filters) => {
      const params = new URLSearchParams();
      if (filter.excludeUserGroup && filter.excludeUserGroup?.length !== 0) {
        params.append('excludeUserGroup', filter.excludeUserGroup.join(','));
      }
      if (filter.loginFrom) {
        params.append('loginFrom', filter.loginFrom);
      }
      if (filter.registeredBefore) {
        params.append('registeredBefore', filter.registeredBefore);
      }
      if (filter.liveModeFilter) {
        params.append('liveModeFilter', filter.liveModeFilter);
      }

      if (tab === 'expired') {
        params.set('expiredDate', getDateXDaysBackInTime(THREE_YEARS));
        return `?${params.toString()}`;
      } else if (tab === 'about-to-expire') {
        params.append(
          'expiryInterval',
          `${getDateXDaysBackInTime(
            TWO_YEARS_11_MONTHS,
          )}:${getDateXDaysBackInTime(THREE_YEARS)}`,
        );
        return `?${params.toString()}`;
      }

      return params.toString().length > 0 ? '?' + params.toString() : '';
    },
    [tab],
  );

  const {
    data: users,
    isValidating: usersLoading,
    mutate: mutateUsers,
  } = useSWR<UserWithLiveModeStatusDto[]>(`/users${filter.queryString}`);

  const onApply = useCallback(
    (newFilter: Filters) => {
      newFilter.queryString = getParams(newFilter);
      setFilter(newFilter);
    },
    [getParams, setFilter],
  );

  const now = subDays(new Date(), 30);
  const isLoading = usersLoading;
  const userCountStats = useMemo(() => {
    const internalUsers = users?.filter(({ email }) => isInternalUser(email));

    const userLogins = users?.filter(({ lastLoggedIn }) => {
      return lastLoggedIn && isBefore(now, new Date(lastLoggedIn));
    });
    const usersWithLiveAccess = users?.filter(
      ({ hasLiveAccess }) => hasLiveAccess,
    );

    const externalUsers =
      users && internalUsers
        ? users?.length - internalUsers?.length
        : 'Not available';

    return {
      total: users?.length,
      internalUsers: internalUsers?.length,
      userLogins: userLogins?.length,
      externalUsers,
      usersWithLiveAccess: usersWithLiveAccess?.length,
    };
  }, [now, users]);

  const rows = useMemo(() => {
    if (!users) {
      return [];
    }

    const rows = users.map<UserRow>(
      ({
        id,
        email,
        firstName,
        lastName,
        createdAt,
        lastLoggedIn,
        hasLiveAccess,
      }) => ({
        id,
        email,
        name: `${firstName} ${lastName}`,
        createdAt: new Date(createdAt),
        lastLoggedIn: lastLoggedIn ? new Date(lastLoggedIn) : null,
        hasLiveAccess,
      }),
    );
    setNumberOfUsersToDelete(rows.length < 15 ? rows.length : 15);
    return rows;
  }, [users]);

  useEffect(() => {
    if (tab == 'expired' || tab == 'about-to-expire') {
      onApply({
        queryString: '',
        liveModeFilter: 'test',
        excludeUserGroup: ['external'],
      });
    } else {
      onApply({
        excludeUserGroup: [],
        loginFrom: '',
        registeredBefore: '',
        queryString: '',
        liveModeFilter: '',
      });
    }
  }, [onApply, tab]);

  useEffect(() => {
    if (error) {
      setTimeout(() => setError(null), 3500);
    }
  }, [error]);

  const onDeleteUsers = useAsync(
    async (userIds: string[]) => {
      try {
        await deleteUsers(userIds);
        mutateUsers();
        setOpenDeleteDialog(false);
      } catch (error) {
        if (ApiError.isApiError(error)) {
          setError(error.message);
        } else {
          setError('Something went wrong. Please try again later.');
        }
      }
    },
    [mutateUsers],
  );

  const onNotifyUsers = useAsync(async () => {
    await notifyUsersAboutExpiration(rows.map(({ id }) => id));
    setOpenSendNotificationDialog(false);
  }, [rows]);

  const onDownloadUsers = useAsync(async () => {
    if (!users) {
      return;
    }

    const params = getParams(filter);
    const result = await request<string>(`/users/download${params}`, 'get');
    const BOM = '\uFEFF';
    const blob = new Blob([BOM + result], { type: 'text/csv' });
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = 'users.csv';
    link.click();
  }, [filter, getParams, users]);

  if (isLoading) {
    return <LoadingPage />;
  }

  return (
    <div className="container" style={{ maxWidth: 1280, margin: '0 auto' }}>
      {(onDownloadUsers.waiting || onNotifyUsers.waiting) && <LoadingModal />}
      <BackButton to="/">Home</BackButton>

      <H1 top="large">Users</H1>
      <Space bottom="xx-large">
        <div>
          <Dashboard
            data={[
              {
                large: [1, 4],
                data: {
                  type: Type.info,
                  title: 'Login last month',
                  text: userCountStats.userLogins?.toString(),
                  icon: SpeedOMeterIcon,
                },
              },
              {
                large: [5, 8],
                data: {
                  type: Type.info,
                  title: 'Internal users / Total user',
                  text:
                    userCountStats.internalUsers + ' / ' + userCountStats.total,
                  icon: PersonIcon,
                },
              },
              {
                large: [9, 12],
                data: {
                  type: Type.info,
                  title: 'Users with live access',
                  text: userCountStats.usersWithLiveAccess?.toString() ?? '',
                  icon: BankIcon,
                },
              },
            ]}
          />
        </div>
      </Space>
      {error && (
        <FormStatus
          bottom="medium"
          globalStatus={{ id: 'error' }}
          text={error}
        />
      )}
      <Tabs
        bottom="small"
        data={tabs}
        on_change={({ selected_key }) => setTab(selected_key)}
        selected_key={tab}
      />

      <DataTable
        barContent={
          <BarContent
            filter={filter}
            numberOfUsers={rows.length}
            onApply={onApply}
            onDownloadUsers={onDownloadUsers.execute}
            onNotifyUsers={() => setOpenSendNotificationDialog(true)}
            openDeleteDialog={() => setOpenDeleteDialog(true)}
            tab={tab}
          />
        }
        columns={COLUMNS}
        data={rows}
        defaultSortKey="createdAt"
        filterBy={['email', 'name']}
        onShow={({ id }) => navigate(id)}
        reverseSort
      />
      <Dialog
        confirmText="Confirm"
        confirmType="warning"
        hideConfirm
        hideDecline
        openState={openDeleteDialog}
        title="Are you sure you want to delete these users?"
        triggerAttributes={{
          hidden: true,
        }}
        variant="confirmation"
      >
        {onDeleteUsers.waiting && <LoadingModal />}
        <Flex.Vertical align="center" justify="center">
          <P>One page of users contains 15 users</P>
          <Input
            label="Number of users to delete"
            label_direction="vertical"
            on_change={({ value }) => setNumberOfUsersToDelete(Number(value))}
            type="Number"
            value={numberOfUsersToDelete}
          />
          {numberOfUsersToDelete < 16 ? (
            <Div style={{ maxHeight: 400, overflowY: 'auto' }}>
              <P bold>Users that will be removed</P>
              {rows.slice(0, numberOfUsersToDelete).map((row) => (
                <P key={row.id}>{row.email}</P>
              ))}
            </Div>
          ) : (
            <P>More than 15 users will be deleted</P>
          )}
        </Flex.Vertical>

        <Button
          icon={DownloadIcon}
          icon_position="left"
          on_click={() => {
            onDownloadUsers.execute();
            setUsersDownloaded(true);
          }}
          variant="tertiary"
        >
          Download list of users
        </Button>
        <FormStatus
          state="info"
          text="You will be prompted to download a file with details about the users
        being deleted. Please make sure that you download this file."
          top="small"
        />

        <Flex.Horizontal align="center" justify="center" top="large">
          <Button
            disabled={onDeleteUsers.waiting}
            on_click={() => {
              setOpenDeleteDialog(false);
              setUsersDownloaded(false);
            }}
            variant="secondary"
          >
            Cancel
          </Button>
          <Button
            disabled={!usersDownloaded || onDeleteUsers.waiting}
            on_click={() => {
              const usersToDelete = rows
                .map(({ id }) => id)
                .slice(0, numberOfUsersToDelete);
              onDeleteUsers.execute(usersToDelete);
            }}
          >
            Confirm
          </Button>
        </Flex.Horizontal>
      </Dialog>

      <Dialog
        confirmText="Confirm"
        confirmType="warning"
        hideConfirm
        hideDecline
        openState={openSendNotificationDialog}
        title="Are you sure you want to send a notification?"
        triggerAttributes={{
          hidden: true,
        }}
        variant="confirmation"
      >
        <P>
          All users in the previous list will be notified about deletion of
          their account.
        </P>
        <Flex.Horizontal align="center" justify="center" top="large">
          <Button
            on_click={() => setOpenSendNotificationDialog(false)}
            variant="secondary"
          >
            Cancel
          </Button>
          <Button on_click={() => onNotifyUsers.execute()}>Confirm</Button>
        </Flex.Horizontal>
      </Dialog>
    </div>
  );
}

import {
  Button,
  Checkbox,
  Dropdown,
  FormRow,
  FormSet,
  H1,
  Section,
  ToggleButton,
} from '@dnb/eufemia';
import {
  type FeatureFlagDto,
  FeatureFlags,
} from '@portals/shared/admin/FeatureFlagDto';
import type { OrganizationDto } from '@portals/shared/admin/OrganizationDto';
import type { TeamDto } from '@portals/shared/admin/TeamDto';
import type { UserDto } from '@portals/shared/admin/UserDto';
import { useEufemiaForm } from '@portals/shared-frontend/hooks';
import { keyByAndMap } from '@portals/shared-frontend/utils';
import { type JSX, useCallback, useEffect, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import useSWR from 'swr';
import { boolean, string, z } from 'zod';

import { saveFeatureFlag } from '@/api/feature-flags';
import BackButton from '@/components/BackButton';
import Container from '@/components/Container';
import FixedAutocomplete from '@/components/FixedAutocomplete';
import LoadingModal from '@/components/LoadingModal';
import LoadingPage from '@/components/LoadingPage';

const featureFlagFormSchema = z.object({
  id: string().optional(),
  key: z.string().and(z.nativeEnum(FeatureFlags)),
  value: boolean(),
  type: z.string().and(z.enum(['everyone', 'email', 'team', 'organization'])),
  typeId: string().nullable(),
});

export default function FeatureFlagForm(): JSX.Element {
  const navigate = useNavigate();
  const { featureFlagId } = useParams();

  const {
    register,
    handleSubmit,
    submitting,
    controller: { getValue, setValues },
  } = useEufemiaForm(featureFlagFormSchema, {
    type: 'everyone',
    value: false,
    typeId: null,
  });

  const type = getValue('type');

  const { data: flags } = useSWR<FeatureFlagDto[]>(
    featureFlagId ? '/feature-flags' : null,
  );
  const { data: teams, isValidating: teamsLoading } = useSWR<TeamDto[]>(
    type === 'team' ? '/teams' : null,
  );
  const { data: organizations, isValidating: organizationsLoading } = useSWR<
    OrganizationDto[]
  >(type === 'organization' ? '/organizations' : null);
  const { data: users, isValidating: usersLoading } = useSWR<UserDto[]>(
    type === 'email' ? '/users' : null,
  );

  const title = flags ? 'Edit flag' : 'Add flag';

  const teamNamesById = useMemo(
    () => keyByAndMap(teams ?? [], 'id', 'name'),
    [teams],
  );

  const organizationNamesById = useMemo(
    () => keyByAndMap(organizations ?? [], 'id', 'name'),
    [organizations],
  );

  const userNamesByEmail = useMemo(
    () => keyByAndMap(users ?? [], 'email', 'email'),
    [users],
  );

  useEffect(() => {
    const flag = flags?.find(({ id }) => id === featureFlagId); // TODO 404
    if (flag) {
      setValues(flag);
    }
  }, [featureFlagId, flags, setValues]);

  const onSubmit = handleSubmit(
    useCallback(
      async (data) => {
        await saveFeatureFlag(data);
        navigate('..');
      },
      [navigate],
    ),
  );

  if (featureFlagId && !flags) {
    return <LoadingPage />;
  }

  return (
    <>
      <BackButton to="/feature-flags">Feature Flags</BackButton>

      <Container centered size="small">
        {submitting && <LoadingModal />}

        <H1 bottom={false} top="large">
          {title}
        </H1>
        <FormSet label_direction="vertical" on_submit={onSubmit}>
          <Section spacing="x-large" style_type="white">
            <FormRow>
              <Dropdown
                data={FeatureFlags}
                label="Key"
                title="Select feature flag"
                {...register.dropdown('key')}
              />
            </FormRow>
            <FormRow top="medium">
              <Checkbox label="Enabled" {...register.checkbox('value')} />
            </FormRow>
            <FormRow top="medium">
              <ToggleButton.Group
                label="Type"
                variant="radio"
                {...register.input('type')}
              >
                <ToggleButton value="everyone">Everyone</ToggleButton>
                <ToggleButton value="team">Team</ToggleButton>
                <ToggleButton value="organization">Organization</ToggleButton>
                <ToggleButton value="email">User</ToggleButton>
              </ToggleButton.Group>
            </FormRow>
            {type !== 'everyone' && (
              <FormRow top="medium">
                {type === 'team' && (
                  <FixedAutocomplete
                    data={teamNamesById}
                    label="Team"
                    skeleton={teamsLoading}
                    {...register.autocomplete('typeId')}
                  />
                )}
                {type === 'organization' && (
                  <FixedAutocomplete
                    data={organizationNamesById}
                    label="Organization"
                    skeleton={organizationsLoading}
                    {...register.autocomplete('typeId')}
                  />
                )}
                {type === 'email' && (
                  <FixedAutocomplete
                    cache_hash="users"
                    data={userNamesByEmail}
                    label="User"
                    skeleton={usersLoading}
                    {...register.autocomplete('typeId')}
                  />
                )}
              </FormRow>
            )}
          </Section>
          <Section spacing="large" style_type="divider">
            <Button
              disabled={submitting}
              icon="save"
              icon_position="left"
              type="submit"
            >
              Save
            </Button>
          </Section>
        </FormSet>
      </Container>
    </>
  );
}

import {
  Dd,
  Dl,
  Dt,
  FormStatus,
  H1,
  H2,
  P,
  Section,
  Space,
} from '@dnb/eufemia';
import type { SafeTemplateDto } from '@portals/shared/admin/TemplateDto';
import { useAsync } from '@portals/shared-frontend/hooks';
import { ApiError } from '@portals/shared-frontend/utils/ApiError';
import { type JSX, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import useSWR from 'swr';

import { deleteTemplate } from '@/api/templates';
import BackButton from '@/components/BackButton';
import Container from '@/components/Container';
import DeleteDialog from '@/components/DeleteDialog';
import LoadingPage from '@/components/LoadingPage';

export default function TemplateShow(): JSX.Element {
  const { templateId } = useParams();
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState<string>();
  const { data: template, isValidating: loadingTemplate } =
    useSWR<SafeTemplateDto>(`/templates/${templateId}`);

  const onDelete = useAsync(async () => {
    try {
      if (templateId) {
        await deleteTemplate(templateId);
        navigate('..', { replace: true });
      }
    } catch (error) {
      if (ApiError.isApiError(error)) {
        setErrorMessage(error.message);
      } else {
        throw error;
      }
    }
  }, [templateId, navigate]);

  if (loadingTemplate) {
    return <LoadingPage />;
  }

  return (
    <>
      <BackButton to="/templates">Template</BackButton>
      <Container centered size="small">
        <H1 bottom="x-large">{template?.name}</H1>
        <Section spacing="x-large">
          <H2>Template details</H2>
          <Dl top="large">
            <Dt>Created at</Dt>
            <Dd>{template?.createdAt}</Dd>
            <Dt>Updated at</Dt>
            <Dd>{template?.updatedAt}</Dd>
            <Dt>Schema</Dt>
            {template?.schema.map((schema, index) => (
              <Space bottom="small" key={`${schema.name}-${index}`}>
                <Dd>
                  <b>Name:</b> {schema.name} <b>Type:</b> {schema.type}
                </Dd>
              </Space>
            ))}
          </Dl>
        </Section>
        <Section spacing="x-large" variant="warning">
          <H2>Delete template</H2>
          <P bottom="medium" top="medium">
            This will permanently delete the template.
          </P>
          <DeleteDialog
            loading={onDelete.waiting}
            onDelete={onDelete.execute}
            text="This will permanently delete the template."
            title="Are you sure you want to delete this template?"
            triggerVariant="signal"
          />
          {errorMessage && (
            <FormStatus state="error" text={errorMessage} top="medium" />
          )}
        </Section>
      </Container>
    </>
  );
}

import { Anchor, H1, Tabs } from '@dnb/eufemia';
import {
  type AppApiEntitlementRequestResponseDto,
  ReviewStatus,
} from '@portals/shared/admin/AppApiEntitlementDto';
import { type JSX, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import useSWR from 'swr';

import BackButton from '@/components/BackButton';
import DataTable, { type Column } from '@/components/DataTable';
import LoadingPage from '@/components/LoadingPage';
import { humanDate } from '@/utils';

const COLUMNS: Column<EntitlementRequestRow>[] = [
  {
    header: '',
    attribute: 'id',
    render: ({ id }) => (
      <Anchor element={Link} to={`/entitlements/${id}`}>
        View
      </Anchor>
    ),
  },
  { header: 'App', attribute: 'app' },
  { header: 'Owner', attribute: 'owner' },
  { header: 'Organization', attribute: 'organization' },
  { header: 'Api', attribute: 'api' },
  {
    header: 'Requsted',
    attribute: 'requested',
    render: ({ requested }) => humanDate(requested),
  },
  {
    header: 'Updated',
    attribute: 'updated',
    render: ({ updated }) => humanDate(updated),
  },
];

const TABS = [
  { title: 'Approved by API provider', key: ReviewStatus.LEVEL1_APPROVED },
  { title: 'Presubmit', key: ReviewStatus.PRESUBMIT },
  { title: 'Pending', key: ReviewStatus.PENDING },
  { title: 'Approved', key: ReviewStatus.APPROVED },
  { title: 'Rejected', key: ReviewStatus.DENIED },
  { title: 'Canceled', key: ReviewStatus.CANCELED },
];

interface EntitlementRequestRow {
  id: string;
  app: string;
  owner: string;
  organization?: string;
  api: string;
  requested: string;
  updated: string;
}

export default function EntitlementRequestsList(): JSX.Element {
  const [activeTab, setActiveTab] = useState<ReviewStatus>(
    ReviewStatus.LEVEL1_APPROVED,
  );
  const { data: entitlements, isValidating: entitlementsLoading } = useSWR<
    AppApiEntitlementRequestResponseDto[]
  >('/entitlement-requests');

  const rows = useMemo(() => {
    if (!entitlements) {
      return [];
    }

    const rowsByStatus = (status: ReviewStatus) =>
      entitlements
        .filter(({ reviewStatus }) => reviewStatus === status)
        .map<EntitlementRequestRow>((entitlement) => ({
          ...entitlement,
          app: entitlement.appName,
          api: entitlement.apiName,
        })) ?? [];

    switch (activeTab) {
      case ReviewStatus.PRESUBMIT: {
        return rowsByStatus(ReviewStatus.PRESUBMIT);
      }
      case ReviewStatus.PENDING: {
        return rowsByStatus(ReviewStatus.PENDING);
      }
      case ReviewStatus.LEVEL1_APPROVED: {
        return rowsByStatus(ReviewStatus.LEVEL1_APPROVED);
      }
      case ReviewStatus.APPROVED: {
        return rowsByStatus(ReviewStatus.APPROVED);
      }
      case ReviewStatus.DENIED: {
        return rowsByStatus(ReviewStatus.DENIED);
      }
      default: {
        return rowsByStatus(ReviewStatus.CANCELED);
      }
    }
  }, [activeTab, entitlements]);

  if (entitlementsLoading) {
    return <LoadingPage />;
  }

  return (
    <>
      <BackButton to="/">Home</BackButton>

      <H1 top="large">Entitlement Requests</H1>
      <Tabs
        data={TABS}
        on_change={({ key }) => setActiveTab(key)}
        selected_key={activeTab}
      >
        <DataTable
          columns={COLUMNS}
          data={rows}
          defaultSortKey="requested"
          filterBy={['app', 'api', 'organization', 'owner']}
          reverseSort
        />
      </Tabs>
    </>
  );
}

import '@dnb/eufemia/style';

import { InteractionType } from '@azure/msal-browser';
import { MsalAuthenticationTemplate, MsalProvider } from '@azure/msal-react';
import { GlobalError } from '@dnb/eufemia';
import { Provider } from '@dnb/eufemia/shared';
import { ApiError } from '@portals/shared-frontend/utils/ApiError';
import React, { type JSX, useState } from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { type Fetcher, SWRConfig } from 'swr';

import IncidentForm from '@/pages/api-incidents/IncidentForm';
import IncidentList from '@/pages/api-incidents/IncidentList';
import ShowApiSubscriberEmails from '@/pages/subscribers/ShowApiSubscriberEmails';

import App from './App';
import { loginRequest, msalInstance } from './authConfig';
import SsoAzureLogin from './components/auth/SsoAzureLogin';
import ApiForm from './pages/apis/ApiForm';
import ApiList from './pages/apis/ApiList';
import ApiListWithoutSn from './pages/apis/ApiListWithoutSn';
import AppList from './pages/apps/AppList';
import AppShow from './pages/apps/AppShow';
import ContentFormByParent from './pages/content/ContentFormByParent';
import ContentList from './pages/content/ContentList';
import ContentListByPath from './pages/content/ContentListByPath';
import DefaultContentForm from './pages/content/DefaultContentForm';
import EntitlementRequestsList from './pages/entitlements/EntitlementRequestsList';
import EntitlementsRequestShow from './pages/entitlements/EntitlementsRequestShow';
import FeatureFlagForm from './pages/feature-flags/FeatureFlagForm';
import FeatureFlagList from './pages/feature-flags/FeatureFlagList';
import FeedbackList from './pages/feedback/FeedbackList';
import GrantList from './pages/grants/GrantList';
import GrantShow from './pages/grants/GrantShow';
import Landing from './pages/landing/Landing';
import PostForm from './pages/news/PostForm';
import PostList from './pages/news/PostList';
import OrganizationForm from './pages/organizations/OrganizationForm';
import OrganizationList from './pages/organizations/OrganizationList';
import OrganizationShow from './pages/organizations/OrganizationShow';
import ProjectForm from './pages/projects/ProjectForm';
import ProjectList from './pages/projects/ProjectList';
import ScopeAuditLogShow from './pages/scopes/ScopeAuditLogShow';
import ScopesList from './pages/scopes/ScopesList';
import ScopesShow from './pages/scopes/ScopesShow';
import SubscriberList from './pages/subscribers/SubscriberList';
import TagForm from './pages/tags/TagForm';
import TagList from './pages/tags/TagList';
import TeamList from './pages/teams/TeamList';
import TeamShow from './pages/teams/TeamShow';
import TemplateForm from './pages/templates/TemplateForm';
import TemplateList from './pages/templates/TemplateList';
import TemplateShow from './pages/templates/TemplateShow';
import UserList from './pages/users/UserList';
import UserShow from './pages/users/UserShow';
import { request } from './request';

const fetcher: Fetcher = async (url: string) => request(url, 'get');

function Site(): JSX.Element {
  const [error, setError] = useState<unknown>();

  const onError = (error: Error) => {
    setError(error);
  };

  if (error) {
    if (ApiError.isApiError(error) && error.status === 404) {
      return <GlobalError href="/" status="404" />;
    } else {
      return <GlobalError href="/" status="500" />;
    }
  }

  return (
    <MsalProvider instance={msalInstance}>
      <MsalAuthenticationTemplate
        authenticationRequest={loginRequest}
        errorComponent={() => (
          <GlobalError
            status="500"
            text="Unable to log in using Azure SSO, please make sure you have access to this application."
            title="Failed to log in"
          />
        )}
        interactionType={InteractionType.Redirect}
      >
        <SWRConfig
          value={{
            fetcher,
            revalidateOnFocus: false,
            onError,
          }}
        >
          <BrowserRouter>
            <Routes>
              <Route element={<App />} path="/">
                <Route element={<Landing />} index />
                <Route path="apis">
                  <Route element={<ApiList />} index />
                  <Route element={<ApiListWithoutSn />} path="without-sn" />

                  <Route element={<ApiForm />} path="new" />
                  <Route element={<ApiForm />} path=":apiId" />
                  <Route element={<ApiForm />} path="without-sn/:apiId" />
                </Route>
                <Route path="apps">
                  <Route element={<AppList />} index />
                  <Route element={<AppShow />} path=":appId" />
                </Route>
                <Route path="projects">
                  <Route element={<ProjectList />} index />
                  <Route element={<ProjectForm />} path="new" />
                  <Route element={<ProjectForm />} path=":projectId" />
                </Route>
                <Route path="api-incidents">
                  <Route element={<IncidentList />} index />
                  <Route element={<IncidentForm />} path="new" />
                  <Route element={<IncidentForm />} path=":incidentId" />
                </Route>
                <Route path="subscribers">
                  <Route element={<SubscriberList />} index />
                  <Route
                    element={<ShowApiSubscriberEmails />}
                    path=":subscriptionType/:apiId"
                  />
                </Route>
                <Route path="news">
                  <Route element={<PostList />} index />
                  <Route element={<PostForm />} path="new" />
                  <Route element={<PostForm />} path=":postId" />
                </Route>
                <Route path="feedback">
                  <Route element={<FeedbackList />} index />
                </Route>
                <Route path="users">
                  <Route element={<UserList />} index />
                  <Route element={<UserShow />} path=":userId" />
                </Route>
                <Route path="organizations">
                  <Route element={<OrganizationList />} index />
                  <Route element={<OrganizationForm />} path="new" />
                  <Route
                    element={<OrganizationShow />}
                    path=":organizationId"
                  />
                  <Route
                    element={<OrganizationForm />}
                    path=":organizationId/edit"
                  />
                </Route>
                <Route path="teams">
                  <Route element={<TeamList />} index />
                  <Route element={<TeamShow />} path=":teamId" />
                </Route>
                <Route path="scopes/*">
                  <Route element={<ScopesList />} path="*" />
                  <Route element={<ScopesList />} path="active/:id" />
                  <Route element={<ScopesShow />} path=":id/edit" />
                  <Route element={<ScopeAuditLogShow />} path="history/:id" />
                </Route>
                <Route path="entitlements/*">
                  <Route element={<EntitlementRequestsList />} path="*" />
                  <Route
                    element={<EntitlementsRequestShow />}
                    path=":entitlementRequestId"
                  />
                </Route>
                <Route path="api-grants">
                  <Route element={<GrantList />} index />
                  <Route element={<GrantShow />} path=":appId" />
                </Route>
                <Route path="tags">
                  <Route element={<TagList />} index />
                  <Route element={<TagForm />} path="new" />
                  <Route element={<TagForm />} path=":tagId" />
                </Route>
                <Route path="content">
                  <Route element={<ContentList />} index />
                  <Route element={<DefaultContentForm />} path="new" />
                  <Route
                    element={<DefaultContentForm />}
                    path="edit/:contentId"
                  />
                  <Route element={<ContentFormByParent />} path="edit" />
                  <Route element={<ContentListByPath />} path="*" />
                </Route>
                <Route path="templates">
                  <Route element={<TemplateList />} index />
                  <Route element={<TemplateForm />} path="new" />
                  <Route element={<TemplateShow />} path=":templateId" />
                </Route>
                <Route path="feature-flags">
                  <Route element={<FeatureFlagList />} index />
                  <Route element={<FeatureFlagForm />} path="new" />
                  <Route element={<FeatureFlagForm />} path=":featureFlagId" />
                </Route>
              </Route>
              <Route element={<SsoAzureLogin />} path="manage" />
              <Route element={<GlobalError status="404" />} path="*" />
            </Routes>
          </BrowserRouter>
        </SWRConfig>
      </MsalAuthenticationTemplate>
    </MsalProvider>
  );
}

const root = createRoot(document.getElementById('app')!);

root.render(
  <React.StrictMode>
    <Provider locale="en-GB">
      <Site />
    </Provider>
  </React.StrictMode>,
);

import {
  Anchor,
  Button,
  Drawer,
  FormRow,
  FormSet,
  H1,
  P,
  Space,
  ToggleButton,
} from '@dnb/eufemia';
import { filter as FilterIcon } from '@dnb/eufemia/icons';
import type { ApiDto } from '@portals/shared/admin/ApiDto';
import type { AppDto } from '@portals/shared/admin/AppDto';
import type { TeamDto } from '@portals/shared/admin/TeamDto';
import type { UserDto } from '@portals/shared/admin/UserDto';
import { useEufemiaForm } from '@portals/shared-frontend/hooks';
import { keyBy } from '@portals/shared-frontend/utils';
import { type JSX, useMemo, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import useSWR from 'swr';
import { array, object, string } from 'zod';

import BackButton from '@/components/BackButton';
import DataTable, { type Column } from '@/components/DataTable';
import LoadingPage from '@/components/LoadingPage';
import { humanDate } from '@/utils';

type Owner = {
  id: string;
  name: string;
  type: 'personal' | 'team';
};
type EXPOSURE_FILTER_VALUES = 'all' | 'internal' | 'external' | 'null';
type ACCESS_FILTER_VALUES =
  | 'all'
  | 'API_KEYS'
  | 'O_AUTH_SYSTEM_TO_SYSTEM'
  | 'null';

interface AppRow {
  id: string;
  name: string;
  owner: Owner;
  ownerType: string;
  updatedAt: Date;
  createdAt: Date;
}

const COLUMNS: Column<AppRow>[] = [
  {
    header: 'Name',
    attribute: 'name',
    render: ({ name, id }) => (
      <Anchor element={Link} to={`/apps/${id}`}>
        {name}
      </Anchor>
    ),
  },
  {
    header: 'Owner',
    attribute: 'owner',
    render: ({ owner }) => (
      <Anchor
        element={Link}
        to={
          owner.type === 'personal'
            ? `/users/${owner.id}`
            : `/teams/${owner.id}`
        }
      >
        {owner.name}
      </Anchor>
    ),
  },
  { header: 'Owner Type', attribute: 'ownerType' },
  {
    header: 'Updated',
    attribute: 'updatedAt',
    render: ({ updatedAt }) => humanDate(updatedAt),
  },
  {
    header: 'Created',
    attribute: 'createdAt',
    render: ({ createdAt }) => humanDate(createdAt),
  },
];

const filterSchema = object({
  apis: array(string()),
});

export default function AppList(): JSX.Element {
  const navigate = useNavigate();
  const [accessFilter, setAccessFilter] = useState<ACCESS_FILTER_VALUES>('all');
  const [exposureFilter, setExposureFilter] =
    useState<EXPOSURE_FILTER_VALUES>('all');

  const { data: apis } = useSWR<ApiDto[]>(`/api?attachable=true`);

  const [apiSlugs, setApiSlugs] = useState<string[]>([]);

  const { data: apps, isValidating: appsLoading } = useSWR<AppDto[]>(
    `/apps${apiSlugs.length > 0 ? '?attached-api=' + apiSlugs.join(',') : ''}`,
  );
  const { data: teams, isValidating: teamsLoading } =
    useSWR<TeamDto[]>('/teams');
  const { data: users, isValidating: usersLoading } =
    useSWR<UserDto[]>('/users');

  const isLoading = appsLoading || teamsLoading || usersLoading;

  const filteredApps = useMemo(() => {
    if (!apps) {
      return [];
    }
    return apps
      .filter((app) => {
        if (accessFilter == 'null' && app.accessType === null) {
          return app;
        }
        return accessFilter === 'all' ? app : accessFilter === app.accessType;
      })
      .filter((app) => {
        if (exposureFilter === 'all') {
          return app;
        }

        if (exposureFilter == 'null' && app.internal === null) {
          return app;
        }

        const internal = app.internal ? 'internal' : 'external';
        return exposureFilter === internal;
      });
  }, [accessFilter, apps, exposureFilter]);

  const rows = useMemo(() => {
    if (!teams || !users || !apps) {
      return [];
    }

    const teamById = keyBy(teams, 'id');
    const usersById = keyBy(users, 'id');

    return filteredApps.map<AppRow>(
      ({ id, name, owner, updatedAt, createdAt, hasLiveModeAccess }) => ({
        id,
        name,
        ownerType: owner.type,
        owner: {
          id: owner.id,
          type: owner.type,
          name:
            owner.type == 'personal'
              ? usersById[owner.id].email
              : teamById[owner.id].name,
        },
        createdAt: new Date(createdAt),
        updatedAt: new Date(updatedAt),
        hasLiveModeAccess,
      }),
    );
  }, [apps, filteredApps, teams, users]);

  const { handleSubmit, register } = useEufemiaForm(filterSchema, {});

  if (isLoading) {
    return <LoadingPage />;
  }

  return (
    <>
      <BackButton to="/">Home</BackButton>

      <H1 top="large">Apps</H1>

      <DataTable
        barContent={
          <div>
            <Drawer
              title="Advanced filter"
              triggerAttributes={{
                text: 'Advanced filter',
                variant: 'secondary',
                icon: FilterIcon,
              }}
            >
              <FormSet
                on_submit={handleSubmit(({ apis }) => {
                  setApiSlugs(apis);
                })}
                vertical
              >
                <FormRow top="medium">
                  <ToggleButton.Group
                    label="Attached APIs"
                    multiselect
                    values={apiSlugs}
                    variant="checkbox"
                    {...register.toggleButtonGroup('apis', {
                      multiselect: true,
                    })}
                  >
                    {apis?.map((api) => (
                      <ToggleButton key={api.id} value={api.slug}>
                        {api.name}
                      </ToggleButton>
                    ))}
                  </ToggleButton.Group>
                </FormRow>
                <FormRow top="medium">
                  <Button size="large" text="Apply" type="submit" />
                </FormRow>
              </FormSet>
            </Drawer>

            <Space top="medium">
              <P>Quick filters:</P>
              <FormRow>
                <ToggleButton.Group
                  label="Exposure"
                  label_direction="vertical"
                  on_change={({ value }) => setExposureFilter(value)}
                  right="medium"
                  top="small"
                  value={exposureFilter}
                >
                  <ToggleButton value="all">All</ToggleButton>
                  <ToggleButton value="internal">Internal</ToggleButton>
                  <ToggleButton value="external">External</ToggleButton>
                  <ToggleButton value="null">Unknown</ToggleButton>
                </ToggleButton.Group>
              </FormRow>

              <FormRow>
                <ToggleButton.Group
                  label="Access type"
                  label_direction="vertical"
                  on_change={({ value }) => setAccessFilter(value)}
                  top="small"
                  value={accessFilter}
                >
                  <ToggleButton value="all">All</ToggleButton>
                  <ToggleButton value="API_KEYS">Api Keys</ToggleButton>
                  <ToggleButton value="O_AUTH_SYSTEM_TO_SYSTEM">
                    System to System
                  </ToggleButton>
                  <ToggleButton value="null">Unknown</ToggleButton>
                </ToggleButton.Group>
              </FormRow>
            </Space>
          </div>
        }
        columns={COLUMNS}
        data={rows}
        defaultSortKey="name"
        filterBy={['name', 'owner', 'ownerType']}
        onEdit={({ id }) => navigate(id)}
      />
    </>
  );
}

import { Anchor } from '@dnb/eufemia';
import type { ScopeAuditLogDto } from '@portals/shared/admin/ScopeAuditLogDto';
import { ScopeRequestType } from '@portals/shared/admin/ScopeRequestDto';
import { type JSX, useMemo } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import DataTable, { type Column } from '@/components/DataTable';
import LoadingPage from '@/components/LoadingPage';
import { humanDate } from '@/utils';

interface ScopeAuditLogRow {
  id: string;
  event?: string;
  scopeName?: string;
  apiName: string;
  apiSlug: string;
  appName?: string;
  createdAt: string;
  reviewStatus?: string;
}

const COLUMNS: Column<ScopeAuditLogRow>[] = [
  { header: 'Event', attribute: 'event' },
  {
    header: 'Scope',
    attribute: 'scopeName',
    render: ({ scopeName, id }) => (
      <Anchor element={Link} to={`/scopes/history/${id}`}>
        {scopeName}
      </Anchor>
    ),
  },
  { header: 'API', attribute: 'apiName' },
  { header: 'API Slug', attribute: 'apiSlug' },
  { header: 'App', attribute: 'appName' },

  {
    header: 'Created',
    attribute: 'createdAt',
    render: ({ createdAt }) => humanDate(createdAt),
  },
];

const toScopeAuditLogRow = (scopeAuditLogs: ScopeAuditLogDto[]) =>
  scopeAuditLogs.map<ScopeAuditLogRow>((log) => ({
    id: log.id,
    requestType: log.metadata.scopeRequest?.requestType,
    event: log.event,
    scopeName:
      log.metadata.scopeRequest?.requestType == ScopeRequestType.CREATE
        ? log.metadata.scopeRequest.scopeName
        : log.metadata.scope?.name,
    apiName: log.metadata.api?.name || '',
    apiSlug: log.metadata.api?.slug || '',
    appName: log.metadata.app?.name,
    createdAt: log.createdAt,
    reviewStatus: log.metadata.scopeRequest?.reviewStatus,
  }));
export interface ScopeAuditLogsTableProps {
  scopeAuditLogs: ScopeAuditLogDto[];
  columns?: Array<keyof ScopeAuditLogRow>;
  loading?: boolean;
}

export default function ScopeAuditLogsTable({
  scopeAuditLogs,
  columns,
  loading = false,
}: ScopeAuditLogsTableProps): JSX.Element {
  const navigate = useNavigate();

  const rows: ScopeAuditLogRow[] = useMemo(() => {
    if (!scopeAuditLogs || scopeAuditLogs.length === 0) {
      return [];
    }
    return toScopeAuditLogRow(scopeAuditLogs);
  }, [scopeAuditLogs]);

  if (loading) {
    return <LoadingPage />;
  }

  return (
    <DataTable
      barContent={<span />} // Just a work around to keep search bar to the right side
      columns={COLUMNS.filter((col) =>
        columns ? columns.includes(col.attribute) : true,
      )}
      data={rows}
      defaultSortKey="createdAt"
      filterBy={['event', 'scopeName', 'apiName', 'appName', 'createdAt']}
      onShow={({ id }) => navigate(`/scopes/history/${id}`)}
      reverseSort
    />
  );
}

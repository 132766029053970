import { Button, Dd, Dl, Dt } from '@dnb/eufemia';
import type { JSX } from 'react';
import { Link as RouterLink } from 'react-router-dom';

interface UserSectionProps {
  userId: string;
  firstName: string;
  lastName: string;
  email: string;
  organizationName: string;
}

export function UserSection({
  userId,
  firstName,
  lastName,
  email,
  organizationName,
}: UserSectionProps): JSX.Element {
  return (
    <Dl top="large">
      <Dt>First name</Dt>
      <Dd>{firstName}</Dd>
      <Dt>Last name</Dt>
      <Dd>{lastName}</Dd>
      <Dt>Email</Dt>
      <Dd>{email}</Dd>
      <Dt>Organization</Dt>
      <Dd>{organizationName}</Dd>
      <Button
        element={RouterLink}
        icon="chevron-right"
        to={`/users/${userId}`}
        top="large"
        variant="secondary"
      >
        Show user
      </Button>
    </Dl>
  );
}

import { Button, Dd, Dl, Dt, H1, H2, P, Section } from '@dnb/eufemia';
import type { ApiWithEnvironmentsDto } from '@portals/shared/admin/ApiDto';
import type { OrganizationDto } from '@portals/shared/admin/OrganizationDto';
import type { ScopeAuditLogDto } from '@portals/shared/admin/ScopeAuditLogDto';
import type {
  ScopeRequestInputDto,
  ScopeRequestWithApiAndOwnerDto,
} from '@portals/shared/admin/ScopeRequestDto';
import { useAsync } from '@portals/shared-frontend/hooks';
import classNames from 'classnames';
import { type JSX, useCallback } from 'react';
import { Link as RouterLink, useNavigate, useParams } from 'react-router-dom';
import useSWR from 'swr';

import { approveScope, denyScope } from '@/api/scopes';
import BackButton from '@/components/BackButton';
import Container from '@/components/Container';
import LoadingModal from '@/components/LoadingModal';
import LoadingPage from '@/components/LoadingPage';

import DecisionForm from './components/DecisionForm';
import ScopeAuditLogsTable from './components/ScopeAuditLogsTable';
import { reviewChecklistTitleMap } from './constant';

import style from './ScopesShow.module.css';

export default function ScopesShow(): JSX.Element {
  const { id: requestId } = useParams();
  const navigate = useNavigate();
  const { data: scopeRequest, isValidating: scopeRequestLoading } =
    useSWR<ScopeRequestWithApiAndOwnerDto>(`/scope-requests/${requestId}`);

  const apiId = scopeRequest?.apiId;
  const { data: api, isValidating: apiLoading } =
    useSWR<ApiWithEnvironmentsDto>(apiId ? `/api/${apiId}` : null);

  const organizationId = scopeRequest?.orgranizationId;
  const { data: organization, isValidating: organizationLoading } =
    useSWR<OrganizationDto>(
      organizationId ? `/organizations/${organizationId}` : null,
    );

  const isLoading = scopeRequestLoading || organizationLoading || apiLoading;
  const isTeam = !!scopeRequest?.teamId;

  const showScopeAuditLog = useCallback(
    (scopeAuditLog: ScopeAuditLogDto) => {
      navigate(`/scopes/history/${scopeAuditLog.id}`);
    },
    [navigate],
  );

  const onApprove = useAsync(
    async ({
      comment,
      checklist,
      skipScopeCreationInCiamProd,
      skipScopeCreationInCiamTest,
    }: ScopeRequestInputDto) => {
      if (requestId) {
        const scopeRequest = await approveScope(requestId, {
          skipScopeCreationInCiamProd,
          skipScopeCreationInCiamTest,
          comment,
          checklist,
        });
        showScopeAuditLog(scopeRequest);
      }
    },
    [showScopeAuditLog, requestId],
  );

  const onDeny = useAsync(
    async ({ comment, checklist }: ScopeRequestInputDto) => {
      if (requestId) {
        const scopeRequest = await denyScope(requestId, {
          comment,
          checklist,
        });
        showScopeAuditLog(scopeRequest);
      }
    },
    [showScopeAuditLog, requestId],
  );

  if (isLoading) {
    return <LoadingPage />;
  }

  return (
    <>
      {(onDeny.waiting || onApprove.waiting) && <LoadingModal />}
      <BackButton to="/scopes">Home</BackButton>
      <Container centered size="small">
        <P
          className={classNames(
            style['Status'],
            style[`Status--${scopeRequest?.reviewStatus.toLocaleLowerCase()}`],
          )}
          top="x-large"
        >
          {scopeRequest?.reviewStatus.toLocaleLowerCase()}
        </P>
        <H1>Review request</H1>

        <Section spacing="x-large" top="x-large">
          <H2>API</H2>
          <Dl top="large">
            <Dt>Name</Dt>
            <Dd>{api?.name}</Dd>
            <Dt>Slug</Dt>
            <Dd>{api?.slug}</Dd>
            <Dt>Registered scopes</Dt>
            {api?.scopes?.length === 0 ? (
              <P top="small">No scopes are registered yet for this API.</P>
            ) : (
              api?.scopes?.map((scope) => {
                return (
                  <Dd key={scope.id}>
                    <b>{scope.name}</b>: {scope.description}
                  </Dd>
                );
              })
            )}
          </Dl>
          <Button
            element={RouterLink}
            icon="chevron-right"
            to={`/apis/${api?.id}`}
            top="large"
            variant="secondary"
          >
            Show API
          </Button>
        </Section>

        <Section spacing="x-large" style_type="white">
          <H2>Owner</H2>
          <Dl top="large">
            <Dt>{isTeam ? 'Team Name' : 'Email'}</Dt>
            <Dd>{isTeam ? scopeRequest.teamName : scopeRequest?.userEmail}</Dd>
            <Dt>Organization</Dt>
            <Dd>
              {organization
                ? organization.name
                : 'User is not part of any organization'}
            </Dd>
            <Dt>Registered scope prefixes</Dt>

            {scopeRequest?.scopePrefixes.length === 0 && (
              <Dd>No scope prefix defined for team</Dd>
            )}

            {scopeRequest?.scopePrefixes.map((prefix) => (
              <Dd key={prefix}>{prefix}</Dd>
            ))}
          </Dl>

          <Button
            element={RouterLink}
            icon="chevron-right"
            to={
              isTeam
                ? `/teams/${scopeRequest?.teamId}`
                : `/users/${scopeRequest?.userId}`
            }
            top="large"
            variant="secondary"
          >
            Show owner
          </Button>
        </Section>

        <Section spacing="x-large">
          <H2>Requested scope</H2>
          <Dl top="large">
            <Dt>Name</Dt>
            <Dd>{scopeRequest?.scopeName}</Dd>
            <Dt>Description</Dt>
            <Dd>{scopeRequest?.scopeDescription}</Dd>
            <Dt>Action</Dt>
            <Dd>{scopeRequest?.requestType}</Dd>
            <Dt>Already exists in CIAM production</Dt>
            <Dd>{scopeRequest?.isInCiam?.prod ? 'YES' : 'NO'}</Dd>
            <Dt>Already exists in CIAM UAT</Dt>
            <Dd>{scopeRequest?.isInCiam?.test ? 'YES' : 'NO'}</Dd>
          </Dl>
        </Section>
      </Container>
      <Section spacing="x-large" style_type="white">
        <H2>Request history</H2>
        <ScopeAuditLogsTable
          columns={['event', 'apiName', 'appName', 'createdAt']}
          scopeAuditLogs={scopeRequest?.relatedScopeAuditLog || []}
        />
      </Section>

      <Container centered size="small">
        {scopeRequest && (
          <DecisionForm
            onApprove={onApprove.execute}
            onDeny={onDeny.execute}
            reviewChecklistTitleMap={reviewChecklistTitleMap}
            scopeRequest={scopeRequest}
          />
        )}
      </Container>
    </>
  );
}

import {
  Button,
  Checkbox,
  FormRow,
  FormSet,
  FormStatus,
  H1,
  H2,
  Ingress,
  Input,
  P,
  Section,
  Tabs,
  Textarea,
  ToggleButton,
} from '@dnb/eufemia';
import type { CreatePostDto, PostDto } from '@portals/shared/admin/PostDto';
import type { TagDto } from '@portals/shared/admin/TagDto';
import Markdown from '@portals/shared-frontend/components/Markdown';
import { useAsync, useEufemiaForm } from '@portals/shared-frontend/hooks';
import { type JSX, useCallback, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import useSWR from 'swr';
import { array, boolean, object, string, z } from 'zod';

import { createPost, deletePost, sendPost, updatePost } from '@/api/posts';
import BackButton from '@/components/BackButton';
import Container from '@/components/Container';
import DeleteDialog from '@/components/DeleteDialog';
import LoadingModal from '@/components/LoadingModal';
import LoadingPage from '@/components/LoadingPage';

const postFormSchema = object({
  title: string().min(1),
  lead: string(),
  content: string().min(1),
  tags: array(string()),
  publish: boolean().optional(),
  sendEmail: boolean().optional(),
});

type PostFormData = z.output<typeof postFormSchema>;

const DEFAULT_POST: Partial<PostFormData> = {
  title: '',
  lead: '',
  content: '',
  tags: [],
  publish: false,
  sendEmail: false,
};

function dtoToForm(data: PostDto): PostFormData {
  return {
    ...data,
    publish: !!data.publishedAt,
    sendEmail: !!data.emailsSentAt,
  };
}

function formToDto(data: PostFormData): CreatePostDto {
  const { title, lead, content, tags, publish } = data;
  return {
    title,
    lead,
    content,
    tags,
    // TODO: This will cause date to be changed whenever an edit is mades
    publishedAt: publish ? Date.now() : null,
  };
}

export default function PostForm(): JSX.Element {
  const navigate = useNavigate();
  const { postId } = useParams();

  const {
    controller: { values, setValues, getValue, setValue },
    register,
    handleSubmit,
    submitting,
  } = useEufemiaForm(postFormSchema, DEFAULT_POST);

  const { data: post, isValidating: postLoading } = useSWR<PostDto>(
    postId ? `/posts/${postId}` : null,
  );
  const { data: tags, isValidating: tagsLoading } = useSWR<TagDto[]>('/tags');

  const title = post ? 'Edit post' : 'Add post';
  const publish = getValue('publish');

  useEffect(() => {
    if (!publish) {
      setValue('sendEmail', false);
    }
  }, [publish, setValue]);

  useEffect(() => {
    if (post) {
      setValues(dtoToForm(post));
    }
  }, [post, setValues]);

  const onDelete = useAsync(async () => {
    if (postId) {
      await deletePost(postId);
      navigate('/news');
    }
  }, [navigate, postId]);

  const onSubmit = handleSubmit(
    useCallback(
      async (data) => {
        const payload = formToDto(data);

        const { id, emailsSentAt } = postId
          ? await updatePost(postId, payload)
          : await createPost(payload);

        if (emailsSentAt == null && data.sendEmail) {
          await sendPost(id);
        }

        navigate('..');
      },
      [postId, navigate],
    ),
  );

  if (tagsLoading || postLoading) {
    return <LoadingPage />;
  }

  return (
    <>
      <BackButton to="/news">News</BackButton>
      <Container centered size="small">
        {submitting && <LoadingModal />}

        <H1 top="large">{title}</H1>
        <P>
          Compose news posts for the Developer Portal in markdown. View the post
          in the preview tab before publishing. Note that the post here will
          appear different than in the developer portal or in an email client.
        </P>
        <Tabs
          content_spacing={false}
          data={[
            {
              title: 'Compose',
              key: 'compose',
              content: (
                <FormSet label_direction="vertical" on_submit={onSubmit}>
                  <Section spacing="x-large" style_type="white">
                    <FormRow>
                      <Input
                        label="Title"
                        stretch
                        {...register.input('title')}
                      />
                    </FormRow>
                    <FormRow top="medium">
                      <Textarea
                        label="Lead"
                        rows={5}
                        stretch
                        {...register.textarea('lead')}
                      />
                    </FormRow>
                    <FormRow top="medium">
                      <Textarea
                        label="Content"
                        rows={15}
                        stretch
                        {...register.textarea('content')}
                      />
                    </FormRow>
                  </Section>
                  <Section spacing="x-large">
                    <H2>Tags</H2>
                    <FormRow top="medium">
                      <ToggleButton.Group
                        variant="checkbox"
                        {...register.toggleButtonGroup('tags', {
                          multiselect: true,
                        })}
                      >
                        {tags?.map((tag) => (
                          <ToggleButton
                            key={tag.id}
                            text={tag.title}
                            value={tag.title}
                          />
                        ))}
                      </ToggleButton.Group>
                    </FormRow>
                  </Section>
                  <Section spacing="x-large" style_type="white">
                    <H2>Publishing</H2>
                    <FormRow top="medium" vertical>
                      <Checkbox
                        disabled={!!post?.publishedAt}
                        label="Publish in the Developer Portal"
                        {...register.checkbox('publish')}
                      />
                      {post?.publishedAt && (
                        <FormStatus
                          state="info"
                          text="This field is disabled because the post already has been published"
                          top="x-small"
                        />
                      )}
                    </FormRow>
                    <FormRow top="medium" vertical>
                      <Checkbox
                        disabled={!publish || !!post?.emailsSentAt}
                        label="Send email to subscribers"
                        {...register.checkbox('sendEmail')}
                      />
                      {post?.emailsSentAt && (
                        <FormStatus
                          state="info"
                          text="This field is disabled because emails already have been sent"
                          top="x-small"
                        />
                      )}
                    </FormRow>
                  </Section>
                  <Section spacing="large" style_type="divider">
                    <Button
                      disabled={submitting}
                      icon="save"
                      icon_position="left"
                      right="small"
                      type="submit"
                    >
                      Save
                    </Button>
                    <DeleteDialog
                      loading={onDelete.waiting}
                      onDelete={onDelete.execute}
                      text="The post will be permanently deleted and this action cannot be undone."
                      title="Are you sure you want to delete this post?"
                    />
                  </Section>
                </FormSet>
              ),
            },
            {
              title: 'Preview',
              key: 'preview',
              content: (
                <Section spacing="large" style_type="white">
                  {values.title && <H1 top="medium">{values.title}</H1>}
                  {values.lead && <Ingress top="medium">{values.lead}</Ingress>}
                  {values.content && <Markdown>{values.content}</Markdown>}
                </Section>
              ),
            },
          ]}
          prerender
          top="large"
        />
      </Container>
    </>
  );
}

import { Button, Dialog, FormStatus, H3, P, Table, Td, Tr } from '@dnb/eufemia';
import { trash_medium as TrashIcon } from '@dnb/eufemia/icons';
import type { ApiWithScopesDto } from '@portals/shared/admin/ApiDto';
import { type JSX, useState } from 'react';

import Card from '@/components/Card';

import style from './index.module.css';
type Apis = {
  apis?: ApiWithScopesDto[];
  liveMode: boolean;
  onRevokeApiAccess: (apiId: string, liveMode: boolean) => void;
  onRevokeScopeAccess: (scopeId: string, liveMode: boolean) => void;
};
export function ConsumedApis({
  apis,
  liveMode,
  onRevokeApiAccess,
  onRevokeScopeAccess,
}: Apis): JSX.Element {
  const [openDeleteApiAccessModal, setOpenDeleteApiAccessModal] =
    useState(false);
  const [openDeleteScopeAccessModal, setOpenDeleteScopeAccessModal] =
    useState(false);

  return (
    <>
      {apis && apis?.length === 0 && (
        <P top="x-small">
          App does not consume any APIs in{' '}
          {liveMode ? 'Live Mode' : 'Test Mode'}
        </P>
      )}
      {apis?.map((api) => (
        <Card
          className={style['ConsumedApis-Card']}
          direction="vertical"
          key={api.id}
        >
          <H3>{api.name}</H3>
          <div className={style['ConsumedApis-TableContainer']}>
            {api?.scopes ? (
              <Table>
                <tbody>
                  {api?.scopes?.map((scope) => (
                    <>
                      <Tr key={scope.id}>
                        <Td>{scope.name}</Td>
                        <Td>{scope.description}</Td>
                        <Td align="right">
                          {api.scopes && api.scopes.length > 1 && (
                            <Button
                              icon={TrashIcon}
                              on_click={() =>
                                setOpenDeleteScopeAccessModal(true)
                              }
                            />
                          )}
                        </Td>
                      </Tr>
                      {openDeleteScopeAccessModal && (
                        <Dialog
                          confirmText="Delete"
                          confirmType="warning"
                          declineText="Cancel"
                          description="This will remove the access to this scope for the current application. This action cannot be undone."
                          icon={TrashIcon}
                          onClose={() => setOpenDeleteScopeAccessModal(false)}
                          onConfirm={() => {
                            setOpenDeleteScopeAccessModal(false);
                            onRevokeScopeAccess(scope.id, liveMode);
                          }}
                          onDecline={() => setOpenDeleteScopeAccessModal(false)}
                          openState={openDeleteScopeAccessModal}
                          title="Delete API key"
                          variant="confirmation"
                        />
                      )}
                    </>
                  ))}
                </tbody>
              </Table>
            ) : (
              <FormStatus
                state="info"
                text="API does not have any scopes "
                top="small"
              />
            )}
            <Button
              on_click={() => setOpenDeleteApiAccessModal(true)}
              top="small"
              variant="secondary"
            >
              Revoke access to API
            </Button>
          </div>

          <Dialog
            confirmText="Delete"
            confirmType="warning"
            declineText="Cancel"
            description={
              <>
                This will remove the access to the API and all scopes releated
                to it. This action cannot be undone. Are you sure you want to
                revoke this access?
              </>
            }
            icon={TrashIcon}
            onClose={() => setOpenDeleteApiAccessModal(false)}
            onConfirm={() => {
              setOpenDeleteApiAccessModal(false);
              onRevokeApiAccess(api.id, liveMode);
            }}
            onDecline={() => setOpenDeleteApiAccessModal(false)}
            openState={openDeleteApiAccessModal}
            title={`Remove access to ${api.name} API`}
            variant="confirmation"
          />
        </Card>
      ))}
    </>
  );
}

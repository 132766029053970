import { Root as HastRoot } from 'hast';
import { visit } from 'unist-util-visit';

/**
 * Adds 'inline' as an additional property on the code element.
 * Inline will be true if the parent tag is not <pre>, false otherwise.
 */
export default function rehypeInlineCodeProperty() {
  return function (tree: HastRoot) {
    visit(tree, 'element', function (node, _, parent) {
      if (node.tagName !== 'code') return;
      let inline = true;
      if (parent && parent.type === 'element' && parent.tagName === 'pre') {
        inline = false;
      }
      node.properties['inline'] = inline;
    });
  };
}

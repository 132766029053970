import {
  Button,
  FormRow,
  FormSet,
  H1,
  Input,
  Radio,
  Section,
  Space,
  Tabs,
} from '@dnb/eufemia';
import {
  type ContentDto,
  PUBLISHED_STATUS,
} from '@portals/shared/admin/ContentDto';
import { useEufemiaForm } from '@portals/shared-frontend/hooks';
import { type JSX, useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import slugify from 'slugify';
import useSWR from 'swr';

import { createDefaultContent } from '@/api/content';
import BackButton from '@/components/BackButton';
import Container from '@/components/Container';
import LoadingModal from '@/components/LoadingModal';
import LoadingPage from '@/components/LoadingPage';
import MarkdownEditor from '@/components/MarkdownEditor';
import MarkdownHelperTable from '@/pages/content/components/MarkdownHelperTable';
import { SharedForm } from '@/pages/content/components/SharedForm';
import Visibility from '@/pages/content/components/Visibility';

import { basicContentFormSchema, DEFAULT_FORM, TabKey, TABS } from './schemas';

import style from './DefaultContentForm.module.css';

export default function DefaultContentForm(): JSX.Element {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const parent = searchParams.get('parent');
  const [activeTab, setActiveTab] = useState<TabKey>(TabKey.info);

  const {
    controller,
    controller: { setValues, setValue },
    register,
    handleSubmit,
    submitting,
  } = useEufemiaForm(
    basicContentFormSchema,
    parent ? { ...DEFAULT_FORM, parent: parent, path: parent } : DEFAULT_FORM,
  );

  const { contentId } = useParams();

  const { data: content, isValidating: loadingContent } = useSWR<ContentDto>(
    contentId ? `/content/edit/${contentId}` : null,
  );

  useEffect(() => {
    if (contentId && !loadingContent && content) {
      setValues({
        ...content,
        sortIndex: content.sortIndex.toString(),
      });
    }
  }, [content, contentId, loadingContent, setValues]);

  useEffect(() => {
    if (!content) {
      const slug = slugify(
        controller.values?.title?.toLocaleLowerCase() ?? '',
        {
          lower: true,
          trim: true,
        },
      );
      setValue('slug', slug);
    }
  }, [controller.values.title]);

  useEffect(() => {
    const path = parent
      ? `${parent}/${controller.values.slug}`
      : `/${controller.values.slug}`;
    setValue('path', path);
  }, [controller.values.slug]);

  const onSubmit = handleSubmit(
    useCallback(
      async (data) => {
        if (!data.path.startsWith(data.parent)) {
          controller.setError('path', 'Path must start with parent path');
          return;
        }
        await createDefaultContent({
          ...data,
          id: contentId,
          sortIndex: Number(data.sortIndex),
          publishedStatus: Number(data.publishedStatus),
        });
        navigate('..');
      },
      [contentId, navigate],
    ),
  );

  if (loadingContent) {
    return <LoadingPage />;
  }

  return (
    <>
      <BackButton to="/content">Content</BackButton>

      <Container size="large" stretch>
        {submitting && <LoadingModal />}

        <H1 bottom={false} top="large">
          Add Content
        </H1>

        <Tabs
          data={TABS}
          no_border
          on_change={({ key }) => setActiveTab(key)}
          selected_key={activeTab}
          top="large"
        />

        <FormSet label_direction="vertical" on_submit={onSubmit}>
          {activeTab === TabKey.info && (
            <>
              <Space className={style['Container']}>
                <SharedForm controller={controller} />

                <FormRow top="medium">
                  <Radio.Group
                    label="Status"
                    on_change={({ value }) =>
                      setValue('publishedStatus', Number.parseInt(value))
                    }
                    value={content ? content.publishedStatus?.toString() : '0'}
                  >
                    <Radio
                      label="Unpublished"
                      value={PUBLISHED_STATUS.UNPUBLISHED.toString()}
                    />
                    <Radio
                      label="Draft"
                      value={PUBLISHED_STATUS.DRAFT.toString()}
                    />
                    <Radio
                      label="Published"
                      value={PUBLISHED_STATUS.PUBLISHED.toString()}
                    />
                  </Radio.Group>
                </FormRow>
              </Space>

              <Visibility
                controller={controller}
                selectedParentContent={null}
              />
            </>
          )}
          {activeTab === TabKey.content && (
            <>
              <Space className={style['ContentContainer']}>
                <Space className={style['ContentFormItem']}>
                  <FormRow bottom="small" left="x-small">
                    <Input
                      label="Title"
                      size={40}
                      {...register.input('title')}
                      stretch
                    />
                  </FormRow>
                  <MarkdownEditor
                    height={600}
                    onChange={(_value) => {
                      controller.setValue('blob.content', _value);
                    }}
                    value={controller.values.blob?.content}
                  />
                </Space>
              </Space>
              <MarkdownHelperTable />
            </>
          )}
          <Section spacing="large" style_type="white">
            <Button
              disabled={submitting}
              icon="save"
              icon_position="left"
              type="submit"
            >
              Save changes
            </Button>
          </Section>
        </FormSet>
      </Container>
    </>
  );
}

/* eslint-disable max-lines */
import {
  Autocomplete,
  Button,
  Checkbox,
  Dropdown,
  FormLabel,
  FormRow,
  FormSet,
  FormStatus,
  H1,
  Input,
  Lead,
  P,
  Radio,
  Section,
  Space,
  Tabs,
  Textarea,
} from '@dnb/eufemia';
import {
  type ContentDto,
  type ContentMetadataDto,
  PUBLISHED_STATUS,
} from '@portals/shared/admin/ContentDto';
import type {
  SafeTemplateDto,
  SchemaDto,
} from '@portals/shared/admin/TemplateDto';
import { useEufemiaForm } from '@portals/shared-frontend/hooks';
import { keyBy } from '@portals/shared-frontend/utils';
import { ApiError } from '@portals/shared-frontend/utils/ApiError';
import {
  type JSX,
  type ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import slugify from 'slugify';
import useSWR from 'swr';
import { z } from 'zod';

import { createContent } from '@/api/content';
import BackButton from '@/components/BackButton';
import Container from '@/components/Container';
import LoadingModal from '@/components/LoadingModal';
import LoadingPage from '@/components/LoadingPage';
import MarkdownEditor from '@/components/MarkdownEditor';
import MarkdownHelperTable from '@/pages/content/components/MarkdownHelperTable';
import { SharedForm } from '@/pages/content/components/SharedForm';
import Visibility from '@/pages/content/components/Visibility';

import {
  basicContentFormSchema,
  blobFormSchema,
  DEFAULT_FORM,
  TabKey,
  TABS,
} from './schemas';

import style from './DefaultContentForm.module.css';

export type ParentAutocomplete = {
  selected_key?: string;
  selected_value: string;
  content: ReactNode;
};
function calculatePath({ slug, path }: { slug?: string; path?: string }) {
  return path ? `${path}/${slug}` : `/${slug}`;
}
export default function ContentFormByParent(): JSX.Element {
  const [selectedTemplate, setSelectedTemplate] = useState<string>();
  const [selectedParent, setSelectedParent] =
    useState<ContentMetadataDto | null>(null);
  const [searchParams] = useSearchParams();

  const contentId = searchParams.get('contentId'); // available if edit mode
  const parentId = searchParams.get('parentId');
  const navigate = useNavigate();

  const {
    controller,
    controller: { setValues, setValue, values },
    register,
    handleSubmit,
    submitting,
  } = useEufemiaForm(basicContentFormSchema, {
    ...DEFAULT_FORM,
    parentId: parentId,
  });

  const { data: content, isValidating: loadingContent } = useSWR<ContentDto>(
    contentId && `/content/edit/${contentId}`,
  );

  const { data: allContent, isValidating: loadingAllContent } =
    useSWR<ContentMetadataDto[]>(`/content-all`);

  const { data: templates, isValidating: templatesLoading } =
    useSWR<SafeTemplateDto[]>('/templates');

  const contentById = useMemo(() => {
    if (!allContent) {
      return {};
    }
    const contentById = keyBy(allContent, 'id');
    if (parentId) {
      setSelectedParent(contentById[parentId]);
    }
    return contentById;
  }, [allContent, parentId]);

  const parentsDropdownData = useMemo<ParentAutocomplete[]>(() => {
    if (!allContent) {
      return [];
    }

    const root = {
      key: '/',
      selected_value: '/',
      content: <DropDownView path="/" title="Root" />,
      search_content: '/',
    };

    if (!content) {
      return [
        root,
        ...allContent.map(({ id, path, title }) => ({
          selected_key: id,
          key: id,
          selected_value: title,
          content: <DropDownView path={path} title={title} />,
          search_content: [title, path],
        })),
      ];
    }

    return [
      root,
      ...allContent
        .filter(
          ({ parent, id }) =>
            id !== content.id && !parent.startsWith(content.path),
        )
        .map(({ id, path, title }) => ({
          selected_key: id,
          key: id,
          selected_value: title,
          content: <DropDownView path={path} title={title} />,
          search_content: [title, path],
        })),
    ];
  }, [allContent, content]);

  const { templateDropDownData, templatesById } = useMemo(() => {
    if (!templates) {
      return {
        templatesById: {},
        templateDropDownData: [],
      };
    }
    const templatesById = keyBy(templates, 'id');
    const templateDropDownData = templates.map((template) => ({
      content: template.name,
      selected_key: template.id,
    }));
    return { templatesById, templateDropDownData };
  }, [templates]);

  const dynamicSchema = useMemo(() => {
    if (!selectedTemplate) {
      return null;
    }
    const schema = templatesById[selectedTemplate]?.schema.reduce(
      (acc: Record<string, z.ZodString | z.ZodBoolean>, current: SchemaDto) => {
        if (current.type === 'boolean') {
          acc[current.name] = z.boolean();
        } else {
          acc[current.name] = z.string();
        }
        return acc;
      },
      {},
    );
    return z.object(schema);
  }, [selectedTemplate, templatesById]);

  const {
    controller: dynamicController,
    controller: { setValues: setDynamicValues },
    register: dynamicRegister,
  } = useEufemiaForm(dynamicSchema ?? blobFormSchema, {});

  useEffect(() => {
    if (
      contentId &&
      !loadingContent &&
      content &&
      !templatesLoading &&
      templates
    ) {
      setValues({
        ...content,
        sortIndex: content.sortIndex.toString(),
        parentId: parentId,
      });
      setSelectedTemplate(content.templateId);
      setDynamicValues(content.blob);
    }
  }, [
    content,
    contentId,
    setDynamicValues,
    loadingContent,
    setValues,
    templates,
    templatesLoading,
    parentId,
  ]);

  useEffect(() => {
    // Should only be done for new pages
    if (!contentId) {
      const path = calculatePath({
        path: selectedParent?.path,
        slug: values.slug,
      });

      setValue('path', path);
      setValue('parent', selectedParent?.path ?? '/');
    }
  }, [values.slug, selectedParent, contentId, setValue]);

  useEffect(() => {
    // Should only be done for new pages
    if (!contentId) {
      const slug = slugify(values?.title?.toLocaleLowerCase() ?? '', {
        lower: true,
        trim: true,
      });
      setValue('slug', slug);
    }
  }, [contentId, setValue, values?.title]);

  useEffect(() => {
    if (!selectedTemplate) {
      const item =
        templateDropDownData.find((item) => item.content === 'default') ??
        templateDropDownData[0];

      setSelectedTemplate(item?.selected_key);
    }
  }, [selectedTemplate, templateDropDownData]);

  const onSubmit = handleSubmit(
    useCallback(
      async (data) => {
        if (!selectedTemplate) {
          return;
        }

        if (!data.path.startsWith(data.parent)) {
          controller.setError('path', 'Path must start with parent path');
          return;
        }

        try {
          await createContent({
            ...data,
            id: contentId ?? undefined,
            templateId: selectedTemplate,
            blob: { ...dynamicController.values },
            sortIndex: Number(data.sortIndex),
          });
          navigate(
            selectedParent ? `/content${selectedParent?.path}` : '/content',
          );
        } catch (error) {
          if (ApiError.isApiError(error)) {
            controller.setFormError(error.message);
            return;
          }
        }
      },
      [
        contentId,
        controller,
        dynamicController.values,
        navigate,
        selectedParent,
        selectedTemplate,
      ],
    ),
  );

  const handleParentSelection = useCallback(
    ({ data }: { data: { selected_key: string } }) => {
      const path = calculatePath({
        path: content?.parent.length === 1 ? '' : content?.parent,
        slug: values.slug,
      });

      const _content = contentById[data?.selected_key];
      setSelectedParent(_content);

      setValue('parentId', data?.selected_key);
      setValue('parent', _content?.path ?? '/');
      setValue('path', path);
    },
    [contentById, setValue, values.slug],
  );

  const [activeTab, setActiveTab] = useState<TabKey>(TabKey.info);
  const isLoading = loadingContent || loadingAllContent || templatesLoading;

  if (isLoading) {
    return <LoadingPage />;
  }

  const isMissingParentId =
    parentId == 'null' && content?.path.split('/').length !== 2;

  return (
    <>
      <BackButton
        to={selectedParent?.path ? `/content${selectedParent?.path}` : '..'}
      >
        Back
      </BackButton>

      <Container size="large" stretch>
        {submitting && <LoadingModal />}

        <H1 bottom={false} top="large">
          Add Content
          <P>will be added under {selectedParent?.path}</P>
        </H1>

        {isMissingParentId && (
          <FormStatus
            state="warn"
            text="Parent ID is not set, please verify that the form is correct before you save changes"
          />
        )}

        <Tabs
          data={TABS}
          no_border
          on_change={({ key }) => setActiveTab(key)}
          selected_key={activeTab}
          top="large"
        />
        <FormSet label_direction="vertical" on_submit={onSubmit}>
          {activeTab === TabKey.info && (
            <>
              <Space className={style['Container']}>
                <FormRow>
                  <Autocomplete
                    data={parentsDropdownData}
                    {...register.autocomplete('parentId')}
                    label="Parent"
                    on_select={handleParentSelection}
                  />
                </FormRow>
                <SharedForm controller={controller} />
                <FormRow top="medium">
                  <Radio.Group
                    label="Status"
                    on_change={({ value }) =>
                      setValue('publishedStatus', Number.parseInt(value))
                    }
                    value={content ? content.publishedStatus?.toString() : '0'}
                  >
                    <Radio
                      label="Unpublished"
                      value={PUBLISHED_STATUS.UNPUBLISHED.toString()}
                    />
                    <Radio
                      label="Draft"
                      value={PUBLISHED_STATUS.DRAFT.toString()}
                    />
                    <Radio
                      label="Published"
                      value={PUBLISHED_STATUS.PUBLISHED.toString()}
                    />
                  </Radio.Group>
                </FormRow>
              </Space>

              <Visibility
                controller={controller}
                selectedParentContent={selectedParent}
              />
            </>
          )}
          {activeTab === TabKey.content && (
            <>
              <Space className={style['ContentContainer']}>
                <Space className={style['ContentFormItem']}>
                  <FormRow>
                    <Dropdown
                      data={templateDropDownData}
                      label="Select template"
                      on_change={({ data }) =>
                        setSelectedTemplate(data.selected_key)
                      }
                      value={selectedTemplate}
                    />
                  </FormRow>
                  <FormRow top="medium">
                    <Input
                      label="Title"
                      size={40}
                      stretch
                      {...register.input('title')}
                    />
                  </FormRow>

                  {selectedTemplate &&
                    templatesById[selectedTemplate].schema.map((field, i) => {
                      return (
                        <Space key={`${field.name}-${i}`} top="medium">
                          {field.type === 'string' && (
                            <FormRow>
                              <Input
                                label={field.name}
                                size={40}
                                stretch
                                {...dynamicRegister.input(`${field.name}`)}
                              />
                            </FormRow>
                          )}
                          {field.type === 'boolean' && (
                            <FormRow>
                              <Checkbox
                                label={field.name}
                                {...dynamicRegister.checkbox(`${field.name}`)}
                              />
                            </FormRow>
                          )}
                          {field.type === 'text' && (
                            <FormRow>
                              <Textarea
                                label={field.name}
                                rows={4}
                                {...dynamicRegister.textarea(`${field.name}`)}
                              />
                            </FormRow>
                          )}
                          {field.type === 'markdown' && (
                            <FormRow direction="vertical">
                              <FormLabel>{field.name}</FormLabel>
                              <MarkdownEditor
                                height={600}
                                onChange={(_value) =>
                                  dynamicController.setValue(
                                    `${field.name}`,
                                    _value,
                                  )
                                }
                                value={
                                  dynamicController.getValue(
                                    `${field.name}`,
                                  ) as string
                                }
                              />
                            </FormRow>
                          )}
                        </Space>
                      );
                    })}
                </Space>
              </Space>
              <MarkdownHelperTable />
            </>
          )}

          <Section spacing="large" style_type="white">
            <Button
              disabled={submitting}
              icon="save"
              icon_position="left"
              type="submit"
            >
              Save changes
            </Button>
            <FormStatus text={controller.formError} />
            {isMissingParentId && (
              <FormStatus
                state="warn"
                text="Parent ID is not set, please verify that the form is correct before you save changes"
              />
            )}
          </Section>
        </FormSet>
      </Container>
    </>
  );
}

type DropdownViewProps = {
  title: string;
  path: string;
};
function DropDownView({ title, path }: DropdownViewProps) {
  return (
    <div>
      <Lead>{title}</Lead>
      <P size="small">{path}</P>
    </div>
  );
}

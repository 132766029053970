import { Anchor, Button, H1 } from '@dnb/eufemia';
import type { PostDto } from '@portals/shared/admin/PostDto';
import type { JSX } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import useSWR from 'swr';

import BackButton from '@/components/BackButton';
import DataTable, { Column } from '@/components/DataTable';
import { humanDate } from '@/utils';

import LoadingPage from '../../components/LoadingPage';

interface PostRow {
  id: string;
  title: string;
  createdAt: Date;
  emailsSentAt: Date | null;
  publishedAt: Date | null;
}

const columns: Column<PostRow>[] = [
  {
    header: 'Name',
    attribute: 'title',
    render: ({ title, id }) => (
      <Anchor element={Link} to={`/news/${id}`}>
        {title}
      </Anchor>
    ),
  },
  {
    header: 'Published',
    attribute: 'publishedAt',
    render: ({ publishedAt }) => humanDate(publishedAt),
  },
  {
    header: 'Emailed',
    attribute: 'emailsSentAt',
    render: ({ emailsSentAt }) => humanDate(emailsSentAt),
  },
  {
    header: 'Created',
    attribute: 'createdAt',
    render: ({ createdAt }) => humanDate(createdAt),
  },
];

function fromDtoToRow({
  id,
  title,
  publishedAt,
  emailsSentAt,
  createdAt,
}: PostDto): PostRow {
  return {
    id,
    title,
    createdAt: new Date(createdAt),
    publishedAt: publishedAt ? new Date(publishedAt) : null,
    emailsSentAt: emailsSentAt ? new Date(emailsSentAt) : null,
  };
}

export default function PostList(): JSX.Element {
  const navigate = useNavigate();
  const { data: posts, isValidating: postsLoading } =
    useSWR<PostDto[]>('/posts');

  const rows = posts?.map(fromDtoToRow);

  if (postsLoading || !rows) {
    return <LoadingPage />;
  }

  return (
    <>
      <BackButton to="/">Home</BackButton>

      <H1 top="large">News</H1>
      <DataTable
        barContent={
          <Button
            element={Link}
            icon="add"
            icon_position="left"
            to="/news/new"
            variant="secondary"
          >
            Add post
          </Button>
        }
        columns={columns}
        data={rows}
        defaultSortKey="title"
        filterBy={['title']}
        onEdit={({ id }) => navigate(id)}
      />
    </>
  );
}

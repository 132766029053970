import {
  PublicClientApplication,
  type RedirectRequest,
} from '@azure/msal-browser';

export const loginRequest: RedirectRequest = {
  scopes: ['openid', 'profile', 'email', `api://${ADMIN_AZURE_CLIENT_ID}/User`],
  domainHint: 'dnb.no',
};

export const msalInstance = new PublicClientApplication({
  auth: {
    clientId: ADMIN_AZURE_CLIENT_ID,
    authority: `https://login.microsoftonline.com/${AZURE_TENANT}`,
    redirectUri: ADMIN_AZURE_REDIRECT_URI,
    postLogoutRedirectUri: '/',
  },
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: true,
  },
});
await msalInstance.initialize();
